(function () {
  'use strict';

  var VisaIssueRequestCreation = function ($http) {
    var VisaIssueRequestsCreation = {};

    VisaIssueRequestsCreation.create = function (model) {
      return $http.post('/app/visas/issue_requests', model);
    };

    VisaIssueRequestsCreation.checkPermission = function () {
      return $http.post('/app/visas/issue_requests/check_availability');
    };
    VisaIssueRequestsCreation.checkFinancialCapacity = function () {
      return $http.post('/app/visas/issue_requests/checkFinancialCapacity');
    };
    return VisaIssueRequestsCreation;
  };

  angular.module('musaned.app.visas.issueRequests')
    .service('VisaIssueRequestCreation', VisaIssueRequestCreation)
})();
