(function () {
  'use strict';

  var NoticeRequestCreationCtrl = function ($anchorScroll, $location, $scope, $timeout, $state, $modal, $rootScope, $translate, Upload, blockUI, NoticeRequestCreationForm, NoticeRequestCreation, $ocLazyLoad, WizardHandler, Settings) {
    var vm = this;

    $ocLazyLoad.load('/assets/js/pdf.combined.js');
    $ocLazyLoad.load('/assets/js/FileAPI.min.js');
    

    var block = blockUI.instances.get('notice_form');
    vm.data = {};
    vm.setting = {};
    vm.sendDatasendData = {};
    vm.can_request_new_visa = true;
    vm.errorMessages = [];
    vm.is_profile_updated = true;

    Settings.get('is_notices_disabled').then(function (respone) {
      vm.setting.is_notices_disabled = respone.data;
    });

    Settings.get('notices_disable_message').then(function (respone) {
      vm.setting.notices_disable_message = respone.data;
    });

    vm.bill_msg = '';

    vm.checkIssueRequest = function(){
      block.start();
      NoticeRequestCreation.checkPermission()
          .then(function (res) {
            vm.occupations =res.data.yemeniOccupationsList;

          }, function (res) {
            $location.hash('errorMessage');
            $anchorScroll();
            vm.can_request_new_visa = false;
             if (res.status == 422) {
              vm.errorMessages = []
               angular.forEach(res.data.errors, function (messages) {
                 angular.forEach(messages, function (message) {
                    vm.errorMessages.push(message);
                 });
               });
             }
             else if (res.data.error.code == 120) {
                  vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.EXCEEDED_MAX_ALLOWED_REQUEST');
              } else if (res.data.error.code == 121) {
                  vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.GENDER_STATUS_ERROR');
              } else if (res.data.error.code == 111) {
                  vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.USER_HAS_TRAFFIC_VIOLATION');
              } else if (res.data.error.code == 122) {
                  vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.STOP_GOVERNMENT_SERVICES');
              } else if (res.data.error.code == 106) {
                  vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.DIED');
              } else if (res.data.error.code == 105) {
                  vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.ERROR__NOT_FOUND');
              } else if (res.data.error.code == 107) {
                  vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.CANNOT_OPEN_SPONSER_FILE');
              } else if (res.data.error.code == 511) {
                  vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.TIMEOUT');
              } else if (res.data.error.code >= 130 && res.data.error.code < 140) {
                  vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.'+res.data.error.code);
              } else {
                vm.errorMessage = res.data.error.message;
              }
          })
          .finally(function () {
            block.stop();
          });
    };


    vm.checkIssueRequest();
    vm.checklist = NoticeRequestCreationForm.checklist;
    vm.fields = NoticeRequestCreationForm.fields;

    vm.model = {
      documents: {}
    };
    vm.files = [];

    vm.uploads = [{'id':1,'name':$translate.instant("VISAS.ISSUE_REQUESTS.PHRASES.PROOF_FINANCIAL_CAPACITY"),'files':[],'required':true},
      {id:2,'name':$translate.instant("VISAS.ISSUE_REQUESTS.PHRASES.COPY_ID"),'files':[],'required':false},
      {id:3,'name':$translate.instant("VISAS.ISSUE_REQUESTS.PHRASES.OTHER_SUPPORTING_DOCUMENT"),'files':[],'required':false}];
    vm.upload_disabled = false;

    vm.addExtraFiles = function(files){
      var item = {};
      for(var i=0;i<files.length;i++){
        item = {
          'id' : i+vm.uploads.length+1,
          'name' : $translate.instant('VISAS.ISSUE_REQUESTS.PHRASES.'+files[i]),
          'files':[],
          'required':true
        }
        vm.uploads.push(item);
      }
    }
    NoticeRequestCreation.getData()
        .then(function (res) {
          vm.is_profile_updated = res.data.is_profile_updated;
          res.data.banks.unshift({id: '' , label: " - "});
          vm.lookupBanks =res.data.banks;
        }, function (res) {
          var title = 'حدث خطأ اثناء استرجاع البيانات', text = '';
          if (res.data.error) {
            if (res.data.error.code == 105) {
              text = 'الرجاء التسجيل في خدمة العنوان الوطني المقدمة من البريد السعودي عبر هذا <a href="https://register.address.gov.sa/ar" target="_blank">الرابط</a>';
            } else if (res.data.error.code == 106) {
              text = 'حدث خطأ اثناء استرجاع معلومات العنوان الوطني, الرجاء المحاولة مرة أخرى.';
            }
          }
          //      vm.errorMessage= text;

          sweetAlert({
            type: 'error',
            title: title,
            text: text,
            html: true
          })
        });
    $scope.$watch('vm.files', function (newFiles) {
      $scope.formUpload = false;
      if (newFiles != null) {
        for (var i = 0; i < newFiles.length; i++) {
          $scope.errorMsg = null;
          (function (file) {
            vm.upload(file);
          })(newFiles[i]);
        }
      }
    });
    vm.uploadFile =  function (files,type) {
      if (files && files.length) {
        for (var i = 0; i < files.length; i++) {
          vm.upload(files[i],type);
        }
      }
    };
     vm.upload = function (file,type) {
      vm.upload_disabled = true;
      vm.errorMsg = [];
      file.upload = Upload.upload({
        url: '/app/user/uploads/upload' + vm.getReqParams(),
        method: 'POST',
        file: file,
        fileFormDataName: 'file'
      });

      file.upload
        .then(function (res) {
          $timeout(function () {
            file.result = res.data.userUpload;
            if(res.data.userUpload !== undefined) {
              res.data.userUpload.type = type;
              vm.uploads[type-1].files.push(res.data.userUpload);
            }
            if (res.data.errors !== undefined) {
                vm.errorMsg.push('(' + file.name + ') ' + res.data.errors.file);
            }
            vm.upload_disabled = false;
          });
        }, function (res) {
          if (res.status == 422) {
            vm.errorMsg.push('(' + file.name + ') ' + res.data.errors.file);
          } else {
            vm.errorMsg.push('(' + file.name + ') ' + $translate.instant(res.data.error.message));
          }
          vm.upload_disabled = false;
        });
      file.upload.progress(function (evt) {
        // Math.min is to fix IE which reports 200% sometimes
        file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
      });
    };

    vm.disableStep = function() {

      var at_least_one_proof, extra_proof;
      at_least_one_proof = extra_proof = false
        for(var i = 0; i < vm.uploads.length; i++) {
        for(var j=0; j< vm.uploads[i].files.length; j++){

          if(vm.uploads[i].files[j].type == 1){
          at_least_one_proof = true;
        }
          if(vm.uploads[i].files[j].type >= 4){
               extra_proof = true;

          }

        }
      }
      if(vm.hasExtraFiles){
        return ((at_least_one_proof && extra_proof) == false);
      }

      return !at_least_one_proof ;
    };

    vm.getReqParams = function () {
      return vm.generateErrorOnServer ? '?errorCode=' + vm.serverErrorCode + '&errorMessage=' + vm.serverErrorMsg : '';
    };
    vm.removeFile = function (idx, type) {
      vm.uploads[type-1].files.splice(idx, 1);
    };

    // function assignment
    vm.finishWizard = function () {
      var block = blockUI.instances.get('notice_form');
      block.start();

      vm.errorMessage = '';

      vm.uploads.map(function (upload) {
        upload.files.map(function (uploadFile) {
          vm.model.documents[uploadFile.id] = uploadFile.type;
        }); });

      return NoticeRequestCreation.create(vm.model)
        .then(function (res) {
          $rootScope.successMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.NOTICE_SUCCESS');

          $state.go('notices.list');
        }, function (res) {
          $location.hash('errorMessage');
          $anchorScroll();
            if (res.status == 400) {
                vm.errorMessage = res.data.error.code.message;
            }
            if (res.status == 422) {
            vm.errorMessages = [];
             angular.forEach(res.data.errors, function (messages) {
               angular.forEach(messages, function (message) {
                 vm.errorMessages.push(message);
               });
             });
           }
          else if (res.data.error.code == 104) {
            vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.USER_NOT_ALLOWED');
          } else if (res.data.error.code == 102) {
            vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.MISSING_FILES');
          } else if (res.data.error.code == 103) {
            vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.PENDING_REQUEST');
          } else if (res.data.error.code == 107) {
            vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.WAITING_PAYMENT_REQUEST');
          } else if (res.data.error.code == 120) {
              vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.EXCEEDED_MAX_ALLOWED_REQUEST');
          }
          else if (res.data.error.code == 422) {
              vm.errorMessage =res.data.error.code.message;
          } else if (res.data.error.code >= 130 && res.data.error.code < 140) {
            vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.'+res.data.error.code);
          }else {
            vm.errorMessage = $translate.instant(res.data.error.message);
          }
        })
        .finally(function () {
          block.stop();
        });
    };
    $scope.toggle_details = function () {
      $scope.show_details = !$scope.show_details;
    };
    vm.allQuestionsAnswered = function () {
      for (var i = 0; i < vm.checklist.questions.length; i++) {
        if (
          vm.checklist.questions[i].visible == true &&
          ( !vm.checklist.questions[i].selectedAnswer
            )
        ) {
          return true;
        }
      }
      return false;
    };

    vm.showQuestion = function (item, dependent) {
      if (!dependent)
        return true;

      if (vm.checklist.questions[dependent.index].selectedAnswer == dependent.value) {
        vm.checklist.questions[item].visible = true;
      } else {
        vm.checklist.questions[item].visible = false;
        vm.checklist.questions[item].selectedAnswer = null;
      }
      return vm.checklist.questions[item].visible;
    };
    vm.hasFinancialCapacity = false;

    vm.checkFinancialCapacity = function () {
      vm.model.employmentStatus = vm.checklist.questions[1].selectedAnswer;

      if (vm.checklist.questions[0].selectedAnswer == 1 && vm.checklist.questions[2].selectedAnswer == 3) {
        block.start();
        NoticeRequestCreation.checkFinancialCapacity()
            .then(function (res) {
              if (res.data.gosiServiceValue) {

                vm.can_request_new_visa = true;
                vm.hasFinancialCapacity = true;
                WizardHandler.wizard($translate.instant('NOTICES.PHRASES.NOTICE')).next();
              } else {
                $location.hash('errorMessage');
                $anchorScroll();
                vm.can_request_new_visa = false;
                vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.ERROR__INVALID_GOSI');
              }

            }, function (res) {
              $location.hash('errorMessage');
              $anchorScroll();
              vm.can_request_new_visa = false;
              if (res.data.error.code == 120) {
                vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.EXCEEDED_MAX_ALLOWED_REQUEST');
              } else if (res.data.error.code == 121) {
                vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.GENDER_STATUS_ERROR');
              } else if (res.data.error.code == 111) {
                vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.USER_HAS_TRAFFIC_VIOLATION');
              } else if (res.data.error.code == 122) {
                vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.STOP_GOVERNMENT_SERVICES');
              } else if (res.data.error.code == 106) {
                vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.DIED');
              } else if (res.data.error.code == 105) {
                vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.ERROR__NOT_FOUND');
              } else if (res.data.error.code == 107) {
                vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.CANNOT_OPEN_SPONSER_FILE');
              } else if (res.data.error.code == 511) {
                vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.TIMEOUT');
              } else if (res.data.error.code == 107) {
                vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.ERROR__INVALID_GOSI');
              } else if (res.data.error.code >= 130 && res.data.error.code < 140) {
                vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.' + res.data.error.code);
              } else {
                vm.errorMessage = $translate.instant(res.data.error.message);
              }
            })
            .finally(function () {
              block.stop();
            });

      } else {
        WizardHandler.wizard($translate.instant('NOTICES.PHRASES.NOTICE')).next();

      }
    };
    vm.checkForeigner = function () {

        block.start();
        NoticeRequestCreation.foreigner(vm.model)
            .then(function (res) {
              if (res.data.foreigner) {
                  vm.model.dob=vm.model.monthob +"/"+vm.model.dayob+"/"+vm.model.yearob;
                WizardHandler.wizard($translate.instant('NOTICES.PHRASES.NOTICE')).next();
              } else {
                $location.hash('errorMessage');
                $anchorScroll();
                vm.can_request_new_visa = false;
                vm.errorMessage = $translate.instant('NOTICES.PHRASES.FOREIGNER_DATA_ERROR');
              }

            }, function (res) {
              $location.hash('errorMessage');
              $anchorScroll();
              vm.can_request_new_visa = false;
              if (res.status == 422) {
                vm.errorMessages = []
                 angular.forEach(res.data.errors, function (messages) {
                   angular.forEach(messages, function (message) {
                      vm.errorMessages.push(message);
                   });
                 });
              }
              else if (res.data.error.code == 131) {
                vm.errorMessage = $translate.instant('NOTICES.PHRASES.FOREIGNER_DATA_NOT_FOUND');
              } else if (res.data.error.code == 132) {
                vm.errorMessage = $translate.instant('NOTICES.PHRASES.FOREIGNER_DATA_ERROR');
              } else {
                vm.errorMessage = $translate.instant(res.data.error.message);
              }
            })
            .finally(function () {
              block.stop();
            });


    };

    vm.withinRange = function(x,min,max) {
      return x <= max && x >= min
    };

    vm.validateRelativeInfo = function() {
      if(vm.model.relative_name || vm.model.relative_type || vm.model.relative_mobile) {
        return vm.model.relative_name && vm.model.relative_type && vm.model.relative_mobile;
      } else {
        return true;
      }
    };

    vm.validate = function () {

      var validate = (
          vm.model.worker_name_en &&
          vm.model.border_number &&
          vm.model.occupation &&
          vm.model.dayob &&
          vm.model.dayob > 0 &&
          vm.model.dayob < 32 &&
          vm.model.monthob &&
          vm.model.monthob > 0 &&
          vm.model.monthob < 13 &&
          vm.model.yearob  &&
          vm.model.yearob > 1900 &&
          vm.model.yearob < 2100 &&
          vm.model.worker_mobile &&
          vm.model.worker_salary &&
          vm.validateRelativeInfo()
      );
        if(vm.model.bank_id) {
            validate = (validate && vm.model.account_no) ;
        }
        return validate;
    };
  };

  angular.module('musaned.app.notices')
    .controller('NoticeRequestCreationCtrl', NoticeRequestCreationCtrl);
})();
