(function () {
    'use strict';

    var VerifyCtrl = function ($rootScope, $state, $translate, blockUI, Auth, $http, $interval, $timeout) {
        var vm = this;

        var block = blockUI.instances.get('verify');

        vm.data = {code: ''};
        var countdown;
        vm.errorMessage = '';
        vm.successMessage = '';
        vm.resend_disabled = false;
        vm.StartCount = true;

        vm.verify = function () {
            block.start();

            vm.errorMessage = '';

      Auth.verify(vm.data)
      .then(function () {}, function (res) {
          vm.successMessage = '';
          if (res.data.error.code == 102) {
            vm.errorMessage = $translate.instant('USERS.MESSAGES.LOGIN.INVALID_CODE');
        }
        else if (res.data.error.code == 103) {
          vm.errorMessage = $translate.instant('USERS.MESSAGES.LOGIN.INVALID_TRYS');
      }
  })
      .finally(function () {
          block.stop();
      });
  };

        vm.resend = function () {
            block.start();
            vm.resend_disabled = true;
            vm.errorMessage = '';
            vm.StartCount = false;

            Auth.resend()
                .then(function (res) {
                  vm.expires = 60;
                    vm.countdown = vm.expires;

                    countdown = $interval(function () {
                        vm.countdown = vm.countdown - 1;
                    }, 1000, vm.countdown);

                    $timeout(function () {
                        vm.resend_disabled = false;
                        vm.StartCount = true;
                    }, vm.countdown * 1000);

                    vm.errorMessage = '';
                    if (res.status == 200) {
                        vm.successMessage = $translate.instant('USERS.MESSAGES.LOGIN.RESEND_IS_DONE');
                    }
                }, function (res) {
                    $interval.cancel(countdown);
                    $state.go('user.login');
                })
                .finally(function () {
                    block.stop();
                });
        };
    };

  angular.module('musaned.app.auth')
    .controller('VerifyCtrl', VerifyCtrl);
})();
