(function () {
  'use strict';

  var Visas = function ($http) {
    var Visas = {};

    Visas.all = function (page) {
      if (page == 'undefined') page = 1;
      return $http.get('/app/visas', {
        params: {page: page}
      });
    };

    Visas.get = function (id) {
      return $http.get('/app/visas/'+id);
    };

    Visas.cancel = function (visa) {
      return $http.post('/app/visas/' + visa.id + '/cancel');
    };

    Visas.getSetting = function () {
      return $http.get('/app/visas/setting');
    }
    

    return Visas;
  };

  angular.module('musaned.app.visas')
    .service('Visas', Visas)
})();
