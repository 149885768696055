(function () {
  'use strict';

  var DashboardCtrl = function (blockUI, Dashboard, $modal, $state, $scope, $rootScope) {
    var vm = this;

    var block = blockUI.instances.get('dashboard');
    if($rootScope.warningMessage) {vm.warningMessage = $rootScope.warningMessage;}

    vm.getData = function () {
      block.start();
      Dashboard.getData()
        .then(function (res) {
          vm.data = res.data;
          //vm.locateAgreedOfficeRequest();
        }, function (res) {
          // handle errors
        })
        .finally(function () {
          block.stop();
        });
    };

    vm.getData();

    vm.addNotice = function () {
      $scope.modalInstance = $modal.open({
        animation: false,
        templateUrl: 'partials/app/notices/noticeMSG.html',
        size: 'lg',
        windowClass: 'print-visa-modal',
        scope: $scope,
        backdrop: 'static',
        keyboard: false
      });
    };

    vm.goToNotice = function () {
      $scope.modalInstance.close();
      $state.go('notices.create');
    };

    $scope.cancel = function () {
      $scope.modalInstance.close();
    };
  };

  angular.module('musaned.app.dashboard')
    .controller('DashboardCtrl', DashboardCtrl);
})();
