(function () {
  'use strict';

  var NoticeRequestRestoreBillCtrl = function ($stateParams, $translate, $state, $modal, locker, blockUI , NoticeRequests, $ocLazyLoad, $rootScope) {
    var vm = this;
    var block = blockUI.instances.get('restor_form');
    vm.restor = {};
    vm.refund = function () {
      block.start();
      NoticeRequests.refund(vm.restor)
        .then(function (res) {          
          $rootScope.show_notice_balance = res.data.show_notice_balance;
          $rootScope.show_notice_balance_and_refund_request = res.data.show_notice_balance_and_refund_request;
          $state.go('notices.list');
        }, function (res) {
          if (res.data.error.code == 101 || res.data.error.code == 134) {
              vm.errorMessage = $translate.instant('BILL.MESSAGES.CAN_NOT_REFUND');
          }else{
              vm.errorMessage = 'ERROR';
          }
        })
        .finally(function () {
          block.stop();
        });
    };
    NoticeRequests.getBanks().then(function (respone) {
      vm.banks = respone.data.banks;
    });
    

  };

  angular.module('musaned.app.notices')
    .controller('NoticeRequestRestoreBillCtrl', NoticeRequestRestoreBillCtrl);
})();
