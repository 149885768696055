(function () {
  'use strict';

  var Sidebar = function ($http,App_URLs) {
    var Sidebar = {};

    Sidebar.getData = function () {
      return $http.get('app/user/sidebar');
    };

    Sidebar.Count = function () {
      return $http.get(App_URLs.eContractingBaseUri+'/api/info');
    };

    return Sidebar;
  };

  angular.module('musaned.app.sidebar')
    .service('Sidebar', Sidebar);
})();
