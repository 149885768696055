(function () {
  'use strict';

  var VisaIssueRequests = function ($http) {
    var VisaIssueRequests = {};

    VisaIssueRequests.all = function (page) {
      if (!page) page = 1;
      return $http.get('/app/visas/issue_requests', {
        params: {page: page}
      });
    };

    VisaIssueRequests.get = function (requestId) {
      return $http.get('/app/visas/issue_requests/' + requestId);
    };

    VisaIssueRequests.bill = function (requestId) {
      return $http.get('/app/billing/show/' + requestId);
    };

    VisaIssueRequests.refund = function (data) {
      return $http.post('/app/billing/refund', data);
    };
    VisaIssueRequests.getBanks = function () {
      return $http.get('/app/billing/banks');
    };
    return VisaIssueRequests;
  };

  angular.module('musaned.app.visas.issueRequests')
    .service('VisaIssueRequests', VisaIssueRequests)
})();
