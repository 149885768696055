(function () {
  'use strict';

  var VisaCancelCtrl = function ($scope, $modalInstance, Visas, blockUI, details) {
    var vm = this;

    $scope.visa = details;

    $scope.dismiss = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.confirm = function () {
      var block = blockUI.instances.get('visa_cancel');
      block.start();
      Visas.cancel(details)
        .then(function (res) {
          block.stop();
          $modalInstance.close('cancellationSuccess');
        }, function () {
          block.stop();
          $modalInstance.dismiss('cancellationFail');
        });
    }
  };

  angular.module('musaned.app.visas')
    .controller('VisaCancelCtrl', VisaCancelCtrl);
})();
