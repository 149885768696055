(function () {
    'use strict';

    var NoticeRequestCreation = function ($http) {
        var NoticeRequestCreation = {};

        NoticeRequestCreation.create = function (model) {
            return $http.post('/app/notices', model);
        };
        NoticeRequestCreation.getData = function () {
            return $http.get('/app/notices/getData');
        };

        NoticeRequestCreation.checkPermission = function () {
            return $http.post('/app/visas/issue_requests/check_availability');
        };
        NoticeRequestCreation.checkFinancialCapacity = function () {
            return $http.post('/app/visas/issue_requests/checkFinancialCapacity');
        };
        NoticeRequestCreation.foreigner = function (model) {
            return $http.post('/app/notices/foreigner', model);
        };
        return NoticeRequestCreation;
    };

    angular.module('musaned.app.notices')
        .service('NoticeRequestCreation', NoticeRequestCreation)
})();
