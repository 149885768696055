(function () {
  'use strict';

  var ContractRequestCancelApproveCtrl = function ($scope, $stateParams, ContractRequests, blockUI,$translate) {
    var vm = this;

    $scope.contracts = $stateParams.contractId;
    vm.errorMessage = '';
    vm.successMessage = '';
      $scope.cr = {};
      var block = blockUI.instances.get('approveCancel');
      ContractRequests.show($stateParams.contractId)
          .then(function (res) {
              vm.contract = res.data.contract.cancellation_request;
          })
          .finally(function () {
              block.stop();
          });

      vm.rejectCancel = function () {
      vm.data = { reason :   $scope.cr.reason };
      vm.errorMessage = '';
      block.start();
      ContractRequests.rejectCancel($stateParams.contractId,vm.data)
        .then(function () {
            vm.successMessage = $translate.instant("CONTRACTS.REQUESTS.CANCELLATION_REQUESTS.PHRASES.REJECT_CANCEL_REQUEST");
            $state.go('contracts.requests.list');

        },function (res) {
          vm.successMessage = '';
          if (res.data.error.code == 101) {
            vm.errorMessage = $translate.instant('USERS.MESSAGES.LOGIN.INVALID_CREDENTIALS');
          }
        })
          .finally(function () {
            block.stop();
          });
    }

    vm.acceptCancel = function () {
      vm.errorMessage = '';
      block.start();
      ContractRequests.acceptCancel($stateParams.contractId)
          .then(function () {

              vm.successMessage = $translate.instant("CONTRACTS.REQUESTS.CANCELLATION_REQUESTS.PHRASES.ACCEPT_CANCEL_REQUEST");
              $state.go('contracts.requests.list');

          },function (res) {
            vm.successMessage = '';
            if (res.data.error.code == 101) {
              vm.errorMessage = $translate.instant('USERS.MESSAGES.LOGIN.INVALID_CREDENTIALS');
            }
          })
          .finally(function () {
            block.stop();
          });
    }
  };

  angular.module('musaned.app.contracts.requests')
    .controller('ContractRequestCancelApproveCtrl', ContractRequestCancelApproveCtrl);
})();
