(function () {
  'use strict';

  var VisaIssueRequestCreationForm = function ($rootScope, $translate, blockUI, $filter) {
    var CreationForm = {};

    $rootScope.$on('$translateChangeSuccess', function () {
      $filter('filter')(CreationForm.fields.step1, {'key':'occupation'})[0].templateOptions.label = $translate.instant('FAQ.Q4.A3.A15');
      $filter('filter')(CreationForm.fields.step1, {'key':'nationality'})[0].templateOptions.label = $translate.instant('FAQ.Q4.A3.A16');
      $filter('filter')(CreationForm.fields.step1, {'key':'visa_issue_place'})[0].templateOptions.label = $translate.instant('FAQ.Q4.A3.A17');
    });


    CreationForm.checklist = {
      "questions": [
        {
          "id": 1,
          "text": 'CREATIONFORM.Q1.Q1',
          "answers": [{"id": 1, "text": ('COMMON.ACTIONS.YES')}, {"id": 2, "text": ('COMMON.ACTIONS.NO')}],
          "selectedAnswer": null,
          "visible": true,
          "error": {
            "title": ('CREATIONFORM.Q1.TITLE'),
            "text": ('CREATIONFORM.Q1.TEXT')
          },
          "type":"radio"
        },
        {
          "id": 2,
          "text": ('CREATIONFORM.Q9.Q9'),
          "answers": [{"id": 1, "text": ('COMMON.ACTIONS.YES')}, {"id": 2, "text": ('COMMON.ACTIONS.NO')}],
          "selectedAnswer": null,
          "visible": true,
          "type":"radio"

        },
        {
          "id": 3,
          "text": ('CREATIONFORM.Q10.Q10'),
          "answers": [{"id": 1, "text": ('CREATIONFORM.Q10.A1')}, {"id": 2, "text": ('CREATIONFORM.Q10.A2')},{"id": 3, "text": ('CREATIONFORM.Q10.A3')}, {"id": 4, "text": ('CREATIONFORM.Q10.A4')},{"id": 6, "text": ('CREATIONFORM.Q10.A6')},{"id": 7, "text": ('CREATIONFORM.Q10.A7')},{"id": 5, "text": ('CREATIONFORM.Q10.A5')},{"id": 8, "text": ('CREATIONFORM.Q10.A8')}],
          "selectedAnswer": null,
          "visible": true,
          "type":"select"

        },
        {
          "id": 4,
          "text": ('CREATIONFORM.Q3.Q3'),
          "answers": [{"id": 3, "text": ('CREATIONFORM.Q3.A1')}, {"id": 4, "text": ('CREATIONFORM.Q3.A2')}],
          "selectedAnswer": null,
          "visible": true,
          "type":"radio"
        },



      ]
    };

    CreationForm.fields = {
      step1: [
        {
          key: 'occupation',
          type: 'select',
          className: 'select-style',
          templateOptions: {
            label: $translate.instant('FAQ.Q4.A3.A15'),
            options: [],
            required: true,
            valueProp: 'id',
            labelProp: 'label',

            ngOptions: 'option.id as option.name for option in to.options'
          },
          controller: function ($scope) {
            $scope.to.options = angular.forEach($rootScope.occupations, function(value) {
              value.name = ($translate.use() == 'en') ? value.label_en : value.label;
            });
            $scope.$watch(function() {
                  return $translate.use();
                }, function(newLang, oldLang) {
                  if (newLang && oldLang && newLang !== oldLang) {
                  angular.forEach($scope.to.options, function(value, key) {
                    value.name = ($translate.use() == 'en') ? value.label_en : value.label;
                  });
                }
            });

          }
        },
        {
          key: 'nationality',
          type: 'select',
          className: 'select-style',

          templateOptions: {
            label: $translate.instant('FAQ.Q4.A3.A16'),
            options: [],
            required: true,
            valueProp: 'id',
            labelProp: 'label',
            ngOptions: 'option.id as option.name for option in to.options'
          },
          controller: function ($scope, VisaLookups) {
            $scope.$watch('model.occupation', function (newValue, oldValue) {
              if (!!newValue && newValue !== oldValue) {
                if ($scope.model[$scope.options.key] && oldValue) {
                  // reset this select
                  $scope.model[$scope.options.key] = '';
                }

                var block = blockUI.instances.get('issue_form');
                block.start();

                $scope.to.loading = VisaLookups.getNationalitiesList(newValue)
                  .then(function (res) {
                    angular.forEach(res.data.nationalities, function(value, key) {
                      value.name = ($translate.use() == 'en') ? value.label_en : value.label;
                    });
                    $scope.to.options = res.data.nationalities;
                    block.stop();
                  });
              }

            });
            $scope.$watch(function() {
                  return $translate.use();
                }, function(newLang, oldLang) {
                  if (newLang && oldLang && newLang !== oldLang) {
                  angular.forEach($scope.to.options, function(value, key) {
                    value.name = ($translate.use() == 'en') ? value.label_en : value.label;
                  });
                }
            });
          }
        },
        {
          key: 'visa_issue_place',
          type: 'select',
          className: 'select-style',
          templateOptions: {
            label: $translate.instant('FAQ.Q4.A3.A17'),
            options: [],
            required: true,
            valueProp: 'id',
            labelProp: 'label',
            ngOptions: 'option.id as option.name for option in to.options'
          },
          controller: function ($scope, VisaLookups) {
            $scope.$watch('model.nationality', function (newValue, oldValue) {
              if (!!newValue && newValue !== oldValue) {
                if ($scope.model[$scope.options.key] && oldValue) {
                  // reset this select
                  $scope.model[$scope.options.key] = '';
                }

                var block = blockUI.instances.get('issue_form');
                block.start();

                $scope.to.loading = VisaLookups.getVisaIssuePlacesList(newValue)
                  .then(function (res) {
                    angular.forEach(res.data.visaIssuePlaces, function(value, key) {
                      value.name = ($translate.use() == 'en') ? value.label_en : value.label;
                    });
                    $scope.to.options = res.data.visaIssuePlaces;
                    block.stop();
                  });
              }

            });
            $scope.$watch(function() {
                  return $translate.use();
                }, function(newLang, oldLang) {
                  if (newLang && oldLang && newLang !== oldLang) {
                  angular.forEach($scope.to.options, function(value, key) {
                    value.name = ($translate.use() == 'en') ? value.label_en : value.label;
                  });
                }
            });
          }
        }
      ],
      step2: [
        {
          key: 'financial_capability_type',
          type: 'radio',
          templateOptions: {
            label: $translate.instant('CREATIONFORM.Q8.Q8'),
            options: [
              {value: 1, name:$translate.instant('CREATIONFORM.Q8.A1')},
              {value: 2, name:$translate.instant('CREATIONFORM.Q8.A2')}
            ],
            required: true
          }
        },
        {
          key: 'financial_capability_value',
          type: 'input',
          ngModelAttrs: {
            arabicNumbersVal: {
              attribute: 'tmk-arabic-numbers'
            }
          },
          templateOptions: {
            type: 'tel',
            required: true,
            minValue: 0,
            maxValue: 999999999999999,
            defaultValue: '',
            arabicNumbersVal: 'Arabic Digits'
          },
          hideExpression: '!model.financial_capability_type',
          controller: function ($scope) {
            $scope.$watch('model.financial_capability_type', function (newValue, oldValue) {
              $scope.model.financial_capability_value = '';
              if (newValue == 1) {
                $scope.to.label = $translate.instant('CREATIONFORM.Q8.A3');
                $scope.to.minValue = 0;
              } else {
                $scope.to.label = $translate.instant('CREATIONFORM.Q8.A4');
                $scope.to.minValue = 25000;
              }
            });
          },
          validators: {
            minValue: {
              expression: function ($viewValue, $modelValue, scope) {
                var value = parseInt($modelValue || $viewValue);
                return value >= scope.to.minValue;
              },
              message: function ($viewValue, $modelValue, scope) {
                var value = $modelValue || $viewValue;
                if ( value < scope.to.minValue) {
                  return scope.to.label+' '+$translate.instant('COMMON.MUST_BE_AT_LEAST')+' '+scope.to.minValue + $translate.instant('COMMON.SR')+'.';
                } else {
                  return '';
                }
              }
            },
            maxValue: {
              expression: function ($viewValue, $modelValue, scope) {
                var value = parseInt($modelValue || $viewValue);
                return (value <= scope.to.maxValue)
              },
              message: function ($viewValue, $modelValue, scope) {
                var value = $modelValue || $viewValue;
                if (value > scope.to.maxValue) {
                  return scope.to.label +' '+$translate.instant('COMMON.MUST_BE_AT_LEAST')+' '+scope.to.maxValue + $translate.instant('COMMON.SR')+'.';
                } else {
                  return '';
                }
              }
            }
          }
        }
      ]
    };

    return CreationForm;
  };

  angular.module('musaned.app.visas.issueRequests')
    .service('VisaIssueRequestCreationForm', VisaIssueRequestCreationForm)
})();
