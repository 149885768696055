(function () {
  'use strict';

  var UserDetailsCtrl = function ($scope, $translate, blockUI, Auth, User) {
    var vm = this;
    vm.errorMessages = [];
    var contactBlock = blockUI.instances.get('contact');
    var passwordBlock = blockUI.instances.get('password');
    vm.data = Auth.user();

    $scope.showUserWokers = function () {
      Auth.fetchUserWorkers();
    };

    vm.contactDetails = {
      email: Auth.user().email,
      mobile: ("966" == Auth.user().mobile.slice(0, 3)) ? Auth.user().mobile.replace("966", "0") : Auth.user().mobile
    };

    vm.password = {
      old_password: '',
      new_password: ''
    };
    if (vm.data.personal_details) {
      vm.personal_details =  vm.data.personal_details;
    } else {
      vm.personal_details = {};
    }


    vm.is_disable = function () {
      return !(
        vm.personal_details.coordinates
        &&  vm.personal_details.iban
        &&  vm.personal_details.iban_owner
        &&  vm.personal_details.average_income
        &&  vm.personal_details.family_size
        &&  vm.personal_details.domestic_laborers
        &&  vm.personal_details.job
      );
    }


    vm.loadMap = function(updateUserLocation) {
      var myLatLng = {lat: 24.7135517, lng: 46.675295};
      if (vm.data.personal_details) {
        var latLng = vm.data.personal_details.coordinates.split(',');
        if (latLng.length > 1) {
          myLatLng = {lat: Number(latLng[0]), lng: Number(latLng[1])};
        }
      }

      var map = new google.maps.Map(document.getElementById('map_div'), {
        zoom: 12,
        center: myLatLng
      });
      var marker = new google.maps.Marker({
        position: myLatLng,
        draggable: true,
        map: map
      });

      google.maps.event.addListener(marker, 'dragend', function(e){
        vm.applyLocation( e );
      });

      map.addListener('click', function(e) {
        marker.setPosition(e.latLng);
        vm.applyLocation( e );
      });
    };

    vm.applyLocation = function (e) {
      $scope.$apply(function () {
        vm.personal_details.coordinates = e.latLng.lat() + ',' + e.latLng.lng();
        var geocoder = new google.maps.Geocoder();

        geocoder.geocode({
          'latLng': e.latLng
        }, function (results, status) {
          if (status != google.maps.GeocoderStatus.OK) {
            vm.personal_details.street_number = '';
            vm.personal_details.route = '';
            vm.personal_details.sublocality = '';
            vm.personal_details.locality = '';
            vm.personal_details.postal_code = '';
            vm.personal_details.postal_code_suffix = '';
            vm.personal_details.house_type_id = '';
            vm.personal_details.coordinates = '';
            vm.personal_details.address = '';

            sweetAlert('تحديث الملف الشخصي','لا يوجد عنوان في هذا الموقع يرجى تحديد موقع آخر');
            return;
          }

            var address_components = results[0].address_components;
            vm.personal_details.address = results[0].formatted_address;

            jQuery.each(address_components, function (k, v1) {
              jQuery.each(v1.types, function (k2, v2) {
                vm.personal_details[v2] = v1.long_name
              });
            });
          Auth.fetchUserInfo();
        });
      });
    };

    vm.loadMap();

    vm.updateDetails = function () {
      contactBlock.start();

      User.updateDetails(vm.personal_details)
        .then(function () {
          sweetAlert({
            type: 'success',
            title: $translate.instant('USERS.MESSAGES.DETAILS_UPDATED_SUCCESSFULLY'),
            timer: 2500,
            showConfirmButton: false
          });
            vm.errorMessages = [];
        }, function (res) {
          vm.errorMessages = [];
            if (res.status == 422) {
                angular.forEach(res.data.errors, function (messages) {
                    angular.forEach(messages, function (message) {
                        vm.errorMessages.push(message);
                    });
                });
            } else {
                // TODO: show error message
            }
        })
        .finally(function () {
          contactBlock.stop();
        });
    };

    vm.updateContactDetails = function () {
      contactBlock.start();

      User.updateContactDetails(vm.contactDetails)
        .then(function () {
          sweetAlert({
            type: 'success',
            title: $translate.instant('USERS.MESSAGES.CONTACT_DETAILS_UPDATED_SUCCESSFULLY'),
            timer: 2500,
            showConfirmButton: false
          });
        }, function (res) {
          vm.errorMessages = [];
          if (res.status == 422) {
            angular.forEach(res.data.errors, function (messages) {
              angular.forEach(messages, function (message) {
                vm.errorMessages.push(message);
              });
            });
          } else {
            // TODO: show error message
          }
        })
        .finally(function () {
          contactBlock.stop();
        });
    };

    vm.changePassword = function () {
      passwordBlock.start();

      User.changePassword(vm.password)
        .then(function () {
          sweetAlert({
            type: 'success',
            title: $translate.instant('USERS.MESSAGES.PASSWORD_CHANGED_SUCCESSFULLY'),
            timer: 3000
          });
          vm.passwordErrorMessage = '';
          vm.password.new_password = '';
          vm.changePassword.form.password_confirmation.$setViewValue('');
          vm.changePassword.form.password_confirmation.$modelValue = '';
          vm.changePassword.form.$setPristine();
          vm.changePassword.form.$setUntouched();
        }, function (res) {
          if (res.data.error.code == 101) {
            vm.passwordErrorMessage = $translate.instant('USERS.MESSAGES.BAD_OLD_PASSWORD');
          } else {
            vm.passwordErrorMessage = 'An error occurred!';
          }
        })
        .finally(function () {
          vm.password.old_password = '';

          passwordBlock.stop();
        });
    };
  };

  angular.module('musaned.app.user')
      .controller('UserDetailsCtrl', UserDetailsCtrl);
})();
