(function () {
  'use strict';

  var VisaPrintCtrl = function ($scope, $modalInstance, $window, details) {
    var vm = this;

    $scope.visa = details;

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.print = function () {
      $window.print();
    }
  };

  angular.module('musaned.app.visas')
    .controller('VisaPrintCtrl', VisaPrintCtrl);
})();
