(function () {
  'use strict';

  var SidebarCtrl = function (Sidebar,$scope,$modal, $state) {
    var vm = this;
    vm.getData = function () {
      Sidebar.getData()
        .then(function (res) {
          vm.data = res.data;
        }, function (res) {
          //
        })
        .finally(function () {
          //
        });
    }();

    vm.Count = function () {
      Sidebar.Count()
        .then(function (res) {
          vm.data.currentContractRequestsCount = res.data.currentContractRequestsCount;
          vm.data.previousContractCount = res.data.previousContractCount;

        }, function (error) {
        })
        .finally(function () {
          //
        });
    }();

    vm.addNotice = function () {
      $scope.modalInstance = $modal.open({
        animation: false,
        templateUrl: 'partials/app/notices/noticeMSG.html',
        size: 'lg',
        windowClass: 'print-visa-modal',
        scope: $scope,
        backdrop: 'static',
        keyboard: false
      });
    };

    vm.goToNotice = function () {
      $scope.modalInstance.close();
      $state.go('notices.create');
    };


  };

  angular.module('musaned.app.sidebar')
    .controller('SidebarCtrl', SidebarCtrl);
})();
