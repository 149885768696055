(function () {
    'use strict';

    var VisaMosaIssueRequests = function ($http) {
        var VisaIssueRequests = {};

        VisaIssueRequests.all = function (page) {
            if (!page) page = 1;
            return $http.get('/app/visas/mosa_issue_requests', {
                params: {page: page}
            });
        };

        VisaIssueRequests.get = function (requestId) {
            return $http.get('/app/visas/mosa_issue_requests/' + requestId);
        };
        
        return VisaIssueRequests;
    };

    angular.module('musaned.app.visas.mosa.issueRequests')
        .service('VisaMosaIssueRequests', VisaMosaIssueRequests)
})();
