(function () {
  'use strict';

  var Contract = function ($http,App_URLs) {
    var vm = this;

    vm.get = function (contractId) {
      return $http.get(App_URLs.eContractingBaseUri + '/api/contracts/' + contractId);
    };

      vm.getContractPaymentPage = function (contractId, paymentMethod) {
          return $http.get(App_URLs.eContractingBaseUri + '/api/payfort/' + contractId + '/getPaymentPage/' + paymentMethod);
      };

    vm.getContractPaymentChannel = function (contractId) {
          return $http.get(App_URLs.eContractingBaseUri + '/api/contracts/' + contractId + '/payment-channel/channel');
    };

    vm.getOffer = function (requestId, offerId) {
      return $http.get(App_URLs.eContractingBaseUri + '/api/requests/' + requestId + '/offers/' + offerId);
    };


    vm.setPaymentChannel = function (contractId, payment_channel) {
      return $http.post(App_URLs.eContractingBaseUri + '/api/contracts/' + contractId + '/payment-channel', {
        channel:payment_channel
      });
    };

    vm.all = function (page) {
      return $http.get(App_URLs.eContractingBaseUri + '/api/contracts', {
        params: {page: page}
      });
    };

    vm.rateMusaned = function (contractId, rate) {
      return $http.get(App_URLs.eContractingBaseUri + '/api/contracts/' + contractId + '/rate/musaned/' + rate);
    }

    vm.ratePro = function (contractId, rate) {
      return $http.get(App_URLs.eContractingBaseUri + '/api/contracts/' + contractId + '/rate/pro/' + rate);
    }

    vm.EndUserDiscountRequest = function (offer_id, discount_type) {
      return $http.get(App_URLs.eContractingBaseUri + '/api/offers/' + offer_id +'/discount/'+ discount_type);
    }

  };

  angular.module('musaned.app.contracts.contracts')
    .service('Contract', Contract);
})();
