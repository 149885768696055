(function () {
  'use strict';

  var Auth = function ($rootScope, $http, $q, locker, Idle, AuthEvents, $state) {
    var Auth = {};

    var user = false;

    Auth.login = function (data) {
      user = false;
      return $http.post('/app/auth/login', data)
        .then(function (res) {
          if (res.data.verified === 'true') {
            $state.go('user.dashboard');
          } else {
            $state.go('user.verify');
          }
        });
    };

    Auth.logout = function () {
      return $http.post('/app/auth/logout')
        .then(function () {
          locker.forget('AUTH-TOKEN');
          Idle.unwatch();
          $rootScope.$emit(AuthEvents.LOGOUT_SUCCESS);
        });
    };

    Auth.fetchUserInfo = function () {
      return $q(function (resolve, reject) {
        if (user !== false) {
          resolve(user);
        }

        $http.get('/app/user/info')
          .then(function (res) {
            $rootScope.userInfo = res.data;
            $rootScope.show_current_balance = res.data.show_current_balance;
            $rootScope.show_current_balance_and_refund_request = res.data.show_current_balance_and_refund_request;
            $rootScope.show_notice_balance = res.data.show_notice_balance;
            $rootScope.show_notice_balance_and_refund_request = res.data.show_notice_balance_and_refund_request;
            resolve(res.data);
          }, function (res) {
            $state.go('user.login');
            reject(res.data.message);
          });
      })
        .then(function (userInfo) {
          user = userInfo;

          $rootScope.$emit(AuthEvents.USER_INFO_UPDATED, userInfo);
          Idle.watch();
        });
    };

    Auth.fetchUserWorkers = function () {

      return $q(function (resolve, reject) {
        console.log(user.workers);
        if (user.workers !== undefined) {
          return resolve({'workers': user.workers, 'visas': user.visas});
        }

        $http.get('/app/user/info_workers')
          .then(function (res) {
            user.workers = $rootScope.userInfo.workers = res.data.workers;
            user.visas = $rootScope.userInfo.visas = res.data.visas;
            resolve(res.data);
          }, function (res) {
            $state.go('user.login');
            reject(res.data.message);
          })
          .then(function (userInfo) {
            Idle.watch();
          });
      });
    };

    Auth.verify = function (data) {
      return $http.post('/app/auth/verify', data)
        .then(function (res) {
            Auth.fetchUserInfo();
            if( res.data.message ){ $rootScope.warningMessage = res.data.message;}
            $state.go('user.dashboard');
        });
    };

    Auth.resend = function () {
      return $http.post('/app/auth/resend');
    };

    Auth.user = function () {
      return user;
    };

    Auth.isAuthenticated = function () {
      return user !== false;
    };

    return Auth;
  };

  angular.module('musaned.app.auth')
    .service('Auth', Auth);
})();
