(function () {
  'use strict';

  var ContractRequestCancelCtrl = function ($scope, $stateParams, ContractRequests, blockUI,$translate,$state) {
    var vm = this;

    $scope.contracts = $stateParams.contractId;
    vm.errorMessage = '';
    vm.successMessage = '';
    $scope.cr = {};
    ContractRequests.show($stateParams.contractId)
        .then(function (res) {
          vm.contract = res.data;
        })
        .finally(function () {
          block.stop();
        });
    $scope.confirm = function () {
      vm.data = { reason :   $scope.cr.reason };
      vm.errorMessage = '';
      var block = blockUI.instances.get('contract_cancel');
      block.start();
      ContractRequests.cancel($stateParams.contractId,vm.data)
        .then(function () {
          vm.successMessage =  $translate.instant("CONTRACTS.REQUESTS.CANCELLATION_REQUESTS.PHRASES.CANCEL_REQUEST_ADDED");

        },function (res) {
          vm.successMessage = '';
          if (res.data.error.code == 101) {
            vm.errorMessage = $translate.instant('USERS.MESSAGES.LOGIN.INVALID_CREDENTIALS');
          }
          if (res.data.error.code == 100) {
            vm.errorMessage = $translate.instant('CONTRACTS.REQUESTS.LABELS.CANCEL_REQUEST_EXIST');
          }
        })
          .finally(function () {
            block.stop();
          });
    }
  };

  angular.module('musaned.app.contracts.requests')
    .controller('ContractRequestCancelCtrl', ContractRequestCancelCtrl);
})();
