(function () {
  'use strict';

  var paymentCompensationCtrl = function ($scope, $stateParams, ContractRequests, blockUI,$translate,$ocLazyLoad) {
    var vm = this;

    $scope.paymentId = $stateParams.paymentId;
      $ocLazyLoad.load('/assets/js/pdf.combined.js');

    vm.errorMessage = '';
    vm.successMessage = '';
    var block = blockUI.instances.get('paymentCompensation');
    $scope.cr = {};
    ContractRequests.showPaymentCompensation($stateParams.paymentId)
        .then(function (res) {
          vm.payment = res.data.payment;
        })
        .finally(function () {
          block.stop();
        });
    vm.rejectPaymentCompensation = function () {
      vm.data = { reason :   $scope.cr.reason };
      vm.errorMessage = '';
      block.start();
      ContractRequests.rejectPaymentCompensation($stateParams.paymentId,vm.data)
          .then(function () {
            vm.successMessage = $translate.instant("CONTRACTS.REQUESTS.CANCELLATION_REQUESTS.PHRASES.REJECT_PAYMENT");
          },function (res) {
            vm.successMessage = '';
            if (res.data.error.code == 101) {
              vm.errorMessage = $translate.instant('USERS.MESSAGES.LOGIN.INVALID_CREDENTIALS');
            }
          })
          .finally(function () {
            block.stop();
          });
    }

    vm.acceptPaymentCompensation = function () {
      vm.errorMessage = '';
      block.start();
      ContractRequests.acceptPaymentCompensation($stateParams.paymentId)
          .then(function () {

            vm.successMessage =  $translate.instant("CONTRACTS.REQUESTS.CANCELLATION_REQUESTS.PHRASES.ACCEPT_PAYMENT");
          },function (res) {
            vm.successMessage = '';
            if (res.data.error.code == 101) {
              vm.errorMessage = $translate.instant('USERS.MESSAGES.LOGIN.INVALID_CREDENTIALS');
            }
          })
          .finally(function () {
            block.stop();
          });
    }
  };

  angular.module('musaned.app.contracts.requests')
    .controller('paymentCompensationCtrl', paymentCompensationCtrl);
})();
