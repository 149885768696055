(function () {
    'use strict';

    var ContractRequestsListCtrl = function (ContractRequests, Visas, blockUI, $translate, $rootScope) {
        var vm = this;

        vm.errorMessage = $rootScope.errorMessage;
        $rootScope.errorMessage = '';

        vm.successMessage = $rootScope.successMessage;
        $rootScope.successMessage = '';
        vm.currentPage = null;
        var block = blockUI.instances.get('proposals_list');
        block.start();

        ContractRequests.occupations()
            .then(function (res) {
                vm.occupations = res.data.occupations;
            });

        ContractRequests.nationalities()
            .then(function (res) {
                vm.nationalities = res.data.nationalities;
            });

        vm.contractRequestSearch = function () {

            block.start();
            vm.getRequests();

        }

        vm.filter = {};

        vm.pageChanged = function () {

            vm.filter.page = vm.currentPage;

            vm.getRequests();
        };

        vm.getRequests = function () {


            ContractRequests.requestSearch(vm.filter)
                .then(function (res) {
                    Visas.all(0).then(function (visa_response) {

                        vm.proposals = res.data.data;

                        for (var i = 0; i < vm.proposals.length; i++) {
                            var visa = _.find(visa_response.data.paginator.data, function (visa) {
                                return vm.proposals[i].visa_id == visa.id;
                            });
                            vm.proposals[i].visa = visa;
                            vm.proposals[i].created_at = new Date(vm.proposals[i].created_at);

                            angular.forEach(vm.proposals[i].offers, function (value) {
                                if (value.contract) {
                                    vm.proposals[i].contract = value.contract;
                                }
                            });
                        }

                        ContractRequests.preVisaContracts()
                            .then(function (res) {
                                angular.forEach(res.data.paginator.data, function (value) {
                                    var data = angular.fromJson(value.contract_request);
                                    vm.proposals.unshift({
                                        'pre_visa': true,
                                        'id': value.id,
                                        'created_at': value.created_at,
                                        'visa': {
                                            'occupation': vm.occupations[data.occupation],
                                            'nationality': vm.nationalities[data.nationality]
                                        }
                                    });
                                });
                            });
                        vm.currentPage = res.data.current_page;
                        vm.per_page = res.data.per_page;
                        vm.total = res.data.total;
                    });
                })
                .finally(function () {
                    block.stop();
                });

        }

        vm.getRequests();

        vm.locateAgreedOfficeRequest = function () {
            for (var i in vm.proposals) {
                vm.proposals[i].waitingForOfficesResponse = 0;
                vm.proposals[i].waitingForUserResponse = 0;
                vm.proposals[i].allRequestsAreRejected = vm.proposals[i].office_contract_requests.length;

                for (var key in vm.proposals[i].office_contract_requests) {
                    var contract = vm.proposals[i].office_contract_requests[key].contract;

                    vm.proposals[i].waitingForOfficesResponse += contract == null && vm.proposals[i].office_contract_requests[key].status == 1 ? 1 : 0;
                    vm.proposals[i].waitingForUserResponse += contract != null && contract.status == 1;
                    vm.proposals[i].allRequestsAreRejected -= (
                        (vm.proposals[i].status == 2) || // contract request is rejected
                        (vm.proposals[i].office_contract_requests[key].status == 2) || // office contract request is rejected
                        (contract != null && contract.status == 2) // contract rejected
                    ) ? 1 : 0;

                }
                vm.proposals[i].allRequestsAreRejected = vm.proposals[i].allRequestsAreRejected == 0;

            }
        };
        vm.cancelContract = function (contract) {
            vm.errorMessage = '';
            vm.successMessage = '';
            sweetAlert({
                title: $translate.instant("VISAS.CANCELLATION_REQUESTS.PHRASES.REQUEST_VISA_CANCELLATION"),
                text: $translate.instant("VISAS.CANCELLATION_REQUESTS.PHRASES.REQUEST_VISA_CANCELLATION_TEXT"),
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: $translate.instant("COMMON.ACTIONS.YES"),
                cancelButtonText: $translate.instant("COMMON.ACTIONS.NO"),
                closeOnConfirm: false,
                showLoaderOnConfirm: true
            }, function (confirmed) {
                if (confirmed) {
                    ContractRequests.cancel(contract)
                        .then(function () {
                            sweetAlert({
                                title: $translate.instant("VISAS.CANCELLATION_REQUESTS.PHRASES.CANCELLATION_REQUEST_SENT"),
                                type: "success"
                            });
                        }, function (res) {
                            sweetAlert({
                                type: 'error',
                                title: 'issue in cancelling'
                            })
                        });

                }
            });


        };
    };

    angular.module('musaned.app.contracts.requests')
        .controller('ContractRequestsListCtrl', ContractRequestsListCtrl);
})();