(function () {
  'use strict';

  var LogoutCtrl = function ($rootScope, $state, Auth, AuthEvents) {
    var vm = this;

    vm.logout = function () {
      Auth.logout();

      $rootScope.$on(AuthEvents.LOGOUT_SUCCESS, function () {
        $state.go('home');
      })
    };
  };

  angular.module('musaned.app.auth')
    .controller('LogoutCtrl', LogoutCtrl);
})();
