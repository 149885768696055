(function () {
    'use strict';

  var VisasListCtrl = function ($rootScope, $scope, blockUI, $modal, $translate, Visas, User, $state, $anchorScroll, $location, VisaIssueRequestCreation) {
    var vm = this;
    vm.errorMessage = $rootScope.errorMessage;
    $rootScope.errorMessage = '';

    vm.successMessage = $rootScope.successMessage;
    $rootScope.successMessage = '';
    vm.data = {
      request_type:'0'
    };
    $scope.reasons = [];
    vm.can_request_new_visa = true;
    vm.statuses = [];

    var block = blockUI.instances.get('visas_list');
    block.start();
    User.reasons_cancel_visa()
        .then(function (res) {
            vm.reasons = res.data;
        });

    vm.checkIssueRequest = function(){
      VisaIssueRequestCreation.checkPermission()
        .then(function (res) {
          vm.can_request_new_visa = true;
            $rootScope.occupations=res.data.occupationsList;
        }, function (res) {
          $location.hash('errorMessage');
          $anchorScroll();
          vm.can_request_new_visa = false;
          if (res.data.error.code == 120) {
            vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.EXCEEDED_MAX_ALLOWED_REQUEST');
          } else if (res.data.error.code == 121) {
            vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.GENDER_STATUS_ERROR');
          } else if (res.data.error.code == 122) {
            vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.STOP_GOVERNMENT_SERVICES');
          } else if (res.data.error.code == 106) {
            vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.DIED');
          } else if (res.data.error.code == 105) {
              vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.ERROR__NOT_FOUND');
          } else if (res.data.error.code >= 130 && res.data.error.code < 140) {
            vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.'+res.data.error.code);
          }else {
            vm.errorMessage = $translate.instant(res.data.error.message);
          }
        })
        .finally(function () {
          block.stop();
        });
    };

    vm.pageChanged = function () {

            Visas.all(vm.currentPage)
                .then(function (res) {
                    vm.paginator = res.data.paginator;
                    vm.statuses = res.data.statuses;
                    vm.currentPage = vm.paginator.current_page;
                })
                .finally(function () {
                    block.stop();
                });
        };

    vm.checkIssueRequest();
    vm.pageChanged();

        vm.printVisa = function (visa) {
            var modalInstance = $modal.open({
                animation: false,
                templateUrl: 'partials/app/visas/print.html',
                controller: 'VisaPrintCtrl',
                size: 'lg',
                windowClass: 'print-visa-modal',
                resolve: {
                    details: function () {
                        return visa;
                    }
                }
            });
        };

        vm.cancelVisa = function (visa) {

            vm.data.request_type = '1';
            vm.data.visa = visa;
        };

        vm.cancel = function () {
            vm.data = {
                request_type: '0'
            };
        };


        vm.apply = function () {
            block.start();
            vm.dataa = {
                reason: vm.data.reasons,
                visa: vm.data.visa.id
            };
            return User.cancel_visa(vm.dataa)
                .then(function (res) {
                    block.stop();
                    sweetAlert({
                        type: 'success',
                        title: 'حالة طلب الغاء تأشيرة',
                        text: res.data.status,
                        timer: 10000
                    });
                    vm.data = {
                        request_type: '0'
                    };
                    vm.pageChanged();
                }, function (res) {
                    block.stop();
                    sweetAlert({
                        type: 'error',
                        title: 'حالة طلب الغاء تأشيرة',
                        text: res.data.errors.reason[0],
                        timer: 10000
                    });
                });
        };

        Visas.getSetting().then(function (respone) {
            vm.setting = respone.data;
        });
    };

    angular.module('musaned.app.visas')
        .controller('VisasListCtrl', VisasListCtrl);
})();
