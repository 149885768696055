(function () {
  'use strict';

  var VisaIssueRequestShowCtrl = function ($stateParams, $modal, locker, blockUI, VisaIssueRequests, $ocLazyLoad, $rootScope) {
    var vm = this;

    $ocLazyLoad.load('assets/js/pdf.combined.js');

    vm.requestId = $stateParams.requestId;

    VisaIssueRequests.get(vm.requestId)
      .then(function (res) {
        vm.request = res.data.issueRequest;
        $rootScope.show_current_balance = res.data.show_current_balance;
        $rootScope.show_current_balance_and_refund_request = res.data.show_current_balance_and_refund_request;
      });

  };

  angular.module('musaned.app.visas.issueRequests')
    .controller('VisaIssueRequestShowCtrl', VisaIssueRequestShowCtrl);
})();
