(function () {
  'use strict';

  var officeRequest = function ($http, App_URLs) {
    var vm = this;

    vm.get = function (RequestId) {
      return $http.get(App_URLs.eContractingBaseUri + '/api/requests/' + RequestId);
    };

    vm.getPreVisaRequest = function (RequestId) {
      return $http.get('/app/contracts/requests/preVisaContracts/' + RequestId);
    };

    vm.getProServices = function (serviceId) {
      return $http.get(App_URLs.proBaseUri + '/api/offices', {
        params: {service_id : serviceId}
      });
    };

    vm.Worker_Confirm = function (contract_id) {
      return $http.post('app/contracts/' + contract_id + '/confirm');
    };

    vm.Accept_Offer = function (contract_request_id, offer_id, cvs) {
      return $http.put(App_URLs.eContractingBaseUri + '/api/requests/' + contract_request_id + '/offers/' + offer_id + '/accept',{
        'cvs': cvs
      });
    };

    vm.getCity = function (city_id) {
      return $http.get('/api/city/' + city_id);
    };
    vm.CancelContract= function (contract_id,data){
        return $http.post(App_URLs.eContractingBaseUri +'/api/contracts/'+contract_id+'/cancel',data);
    }

    vm.CancelRequest = function (request_id){
      return $http.put(App_URLs.eContractingBaseUri +'/api/requests/'+request_id+'/cancel');
    }

  };

  angular.module('musaned.app.contracts.officeRequests')
    .service('officeRequest', officeRequest);
})();
