(function () {
  'use strict';

  var ContractRequestCreationCtrl = function ($scope, $state, $translate, $rootScope, $timeout, $stateParams, blockUI, WizardHandler, Upload, Visas, VisaIssueRequests, ContractRequests, $ocLazyLoad, $modal, Auth, $filter) {
    var vm = this;
    var block = blockUI.instances.get('proposal_form');

    var user = Auth.user();
    vm.must_update_profile = !user.personal_details || !user.personal_details.route;
    vm.preVisaContracting = !!$stateParams.requestId;
    $scope.dmMax = 0;
    vm.recommendedOnly = true;
    vm.availableOptions = [
      {id: 'PREFERENCES', name: $translate.instant('CONTRACTS.REQUESTS.LABELS.METHOD2')},
      {id: 'PRESELECTED', name: $translate.instant('CONTRACTS.REQUESTS.LABELS.METHOD1')}
    ];
    vm.data = {};


    block.start();

    vm.e_contract_off = 0;
    ContractRequests.getSetting('turn_off_eContract')
      .then(function (res) {
        vm.e_contract_off = parseInt(res.data.value);
        ContractRequests.getSetting('turn_off_eContract_message')
          .then(function (res) {
            vm.e_contract_off_message = res.data.value;
          })
      })
      .finally(function () {
        block.stop();
      });


    $ocLazyLoad.load('/assets/js/pdf.combined.js');
    $ocLazyLoad.load('/assets/js/FileAPI.min.js');
    vm.files = [];
    vm.uploads = [];
    vm.upload_disabled = false;
    vm.fetchedVisa = false;
    vm.next1 = vm.next2 = false;
    vm.upload = function (file) {
      if (file) {
        block.start();

        file.upload = Upload.upload({
          url: '/app/user/uploads/upload' + vm.getReqParams(),
          method: 'POST',
          file: file,
          fileFormDataName: 'file'
        });

        file.upload
            .then(function (res) {
              $scope.step1.file = res.data.userUpload;
              vm.receipt = res.data.userUpload;
              vm.errorMsg = null;
            }, function (res) {
              vm.errorMsg = [];
              if (res.status == 422) {
                vm.errorMsg.push('(' + file.name + ') ' + res.data.errors.file);
              } else {
                vm.errorMsg.push('(' + file.name + ') ' + $translate.instant(res.data.error.message));
              }

            })
            .finally(function () {
              block.stop();
            });
      }
    };
    vm.removeFile = function () {
      vm.receipt = null;
      $scope.step1.file = null;
    };


    vm.getReqParams = function () {
      return vm.generateErrorOnServer ? '?errorCode=' + vm.serverErrorCode + '&errorMessage=' + vm.serverErrorMsg : '';
    };

    vm.sortBy = null;
    vm.sortDirection = false;

    vm.sortOffer = function(sortBy, flip){
      angular.forEach($scope.pros,function($item, $key){
        $scope.pros[$key].city_label = '';
        if($scope.getCity($item.address.city_id)) {
          if ($scope.lang == 'en') {
            $scope.pros[$key].city_label = $scope.getCity($item.address.city_id).label_en;
          } else {
            $scope.pros[$key].city_label = $scope.getCity($item.address.city_id).label_ar;
          }
        }
      });
      if(sortBy) {
        vm.sortBy = sortBy;
        if (flip){
          if (vm.sortDirection) {
            vm.sortDirection = false;
          } else {
            vm.sortDirection = true;
          }
        }
        $scope.pros = $filter('orderBy')($scope.pros, sortBy, vm.sortDirection);
      }
    }

    vm.gotoTop = function(){
      $("html, body").animate({ scrollTop: 0 }, "slow");
    }

    $scope.step1 = {};
    $scope.step2 = {};
    $scope.step3 = {};

    /*TODO
     * 1) Split option populating  and obtaining the visa info in "ContractRequests.lookups "
     *   by refactoring that into the following:
     *         * ContractRequests.options() now gets the options for populating the drop down list
     *         * ContractRequests.visa or any method name that gets the visa info
     *         * ContractRequests.visa_request or any method name that gets the visa request info in case  of preVisaContracting
     *
     * */
    if (vm.preVisaContracting) {
      vm.next1 = true;
      vm.data.request_type = 'PREFERENCES';
      $scope.requestId = $stateParams.requestId;
      VisaIssueRequests.get($scope.requestId)
          .then(function (res) {
            $scope.issueRequest = res.data.issueRequest;
            vm.fetchedVisa = true;
          }, function () {
            //err
          })
      ContractRequests.contractingOptions().then(function (res) {
            $scope.languages = res.data.lookups.languages;
            $scope.skills = res.data.lookups.skills;
            $scope.qualifications = res.data.lookups.qualifications;
            $scope.regions = res.data.lookups.regions;
            $scope.cities = res.data.lookups.cities;
          }, function () {
            $rootScope.errorMessage = 'الطلب غير موجود.';
            $state.go('contracts.requests.new');
          })
          .finally(function () {
            block.stop();
          });
    } else {

      block.start();
      ContractRequests.availableVisas()
        .then(function (res) {
            $scope.available_visas = res.data.visas;
        }, function () {
          //error
        })
        .finally(function () {
          block.stop();
        });
      $scope.selectVisa = function (visaId) {
        block.start();
        $scope.visaId = visaId;
        Visas.get(visaId)
            .then(function (res) {
              $scope.visa = res.data.visa;
              vm.fetchedVisa = true;
            }, function () {
              //err
            })
          .finally(function () {
            block.stop();
          });
        ContractRequests.lookups($scope.visaId)
            .then(function (res) {
              $scope.visa = res.data.lookups.visa;
              $scope.languages = res.data.lookups.languages;
              $scope.skills = res.data.lookups.skills;
              $scope.qualifications = res.data.lookups.qualifications;
              $scope.regions = res.data.lookups.regions;
              $scope.cities = res.data.lookups.cities;
            }, function () {
              $rootScope.errorMessage = 'الطلب غير موجود.';
              $state.go('contracts.requests.new');
            })
            .finally(function () {
              $scope.visaChanged = true;
              $scope.proSearch();
              block.stop();
            });
      };
    }
    block.start();

    vm.maxSelectedOfficesNumber = 0;
    ContractRequests.getSetting('selected_offices_maximum')
        .then(function (res) {
          vm.maxSelectedOfficesNumber = res.data.value;
        })
        .finally(function () {
          block.stop();
        });

    $scope.watchRegions = function () {
      block.start();

      ContractRequests.cities($scope.step3.region_id)
          .then(function (res) {
            $scope.cities = res.data.cities;
          })
          .finally(function () {
            block.stop();
          });
    };

    $scope.getPros = function (page, op) {
      if ('next' == op) {
        page = page + 1;
      } else {
        page = page - 1;
      }
      $scope.page = page;
      $scope.proSearch();
    };

    $scope.proSearch = function () {
      $scope.step3.office = [];
      block.start();

      var data = {
        occupation: vm.preVisaContracting ? $scope.issueRequest.occupation.id : $scope.visa.occupation.id,
        nationality: vm.preVisaContracting ? $scope.issueRequest.nationality.id : $scope.visa.nationality.id,
      };

      if ($scope.page) {
        data.page = $scope.page;
      }


      if (!$scope.visaChanged) {

        if ($scope.proPriceMin) {
          data.price_min = $scope.proPriceMin;
        }

        if ($scope.proPriceMax) {
          data.price_max = $scope.proPriceMax;
        }

      }
      data.recommendedOnly = vm.recommendedOnly;
      data.city_id =$scope.step1.city_id;
      if (vm.proRegion) {
        data.region = vm.proRegion.id;
      }

      if (vm.proCity) {
        data.city = vm.proCity.id;
      }

      if (vm.proName) {
        data.name = vm.proName;
        ContractRequests.searchCity(vm.proName)
            .then(function (res) {
              if( res.data.cities.length > 0 ) {
                data.city_id = res.data.cities[0].id;
              }
            }).finally(function () {
          $scope.proSearchInit(data);
        });
      } else {
        $scope.proSearchInit(data);
      }

    };

    $scope.proSearchInit = function(data){
      if($scope.step1.religion && vm.data.request_type=='PREFERENCES' && $scope.step1.religion!=3){
        data.religion_id = $scope.step1.religion;
      }
      ContractRequests.proSearch(data)
          .then(function (res) {
            $scope.pros = res.data.offices;
            $scope.page = res.data.current_page;

          ContractRequests.proRatings({offices: _.pluck($scope.pros, 'id').toString()})
            .then(function (response) {
              angular.forEach($scope.pros, function ($item) {
                $item.pro_rating = (response.data[$item.id]) ? response.data[$item.id] : 0;
              });
            })
            .finally(function () {

            });
          
          if (!data.price_max) {
            $scope.proPriceMin = res.data.min_cost;
            $scope.dmMax = ($rootScope.lang == 'en') ? res.data.max_cost : 0;
            $scope.proPriceMax = res.data.max_cost;
            $scope.visaChanged = false;
          }

            if( res.data.max_cost ){
              if(res.data.min_cost==res.data.max_cost){
                res.data.min_cost = 0;
                $scope.proPriceMin = 0;
              }

              if($rootScope.lang == 'ar') {
                $scope.dmMax = Math.abs($scope.dmMax - res.data.max_cost);
              }

              vm.costFilter = true;
              $("#costSlider").slider({
                range: false,
                min: res.data.min_cost,
                max: res.data.max_cost,
                value: $scope.dmMax,
                slide: function (event, ui) {
                  if($rootScope.lang == 'en') {
                    $scope.proPriceMax = ui.value;
                    $scope.dmMax = ui.value;
                  } else {
                    $scope.proPriceMax = res.data.max_cost - ui.value;
                    $scope.dmMax = res.data.max_cost - ui.value;
                  }
                  $scope.$digest();
                }
              });
            }
          })
          .finally(function () {
            block.stop();
            vm.sortOffer(vm.sortBy, false);
          });
    };


    $scope.getCity = function (city_id) {
      return _.find($scope.cities, function (city) {
        return city.id == city_id
      });
    };


    $scope.getRegion = function (region_id) {
      return _.find($scope.regions, function (region) {
        return region.id == region_id
      });
    };

    $scope.finishWizard = function () {
      var block = blockUI.instances.get('proposal_form');
      block.start();
      $scope.errorMessage = '';
      var data = {
        visa: vm.preVisaContracting ? '' : $scope.visaId,
        followup_number: vm.preVisaContracting ? '' :$scope.visa.followup_number,
        border_number: vm.preVisaContracting ? '' :$scope.visa.border_number,
        requestId: vm.preVisaContracting ? $scope.requestId : '',
        occupation: vm.preVisaContracting ? $scope.issueRequest.occupation.id : $scope.visa.occupation.id,
        nationality: vm.preVisaContracting ? $scope.issueRequest.nationality.id : $scope.visa.nationality.id,
        domestic_laborers: $rootScope.userInfo.personal_details.domestic_laborers,
        work_type: $rootScope.userInfo.personal_details.job,
        family_size: $rootScope.userInfo.personal_details.family_size,
        location: $rootScope.userInfo.personal_details.coordinates,
        id_number: $rootScope.userInfo.id_number,
        lat_lng: $rootScope.userInfo.personal_details.coordinates,
        house_type_id: $rootScope.userInfo.personal_details.house_type_id,
        average_income: $rootScope.userInfo.personal_details.average_income,
        worker_upload_id: $scope.step1.file ? $scope.step1.file.id : '',
        delivery_city_id:  $scope.step1.city_id
      };
      $scope.step3.office = Object.keys(new Object($scope.step3.office)).filter(function (key) {
        return $scope.step3.office[key];
      });
      $scope.step1.skills = Object.keys(new Object($scope.step1.skills)).filter(function (key) {
        return $scope.step1.skills[key];
      });
      $scope.step1.languages = Object.keys(new Object($scope.step1.languages)).filter(function (key) {
        return $scope.step1.languages[key];
      });
      angular.extend(data, vm.data, $scope.step1, $scope.step2, $scope.step3);

      //TODO: if request from preVisa send to entire controller
      if (vm.preVisaContracting) {
        return ContractRequests.preVisaContract(data)
            .then(function (res) {
              if (res.status == 200) {
                $rootScope.successMessage = 'تم حفظ طلبك بنجاح. سيتم إرسال طلب التعاقد فور إصدار التأشيرة.';
                $state.go('contracts.officeRequests.list', {
                  requestId: res.data.id,
                  type: 'preVisa',
                  created: true
                });
              }
            }, function (res) {
              if (res.status == 422) {
                vm.errorMessage = 'خطأ في المدخلات, تأكد من صحة البيانات المدخلة';
              } else if (res.status == 100) {
                vm.errorMessage = 'التأشيرة غير متاحة';
              } else if (res.status == 101) {
                vm.errorMessage = 'هذا المكتب لايقدم هذه الخدمة';
              } else if (res.status == 409) {
                vm.errorMessage = 'يوجد لديك طلب تعاقد مسبق التأشيرة آخر';
              } else if (res.status == 429) {
                vm.errorMessage = 'لقد أرسلت اكثر من طلب خلال مدة قصيرة الرجاء الإنتظار ومن ثم إعادة المحاولة';
              } else {
                vm.errorMessage = 'حصلت مشكلة لم يتم تنفيذ طلبك الرجاء المحاولة لاحقاً';
              }
            })
            .finally(function () {
              block.stop();
            });
      }
//else
      else {

        return ContractRequests.create(data)
            .then(function (res) {
              if (res.status == 200) {
                sweetAlert({
                  type: 'success',
                  title: 'تقديم طلب تعاقد',
                  text: 'تم إرسال طلبك إلى مكاتب الاستقدام بنجاح.',
                  timer: 10000
                });
                $state.go('contracts.officeRequests.list', {
                  requestId: res.data.id,
                  created: true
                });
              }
            }, function (res) {
              if (res.status == 422) {
                vm.errorMessage = {
                  message: 'خطأ في المدخلات, تأكد من صحة البيانات المدخلة',
                  error: res.data
                }
              } else if (res.status == 100) {
                vm.errorMessage = 'التأشيرة غير متاحة';
              } else if (res.status == 101) {
                vm.errorMessage = 'هذا المكتب لايقدم هذه الخدمة';
              } else if (res.status == 429) {
                vm.errorMessage = 'لقد أرسلت اكثر من طلب خلال مدة قصيرة الرجاء الإنتظار ومن ثم إعادة المحاولة';
              } else {
                vm.errorMessage = 'حصلت مشكلة لم يتم تنفيذ طلبك الرجاء المحاولة لاحقاً';
              }
            })
            .finally(function () {
              block.stop();
            });
      }

    };

    $scope.toggle_details = function () {
      $scope.show_details = !$scope.show_details;
    };

    $scope.validateStep1Method1 = function () {
      if (!$scope.step1) {
        return false;
      }

      var validate = (
              $scope.step1.worker_id != null &&
              $scope.step1.worker_number != null &&
              $scope.step1.city_id != null
          ) || vm.preVisaContracting;
      return validate;
    };

    $scope.validateStep1Method2 = function () {
      if (!$scope.step1) {
        return false;
      }

      var validate = (
          $scope.step1.age_range != null &&
          $scope.step1.religion != null &&
          $scope.step1.work_experience != null &&
          $scope.step1.city_id != null

      );
      return validate;
    };

    $scope.validateStep3 = function () {
      var proceed = false;
      if (!$scope.step3) {
        return false;
      }
      if ($scope.step3.office) {
        vm.selected = Object.keys($scope.step3.office).filter(function (key) {
          return $scope.step3.office[key];
        });
        proceed = vm.selected.length > 0 && vm.selected.length <= vm.maxSelectedOfficesNumber;
      }

      return proceed;
    };
    vm.contractRequestSummary = function () {
      var data = {
        visa: vm.preVisaContracting ? '' : $scope.visaId,
        requestId: vm.preVisaContracting ? $scope.requestId : '',
        occupation: vm.preVisaContracting ? $scope.issueRequest.occupation.id : $scope.visa.occupation.id,
        nationality: vm.preVisaContracting ? $scope.issueRequest.nationality.id : $scope.visa.nationality.id,
        offices: $scope.pros,

        summary:vm.preVisaContracting ? $scope.issueRequest: $scope.visa,
      };
      angular.extend(data, vm.data, $scope.step1, $scope.step2, $scope.step3, {'pros': $scope.pros});
      var modalInstance = $modal.open({
        animation: false,
        templateUrl: 'partials/app/contracts/requests/summary.html',
        controller: 'ContractRequestSummaryCtrl',
        size: 'lg',
        windowClass: 'contract-request-summary-modal',
        resolve: {
          details: function () {
            return data;
          }
        }
      });
    };
  };

  angular.module('musaned.app.contracts.requests')
      .controller('ContractRequestCreationCtrl', ContractRequestCreationCtrl);
})();
