(function () {
    'use strict';

    var ContractsShowCtrl = function ($stateParams, $state, Contract, blockUI, $rootScope, $scope, officeRequest, $translate, App_URLs, locker) {
        var vm = this;

        vm.token = locker.get('AUTH-TOKEN');

        $scope.eContractingBaseUri = App_URLs.eContractingBaseUri;
        vm.requestId = $stateParams.requestId;
        vm.offerId = $stateParams.offerId;

        vm.errorMessage = $rootScope.errorMessage;
        $rootScope.errorMessage = '';
        vm.alreadyClicked =false;

        vm.successMessage = $rootScope.successMessage;
        $rootScope.successMessage = '';

        var block = blockUI.instances.get('contracts_info');
        block.start();

        Contract.getOffer(vm.requestId, vm.offerId)
            .then(function (res) {
                vm.offer = res.data.offer;
            })
            .finally(function () {
                block.stop();
            });

        vm.Accept_Offer = function (contract_id, office_id, cvs) {
            officeRequest.Accept_Offer(contract_id, office_id, cvs)
                .then(function (data) {
                    init();
                });
        };

        vm.AcceptAndPay = function (contract_request_id, offer_id) {

            var ordered_cvs = $('#cvs_items li');
            if (ordered_cvs.length < vm.offer.cvs.length) {
                sweetAlert({
                    type: 'warning',
                    title: $translate.instant('CONTRACTS.REQUESTS.LABELS.CVS_TITLE'),
                    text: $translate.instant('CONTRACTS.REQUESTS.LABELS.SELECT_CVS')
                });

            }
            else {
                vm.alreadyClicked= true;
            }
            var cvs_array = [];

            ordered_cvs.each(function (index, li) {
                cvs_array.push([$(li).attr('id'), index + 1]);
            });

            officeRequest.Accept_Offer(contract_request_id, offer_id, cvs_array)
                .then(function (response) {
                    if (response.data.isSadad) {
                        $state.go('contracts.sadadPayment', {
                            contractId: response.data.offer.contract.id
                        });
                    } else {
                        $state.go('contracts.payment', {
                            contractId: response.data.offer.contract.id
                        });
                    }

                }, function (res) {
                    sweetAlert({
                        type: 'error',
                        title: res.data.error
                    })
                });
        };

        vm.showCV = function (pro_id, cv_id) {
            $('#cv_preview').attr('src', $scope.eContractingBaseUri + '/api/cv/' + cv_id + '/download?token=' + vm.token)
        };

        vm.hideCV = function (id) {
            setTimeout(function () {
                document.getElementById('div' + id).style.display = 'none';
            }, 1000)
        };


    };

    angular.module('musaned.app.contracts.contracts')
        .controller('ContractsShowCtrl', ContractsShowCtrl);
})();
