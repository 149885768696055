(function () {
  'use strict';

  var NoticeRequestShowCtrl = function ($stateParams, $modal, locker, blockUI, NoticeRequests, $ocLazyLoad, $rootScope) {
    var vm = this;

    $ocLazyLoad.load('assets/js/pdf.combined.js');

    vm.requestId = $stateParams.noticeId;

      NoticeRequests.get(vm.requestId)
      .then(function (res) {
        vm.request = res.data.noticeRequest;
        $rootScope.show_current_balance = res.data.show_current_balance;
        $rootScope.show_current_balance_and_refund_request = res.data.show_current_balance_and_refund_request;
      });

  };

  angular.module('musaned.app.notices')
    .controller('NoticeRequestShowCtrl', NoticeRequestShowCtrl);
})();
