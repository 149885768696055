(function () {
  'use strict';

  var Settings = function ($http) {
    var Settings = {};

    Settings.get = function (key) {
      return $http.get('/app/settings/'+key);
    };

    return Settings;
  };

  angular.module('musaned.app.visas')
    .service('Settings', Settings)
})();
