(function () {
  'use strict';

  var Registration = function ($http) {
    var Registration = {};

    Registration.checkInfo = function (data) {
      return $http.post('/app/user/register/check', data);
    };

    Registration.verifyCode = function (data) {
      return $http.post('/app/user/register/verify', data);
    };

    Registration.register = function (data) {
      return $http.post('/app/user/register/complete', data);
    };

    return Registration;
  };

  angular.module('musaned.app.registration')
    .service('Registration', Registration);
})();
