(function () {
  'use strict';

  var VisaLookups = function ($http) {
    var VisaLookups = {};

    VisaLookups.getOccupationsList = function (occupations) {
      return $http({
        url: '/api/occupations',
        method: "GET",
        params: occupations
      });
    };

    VisaLookups.getNationalitiesList = function (occupation) {

      return $http.get('/api/nationalities?occupation=' + occupation);
    };

    VisaLookups.getVisaIssuePlacesList = function (nationality) {
      return $http.get('/api/visa_issue_places?nationality=' + nationality);
    };

    VisaLookups.getOccupationsListForReplacment = function (visaID) {
      return $http.get('/app/visas/'+visaID+'/occupation');
    };

    return VisaLookups;
  };

  angular.module('musaned.app.visas.lookups')
    .service('VisaLookups', VisaLookups);
})();
