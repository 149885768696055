(function () {
    'use strict';

    var VisaMosaIssueRequestCreation = function ($http) {
        var VisaIssueRequestsCreation = {};

        VisaIssueRequestsCreation.create = function (model) {
            return $http.post('/app/visas/mosa_issue_requests', model);
        };

        VisaIssueRequestsCreation.checkPermission = function () {
            return $http.post('/app/visas/mosa_issue_requests/check_availability');
        };

        return VisaIssueRequestsCreation;
    };

    angular.module('musaned.app.visas.mosa.issueRequests')
        .service('VisaMosaIssueRequestCreation', VisaMosaIssueRequestCreation)
})();
