(function () {
    'use strict';

    var VisaMosaIssueRequestsListCtrl = function (VisaMosaIssueRequests, blockUI, $scope, $rootScope) {
        var vm = this;

        var block = blockUI.instances.get('requests_list');

        block.start();

        if ($rootScope.successMessage) {
            vm.successMessage = $rootScope.successMessage;
            $rootScope.successMessage = '';
        }

        VisaMosaIssueRequests.all()
            .then(function (res) {
                block.stop();
                vm.paginator = res.data.paginator;
                vm.currentPage = vm.paginator.current_page;
            });

        vm.pageChanged = function () {
            block.start();
            VisaMosaIssueRequests.all(vm.currentPage)
                .then(function (res) {
                    vm.paginator = res.data.paginator;
                    block.stop();
                });
        };
    };

    angular.module('musaned.app.visas.mosa.issueRequests')
        .controller('VisaMosaIssueRequestsListCtrl', VisaMosaIssueRequestsListCtrl);
})();
