(function () {
  'use strict';

  var NoticeRequests = function ($http) {
    var NoticeRequests = {};

    NoticeRequests.all = function (page) {
      if (!page) page = 1;
      return $http.get('/app/notices', {
        params: {page: page}
      });
    };

    NoticeRequests.get = function (requestId) {
      return $http.get('/app/notices/' + requestId+'/show');
    };

    NoticeRequests.bill = function (requestId) {
      return $http.get('/app/notices/'+ requestId+'/bill/show');
    };

    NoticeRequests.refund = function (data) {
      return $http.post('/app/notices/refund', data);
    };
    
    NoticeRequests.getBanks = function () {
      return $http.get('/app/billing/banks');
    };
    return NoticeRequests;
  };

  angular.module('musaned.app.notices')
    .service('NoticeRequests', NoticeRequests)
})();
