(function () {
  'use strict';

  angular.module('musaned.app').directive('mydatepicker', function() {
	  return {
	  	restrict: 'A',
	    require: 'ngModel',
	    link: function(scope, el, attr, ngModel) {
	        var calendar = $.calendars.instance('ummalqura');
	        var min_max = {};
	        (attr.min) ? min_max.minDate = attr.min : '';
	        (attr.max) ? min_max.maxDate = attr.max : '';
	        $(el).calendarsPicker(angular.extend(min_max, {
	        	calendar: calendar,
		        onSelect: function(dateText) {
		          scope.$apply(function() {
		            ngModel.$setViewValue(dateText.toString().replace(/-/g, "/"));
		          });
		          var date = dateText.toString().replace(/-/g, "/");
		          if(attr.to){
		          	$(attr.to).calendarsPicker('option', 'minDate', date || null);
		          }else if(attr.from){
		          	$(attr.from).calendarsPicker('option', 'maxDate', date || null);
		          }
		        }
	      	}));
	    }
	  };
	});

	angular.module('musaned.app').directive("limitTo", [function() {
		return {
			restrict: "A",
			link: function(scope, elem, attrs) {
				var limit = parseInt(attrs.limitTo);
				angular.element(elem).on("keypress", function(e) {
					if (this.value.length == limit) e.preventDefault();
				});
			}
		}
	}]);

   angular.module('musaned.app').directive('gorgiandatepicker', function() {
	  return {
	  	restrict: 'A',
	    require: 'ngModel',
	    link: function(scope, el, attr, ngModel) {
	        var calendar = $.calendars.instance('gregorian','ar-EG');
	        var min_max = {};
	        (attr.min) ? min_max.minDate = attr.min : '';
	        (attr.max) ? min_max.maxDate = attr.max : '';
	        $(el).calendarsPicker(angular.extend(min_max, {
	        	calendar: calendar,
		        onSelect: function(dateText) {
		          scope.$apply(function() {
		            ngModel.$setViewValue(dateText.toString().replace(/-/g, "/"));
		          });
		          var date = dateText.toString().replace(/-/g, "/");
		          if(attr.to){
		          	$(attr.to).calendarsPicker('option', 'minDate', date || null);
		          }else if(attr.from){
		          	$(attr.from).calendarsPicker('option', 'maxDate', date || null);
		          }
		        }
	      	}));
	    }
	  };
	});
  
})();