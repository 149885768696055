(function () {
    'use strict';

    var ReplacementRequestCreation = function ($http) {
        var ReplacementRequestCreation = {};

        ReplacementRequestCreation.create = function (requestId, model) {
            return $http.post('/app/contracts/requests/replacement/' + requestId, model);
        };

        return ReplacementRequestCreation;
    };

    angular.module('musaned.app.contracts.replacementRequests')
        .service('ReplacementRequestCreation', ReplacementRequestCreation)
})();
