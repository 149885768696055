(function () {
    'use strict';

    var AppointmentCreationCtrl = function (AppointmentRequests, blockUI, $scope, $rootScope) {
        var vm = this;

        var block = blockUI.instances.get('appointment');

        block.start();
        vm.office = null;
        vm.date = null;
        vm.takamol_config = '';

        vm.slots = [];
        vm.user_appointments = [];

        if ($rootScope.successMessage) {
            vm.successMessage = $rootScope.successMessage;
            $rootScope.successMessage = '';
        }

        AppointmentRequests.get()
            .then(function (res) {
                vm.offices = res['data']['offices'];
                vm.takamol_config = res['data']['takamol_config'];
                var date_start = res['data']['takamol_config']['date_start'];
                var date_end = res['data']['takamol_config']['date_end'];
                date_start = date_start.split("-");
                date_end = date_end.split("-");

                $('#date_picker').calendarsPicker('option', 'maxDate',$.calendars.instance('ummalqura').newDate(Number(date_end[0]),Number(date_end[1]),Number(date_end[2])));
                $('#date_picker').calendarsPicker('option','dateFormat', 'yyyy-mm-dd');
                $('#date_picker').calendarsPicker('option','altFormat', 'yyyy-mm-dd');
                $('#date_picker').calendarsPicker('option','altFormat', 'yyyy-mm-dd');

            }).finally(function () {
        });

        vm.getAvailability = function () {
            AppointmentRequests.getAvailability(vm.office.id, vm.date.replace(/\//g,'-'))
                .then(function (res) {

                    if (res['data']['error']){
                        swal("نظام المواعيد", res['data']['error'], "error");
                        window.location.href = '/#/appointments/create';
                    }

                    $rootScope.office = vm.office;
                    $rootScope.date = vm.date;
                    // Big shoutout to Mr.jake clarkson
                    var settings = {
                        startOfWeek: 0, //0 = Sunday, 1 = Monday
                        timeSlotGap: vm.takamol_config.time_slot,
                        minTime: vm.takamol_config.time_start,
                        maxTime: vm.takamol_config.time_end,
                        numSlots: 0
                    };

                    function getTimeDate(time) {
                        var timeParts = time.split(':');
                        var d = new Date();

                        d.setHours(timeParts[0]);
                        d.setMinutes(timeParts[1]);
                        d.setSeconds(timeParts[2]);

                        return d;
                    }

                    function getTimeSlots(startDate, endDate, interval) {
                        var slots = [];

                        var intervalMillis = interval * 60 * 1000;

                        var i = 0;

                        while (startDate < endDate) {
                            var available = true;
                            // So that you get "00" if we're on the hour.
                            var mins = (startDate.getMinutes() + '0').slice(0, 2);

                            var slot_time = ("0" + startDate.getHours()).substr(-2) + ':' + mins + ':00';

                            if(res['data']['appointments'].length > i){
                                if (res['data']['appointments'][i]['time'] == slot_time){
                                    available = (res['data']['appointments'][i]['count'] < res['data']['office']['capacity']);
                                    i++;
                                }
                            }

                            slots.push([startDate.getHours() + ':' + mins,available]);
                            startDate.setTime(startDate.getTime() + intervalMillis);
                        }

                        return slots;
                    }
                    $rootScope.slots = getTimeSlots(getTimeDate(settings.minTime), getTimeDate(settings.maxTime), settings.timeSlotGap);

                })


        };

        vm.book = function (time) {
            swal({
                    title: "نظام المواعيد",
                    text: "هل انت متأكد من اختيارك لهذا الوقت؟",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "نعم",
                    cancelButtonText: "لا",
                    closeOnConfirm: false
                },
                function(){
                    AppointmentRequests.book($rootScope.date.replace(/\//g,'-'), time, $rootScope.office.id)
                        .then(function (res) {
                            if (!res['data']['error']){
                                swal("نظام المواعيد", "تم الحجز بنجاح", "success");
                                window.location.href = '#/appointments/list/';
                            }
                            else{
                                swal("نظام المواعيد", res['data']['error'], "error");
                            }
                    });

                });
        };

        AppointmentRequests.getAppointments()
            .then(function (res) {
                vm.user_appointments = res['data'];


            }).finally(function () {
        });



        block.stop();
    };


    angular.module('musaned.app.appointments')
        .controller('AppointmentCreationCtrl', AppointmentCreationCtrl);

})();