(function () {
    'use strict';

    var ReplacementVisaIssueRequestCreation = function ($http) {
        var ReplacementVisaIssueRequestCreation = {};

        ReplacementVisaIssueRequestCreation.create = function (model) {
            return $http.post('/app/replaced_visas', model);
        };

        ReplacementVisaIssueRequestCreation.getEligibleWorkers = function () {
            return $http.get('/app/replaced_visas/eligibleWorkers');
        };

        ReplacementVisaIssueRequestCreation.getReplaceableVisaId = function (laborerId) {
            return $http.get('/app/replaced_visas/replaceableVisa/' + laborerId);
        };

        return ReplacementVisaIssueRequestCreation;
    };

    angular.module('musaned.app.replacedVisas')
        .service('ReplacementVisaIssueRequestCreation', ReplacementVisaIssueRequestCreation)
})();
