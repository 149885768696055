(function () {
  'use strict';

  var App_Run = function ($rootScope, $state, $translate, $templateCache, paginationConfig, formlyConfig, blockUIConfig, AuthEvents) {
    // Redirect to login page on authentication error
    $rootScope.$on('$stateChangeError', function (event, toState, toParams, formState, fromParams, error) {
      if (error === AuthEvents.AUTHENTICATION_FAILED) {
        $state.go('user.login');
      }
    });

    $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams){
      $rootScope.extra_class = toState.name === 'user.dashboard' ? 'dashboard' : '';
    });

    $rootScope.$on(AuthEvents.USER_INFO_UPDATED, function(event, userInfo) {
      zE(function() {
        zE.identify({
          name: userInfo['name'],
          email: userInfo['email']
        });
      });
    });

    $rootScope.$on(AuthEvents.LOGOUT_SUCCESS, function(event, userInfo) {
      zE(function() {
        zE.identify({});
      });
    });

    $rootScope.$on(AuthEvents.LOGOUT_SUCCESS, function () {
      $state.go('user.login');
    });

    // Refresh translator when new partial is loaded
    $rootScope.$on('$translateChangeSuccess', function (event, data) {
      document.documentElement.setAttribute('lang', data.language);
      $translate('COMMON.MESSAGES.LOADING').then(function (message) {
        blockUIConfig.message = message;
      });

      $translate('COMMON.ACTIONS.NEXT').then(function (next) {
        paginationConfig.nextText = next;
      });

      $translate('COMMON.ACTIONS.PREVIOUS').then(function (prev) {
        paginationConfig.previousText = prev;
      });
    });

    $rootScope.$on('KeepaliveResponse', function (res, data) {
        $rootScope.show_current_balance                    = data.show_current_balance;
        $rootScope.show_current_balance_and_refund_request = data.show_current_balance_and_refund_request;
        $rootScope.show_notice_balance                     = data.show_notice_balance;
        $rootScope.show_notice_balance_and_refund_request  = data.show_notice_balance_and_refund_request;
    });

    $templateCache.put('wizard.html',
      '<div>'+
        '<ul class="steps-indicator steps-{{steps.length}}">'+
          '<li ng-repeat="step in steps" ng-class="{default: !step.completed && !step.selected, current: step.selected && !step.completed, done: step.completed && !step.selected, editing: step.selected && step.completed}">'+
            '<a ng-click="goTo(step)" translate="{{ step.title || step.wzTitle }}"></a>'+
          '</li>'+
        '</ul>'+
        '<div class="steps" ng-transclude></div>'+
      '</div>'
    );

    formlyConfig.setWrapper({
      name: 'validation',
      types: ['input'],
      templateUrl: 'partials/common/formly/messages.html'
    });

    $translate('COMMON.MESSAGES.LOADING').then(function (message) {
      blockUIConfig.message = message;
    });

    $translate('COMMON.ACTIONS.NEXT').then(function (next) {
      paginationConfig.nextText = next;
    });

    $translate('COMMON.ACTIONS.PREVIOUS').then(function (prev) {
      paginationConfig.previousText = prev;
    });
  };

  angular.module('musaned.app')
    .run(App_Run);
})();
