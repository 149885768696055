(function () {
    'use strict';

    var VisaMosaIssueRequestShowCtrl = function ($stateParams, $modal, locker, blockUI, VisaIssueRequests, $ocLazyLoad) {
        var vm = this;

        $ocLazyLoad.load('assets/js/pdf.combined.js');

        vm.requestId = $stateParams.requestId;

        VisaIssueRequests.get(vm.requestId)
            .then(function (res) {
                vm.request = res.data.issueRequest;
            });

    };

    angular.module('musaned.app.visas.mosa.issueRequests')
        .controller('VisaMosaIssueRequestShowCtrl', VisaMosaIssueRequestShowCtrl);
})();
