(function () {
  'use strict';

  var ContractRequestSummaryCtrl = function ($scope, $modalInstance, $window, details, $translate, $timeout, $rootScope,WizardHandler, App_URLs) {
    var vm = this;
    var data = {};
      if(angular.element('#PREFERENCES').hasClass('active')) {
          if (details.age_range) {
              var ageRangeList = {
                  1: $translate.instant('CONTRACTS.REQUESTS.LABELS.AGE_30'),
                  2: $translate.instant('CONTRACTS.REQUESTS.LABELS.AGE_40'),
                  3: $translate.instant('CONTRACTS.REQUESTS.LABELS.AGE_50'),
                  4: $translate.instant('CONTRACTS.REQUESTS.LABELS.AGE_60'),
                  5: $translate.instant('CONTRACTS.REQUESTS.LABELS.AGE_65')
              };
              var age_range = details.age_range;
              data['age'] = ageRangeList[age_range];
          }


          if (details.work_experience) {
              var workExperienceList = {
                  5: $translate.instant('CONTRACTS.REQUESTS.LABELS.NONE'),
                  1: $translate.instant('CONTRACTS.REQUESTS.LABELS.WORKED_IN_KSA'),
                  2: $translate.instant('CONTRACTS.REQUESTS.LABELS.WORKED_IN_GOLF'),
                  3: $translate.instant('CONTRACTS.REQUESTS.LABELS.WORKED_BEFORE'),
                  4: $translate.instant('CONTRACTS.REQUESTS.LABELS.NEVER_WORKED')
              };
              var work_experience = details.work_experience;
              data['work_experience'] = workExperienceList[work_experience];
          }

          if (details.work_experience) {
              var religionList = {
                  1: $translate.instant('CONTRACTS.REQUESTS.LABELS.ISLAM'),
                  4: $translate.instant('CONTRACTS.REQUESTS.LABELS.OTHER'),
                  3: $translate.instant('CONTRACTS.REQUESTS.LABELS.NONE')
              };
              var religion = details.religion;
              data['religion'] = religionList[religion];
          }
      }
      else {
          if (details.worker_id) {
              data['worker_id'] = details.worker_id;
          }

          if (details.worker_number) {
              data['worker_number'] = details.worker_number;
          }
      }

      if(details.visa) {
          data['visa_no'] = details.summary.followup_number;
      }
      else {
          data['visa_no']=  $translate.instant('CONTRACTS.REQUESTS.LABELS.WAITING_FOR_VISA');
      }
      data['nationality']=details.summary.nationality.label;
      data['issue_place']=details.summary.visaIssuePlace.label;
      data['occupation']= details.summary.occupation.label;

    var offices = {};
    angular.forEach(details.pros, function (value, key) {

      angular.forEach(value.services, function (serviceValue, serviceKey) {
        if (details.office[serviceValue.id]) {
          offices[value.id] = {
            'name': value.name,
            'address': value.address.address,
            'logo': App_URLs.proBaseUri + '/api/offices/' + value.id + '/logo',
            'email': value.address.email,
            'mobile': value.address.mobile_number,
            'price': value.services[0].price + value.services[0].delivery_price,
            'recruitment_period': value.services[0].recruitment_period
          };
        }
      });
    });
    data['offices'] = offices;
    data['domestic_laborers'] = $rootScope.userInfo.personal_details.domestic_laborers;
    data['work_type'] = $rootScope.userInfo.personal_details.job;
    data['family_size'] = $rootScope.userInfo.personal_details.family_size;
    data['address'] = $rootScope.userInfo.personal_details.address;

    $scope.data = data;

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
    };

    $scope.triggerClickOrderContract = function () {
      $timeout(function () {
        $modalInstance.dismiss('cancel');
        WizardHandler.wizard($translate.instant('CONTRACTS.REQUESTS.LABELS.E_CONTRACT')).next();
      }, 0);
    };
  };

  angular.module('musaned.app.contracts.requests')
    .controller('ContractRequestSummaryCtrl', ContractRequestSummaryCtrl);
})();
