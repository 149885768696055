(function () {
  'use strict';

  var VisaIssueRequestRestoreBillCtrl = function ($stateParams, $translate, $state, $modal, locker, blockUI , VisaIssueRequests, $ocLazyLoad, $rootScope) {
    var vm = this;
    var block = blockUI.instances.get('restor_form');
    vm.restor = {};
    vm.refund = function () {
      block.start();
      VisaIssueRequests.refund(vm.restor)
        .then(function (res) {
          sweetAlert({
            type: 'success',
            title: 'تقديم طلب الاسترجاع',
            text: 'تم استقبال طلب الاسترجاع بنجاح.',
            timer: 10000
          });
          $rootScope.show_current_balance = res.data.show_current_balance;
          $rootScope.show_current_balance_and_refund_request = res.data.show_current_balance_and_refund_request;
          $state.go('visas.issue_requests.list');
        }, function (res) {
          vm.errorMessages = [];
          
          angular.forEach(res.data.errors, function (elem, messages) {
            angular.forEach(elem, function (message) {
              vm.errorMessages.push(message);
            });
          });
          if (res.data.error.code == 101) {
            vm.errorMessages.push($translate.instant('BILL.MESSAGES.CAN_NOT_REFUND'));
          }
          else if (res.data.error.code == 102) {
            vm.errorMessages.push($translate.instant('BILL.MESSAGES.INVALID_NIN'));
          }
          else if (res.data.error.code == 103) {
            vm.errorMessages.push($translate.instant('BILL.MESSAGES.MULTIPLE_REQUESTS_NOT_ALLOWED'));
          }
          else if (res.data.error.code == 104) {
            vm.errorMessages.push($translate.instant('BILL.MESSAGES.PAYMENT_NOT_CONCILED'));
          }
          else {
            vm.errorMessages.push('ERROR');
          }
        })
        .finally(function () {
          block.stop();
        });
    };
    VisaIssueRequests.getBanks().then(function (respone) {
      vm.banks = respone.data.banks;
    });
    

  };

  angular.module('musaned.app.visas.issueRequests')
    .controller('VisaIssueRequestRestoreBillCtrl', VisaIssueRequestRestoreBillCtrl);
})();
