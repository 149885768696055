(function () {
    'use strict';

    var TawtheeqContractViewCtrl = function ($anchorScroll, $location, $timeout, $rootScope, $translate, Upload, $ocLazyLoad, $stateParams, $scope, $state, $modal, blockUI, TawtheeqContracts, details, laborerId, $modalInstance, $window) {
        var vm = this;

        var block = blockUI.instances.get('contracts_tawtheeq');

        $scope.data = {};
        $scope.visa = details;
        $scope.laborerId = laborerId;
        $scope.showSend = true;

        if (laborerId != null && details == null) {

            $scope.showSend = false;
            TawtheeqContracts.show(laborerId)
                .then(function (res) {
                    $scope.data = res.data.contract;
                    $scope.data.worker = res.data.worker;
                    $scope.data.userName = res.data.nic_user_info.first_name + " " + res.data.nic_user_info.last_name;
                    $scope.data.userGender = res.data.nic_user_info.gender;
                    $scope.data.userId = res.data.user_info.id_number;
                    $scope.data.userMobile = res.data.user_info.mobile;
                    $scope.data.userEmail = res.data.user_info.email;
                    $scope.data.marital_status = res.data.nic_user_info.marital_status;
                    $scope.data.marital_status_en = res.data.nic_user_info.marital_status_en;
                    $scope.data.userNationality = res.data.nic_user_info.nationality;
                    $scope.worker_id_number = res.data.worker.Id;
                    $scope.data.wasel = res.data.wasel_info;
                    $scope.data.street_number_ar =  res.data.user_info.user_details.street_number;
                    $scope.data.route_ar = res.data.user_info.user_details.route;
                    $scope.data.locality_ar = res.data.user_info.user_details.locality;
                    $scope.data.sublocality_ar = res.data.user_info.user_details.sublocality;
                    $scope.data.postal_code_ar = res.data.user_info.user_details.postal_code;
                    $scope.data.postal_code_suffix_ar = res.data.user_info.user_details.postal_code_suffix;


                    var bank = _.filter(res.data.banks, function (bank) {
                        return bank.id == res.data.contract.bank_id
                    })[0];

                    $scope.data.bank_label = bank.label;
                    $scope.data.bank_label_en = bank.label_en;

                    if (res.data.contract.worker_relative) {
                        $scope.data.relative_name = res.data.contract.worker_relative.relative_name;
                        $scope.data.relative_type = res.data.contract.worker_relative.relative_type;
                        $scope.data.relative_phone = res.data.contract.worker_relative.relative_phone;
                        $scope.data.relative_address = res.data.contract.worker_relative.relative_address;
                    }

                  var geo = res.data.user_details.coordinates;
                  geo =  geo.split(',');
                  var geocodingAPI = "https://maps.googleapis.com/maps/api/geocode/json?latlng="+geo[0]+','+geo[1]+'&language=en';
                  $.getJSON(geocodingAPI, function (json) {
                    var address_components = json.results[0].address_components;
                    jQuery.each(address_components, function(k,v1) {
                      jQuery.each(v1.types, function(k2, v2){
                        $scope.data[v2]=v1.long_name;
                      });
                    });
                  });


                });

        }
        else {
            if (details != null) {
                $scope.showSend = true;
                $scope.data = details;

            }
        }

        $scope.toggle_details = function () {
            $scope.show_details = !$scope.show_details;
        };

        $scope.submit = function () {

            if (details.user)
                delete details.user;
            if (details.worker) {

                delete details.worker;
            }

            return TawtheeqContracts.store(laborerId, details)
                .then(function (res) {

                    if (res.status == 200) {
                        $modalInstance.dismiss('cancel');
                        $state.go('tawtheeqContracts.list');

                    }
                }, function (res) {
                    details = $scope.data;
                    var title = 'حدث خطأ اثناء التحقق من البيانات!', text = '';
                    if (res.data.errors) {
                        if (res.data.errors.worker_name) {
                            text += res.data.errors.worker_name;
                            text += '\n';
                        }
                        if (res.data.errors.worker_mobile) {
                            text += res.data.errors.worker_mobile;
                            text += '\n';
                        }
                        if (res.data.errors.salary) {
                            text += res.data.errors.salary;
                            text += '\n';
                        }
                        if (res.data.errors.employer_mobile) {
                            text += res.data.errors.employer_mobile;
                            text += '\n';
                        }
                        if (res.data.errors.location) {
                            text += res.data.errors.location;
                            text += '\n';
                        }
                        if (res.data.errors.relative_address) {
                            text += res.data.errors.relative_address;
                            text += '\n';
                        }
                        if (res.data.errors.relative_phone) {
                            text += res.data.errors.relative_phone;
                            text += '\n';
                        }
                        if (res.data.errors.relative_type) {
                            text += res.data.errors.relative_type;
                            text += '\n';
                        }
                        if (res.data.errors.relative_name) {
                            text += res.data.errors.relative_name;
                            text += '\n';
                        }
                    } else if (res.data.errors.code == 422) {
                        text = 'خطأ في المدخلات, تأكد من صحة البيانات المدخلة';
                    } else if (res.data.error.code == 106) {
                        text = 'حدث خطأ اثناء استرجاع معلومات العنوان الوطني, الرجاء المحاولة مرة أخرى.';
                    }
                    //      vm.errorMessage= text;

                    sweetAlert({
                        type: 'error',
                        title: title,
                        text: text
                    })
                })
                ;
        }
        $scope.print = function () {
            if (details != null) {
                $scope.submit();
            }
            $window.print();
        }
    };

    angular.module('musaned.app.eTawtheeq.tawtheeqContracts')
        .controller('TawtheeqContractViewCtrl', TawtheeqContractViewCtrl);
})();
