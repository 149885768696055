(function () {
  'use strict';

  var NavbarCtrl = function ($rootScope, Auth, AuthEvents, $scope, $window) {
    var vm = this;

    vm.user = false;

    $rootScope.$on(AuthEvents.LOGIN_SUCCESS, function () {
      vm.user = Auth.user();
    });

    $rootScope.$on(AuthEvents.USER_INFO_UPDATED, function () {
      vm.user = Auth.user();
    });

    $rootScope.$on(AuthEvents.LOGOUT_SUCCESS, function () {
      vm.user = false;
    });

    $rootScope.$on(AuthEvents.AUTHENTICATION_FAILED, function () {
      vm.user = false;
    });

    vm.isAuthenticated = function () {
      return vm.user !== false;
    };

    $scope.searchHeaderFormSubmit = function(){
      $window.location.href = 'https://musaned.com.sa/search'+(($scope.lang=='en')?'-en':'')+'.html?name=' + $scope.searchHeaderForm.text+'&per_page=20&page=1';
    }
  };

  angular.module('musaned.app.navbar')
    .controller('NavbarCtrl', NavbarCtrl);
})();
