(function () {
    'use strict';

    var ReplacedVisaCtrl = function ($anchorScroll, $translate, $scope, $location, $state, $rootScope, $timeout, $stateParams, $ocLazyLoad, blockUI, WizardHandler, Upload, officeRequest, VisaIssueRequests, Visas, ContractRequests, VisaIssueRequestCreationForm, ReplacementVisaIssueRequestCreation, VisaIssueRequestCreation) {
        var vm = this;

        $ocLazyLoad.load('/assets/js/pdf.combined.js');
        $ocLazyLoad.load('/assets/js/FileAPI.min.js');

        vm.fields = VisaIssueRequestCreationForm.fields;
        vm.checklist = VisaIssueRequestCreationForm.checklist;
        vm.can_request_new_visa = true;
        vm.show_bill = false;

        $rootScope.workerId = $stateParams.workerId;
        delete vm.fields.step1[0];
        vm.fields.step1[1] = {
            key: 'nationality',
            type: 'select',
            className: 'select-style',
            templateOptions: {
                label: "الجنسية",
                options: [],
                required: true,
                valueProp: 'id',
                labelProp: 'label',
                ngOptions: 'option.id as option.label for option in to.options'
            },
            controller: function ($scope, VisaLookups) {
                $scope.to.loading = VisaLookups.getNationalitiesList(1, $rootScope.user.id_number, $rootScope.user.check_date)
                    .then(function (res) {
                        $scope.to.options = res.data.nationalities;
                    });
            }
        };

        vm.model = {
            documents: {}
        };

        VisaIssueRequestCreation.checkPermission()
            .then(function () {
                vm.can_request_new_visa = true;
            }, function (res) {
                $location.hash('errorMessage');
                $anchorScroll();
                vm.can_request_new_visa = false;
                if (res.data.error.code == 120) {
                    vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.EXCEEDED_MAX_ALLOWED_REQUEST');
                } else if (res.data.error.code == 121) {
                    vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.GENDER_STATUS_ERROR');
                } else if (res.data.error.code == 111) {
                    vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.USER_HAS_TRAFFIC_VIOLATION');
                } else if (res.data.error.code == 122) {
                    vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.STOP_GOVERNMENT_SERVICES');
                } else if (res.data.error.code == 106) {
                    vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.DIED');
                } else if (res.data.error.code == 105) {
                    vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.ERROR__NOT_FOUND');
                } else if (res.data.error.code == 107) {
                    vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.CANNOT_OPEN_SPONSER_FILE');
                } else if (res.data.error.code == 109) {
                    vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.INVALID_BOD') + window.location.origin + '/#/user/register';
                } else if (res.data.error.code == 108) {
                    vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.DLBA_VISA_ISSUE');
                } else if (res.data.error.code == 511) {
                    vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.TIMEOUT');
                } else if (res.data.error.code >= 130 && res.data.error.code < 140) {
                    vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.' + res.data.error.code);
                } else {
                    vm.errorMessage = $translate.instant('USERS.MESSAGES.LOGIN.COULD_NOT_LOGIN');
                }
            });

        vm.requestId = $stateParams.requestId;
        vm.select_labor_disabled = false;
        ReplacementVisaIssueRequestCreation.getReplaceableVisaId($rootScope.workerId)
            .then(function (res) {
                vm.occupation = res.data.occupation;
                vm.visaId = res.data.visaId;
            },function (res) {
                vm.select_labor_disabled = true;
                $location.hash('errorMessage');
                vm.errorMessage=res.data.error.code;
            });

        vm.files = [];
        vm.uploads = [];
        vm.upload_disabled = false;
        $scope.$watch('vm.files', function (newFiles) {
            $scope.formUpload = false;
            if (newFiles != null && newFiles.length!=0) {
                upload(newFiles);
            }
        });

        var upload = function (file) {
            vm.upload_disabled = true;
            vm.errorMsg = [];
            file.upload = Upload.upload({
                url: '/app/user/uploads/upload' + vm.getReqParams(),
                method: 'POST',
                file: file,
                fileFormDataName: 'file'
            });

            file.upload
                .then(function (res) {
                    $timeout(function () {
                        file.result = res.data.userUpload;
                        vm.uploads.push(res.data.userUpload);
                        vm.upload_disabled = false;
                    });
                }, function (res) {
                    if (res.status == 422) {
                        vm.errorMsg.push('(' + file.name + ') ' + res.data.errors.file);
                    } else {
                        vm.errorMsg.push('(' + file.name + ') ' + $translate.instant(res.data.error.message));
                    }
                    vm.upload_disabled = false;
                });
            file.upload.progress(function (evt) {
                // Math.min is to fix IE which reports 200% sometimes
                file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
            });
        };

        vm.disableStep = function () {
            if (vm.uploads.length == 0 || vm.uploads.length > 10) {
                return true;
            }
            return false;
        };

        vm.getReqParams = function () {
            return vm.generateErrorOnServer ? '?errorCode=' + vm.serverErrorCode + '&errorMessage=' + vm.serverErrorMsg : '';
        };

        vm.removeFile = function (idx) {
            vm.uploads.splice(idx, 1);
        };

        $scope.validateStep3 = function () {
            if (!$scope.step3) {
                return false;
            }

            var validate = (
                $scope.step3.agreement != null
            );
            return validate;
        };

        $scope.step1 = {};
        $scope.step3 = {agreement: null};
        $scope.nextBtn = false;

        $scope.finishWizard = function () {
            var block = blockUI.instances.get('replacement_form');
            block.start();
            $scope.errorMessage = '';
            vm.uploads.map(function (upload) {
                vm.model.documents[upload.id] = 2;
            });
            vm.model.visa_no = vm.visaId;
            vm.model.occupation = vm.occupation.id;
            vm.model.worker_id = $rootScope.workerId;
            return ReplacementVisaIssueRequestCreation.create(vm.model)
                .then(function (res) {

                    vm.can_request_new_visa = false;
                    vm.show_bill = true;

                    vm.bill_number = res.data.bill_number;

                    if ($scope.lang == 'ar') {
                        moment.locale('ar-sa');
                    } else {
                        moment.locale('en-us');
                    }
                    vm.bill_expiry_date = moment(res.data.bill_expiry_date).format('LLLL'); // الأربعاء ٥ أبريل ٢٠١٧ ١٣:٥٦
                    vm.bill_issueed_date = moment(res.data.request.bill.created_at).format('LLLL'); // الأربعاء ٥ أبريل ٢٠١٧ ١٣:٥٦
                    vm.is_vat_enabled = res.data.is_vat_enabled;

                    vm.request = res.data.request;
                    vm.user = res.data.user;
                    vm.counts = res.data.counts;
                    $rootScope.show_current_balance = res.data.show_current_balance;
                    vm.show_current_balance_and_refund_request = res.data.show_current_balance_and_refund_request;
                    $rootScope.show_current_balance_and_refund_request = res.data.show_current_balance_and_refund_request;

                }, function (res) {
                    $location.hash('errorMessage');
                    $anchorScroll();
                    if (res.data.error.code == 104) {
                        vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.USER_NOT_ALLOWED');
                    } else if (res.data.error.code == 102) {
                        vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.MISSING_FILES');
                    } else if (res.data.error.code == 103) {
                        vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.PENDING_REQUEST');
                    } else {
                        vm.errorMessage = $translate.instant(res.data.error.message);
                    }
                })
                .finally(function () {
                    block.stop();
                });
        };

        $scope.next = function () {
            $scope.nextBtn = true;
        };

    };

    angular.module('musaned.app.replacedVisas')
        .controller('ReplacedVisaCtrl', ReplacedVisaCtrl);
})();
