(function () {
    'use strict';

    var PaymentCtrl = function ($scope, $state, $translate, $http, $rootScope, $location, $timeout, $stateParams, blockUI, WizardHandler, Upload, Visas, ContractRequests, $ocLazyLoad, Contract) {
        var vm = this;
        vm.contractId = $stateParams.contractId;
        vm.paymentStatus = $stateParams.status;

        var block = blockUI.instances.get('payment_form');
        block.start();

        vm.loader = false;
        vm.payFortForm = true;
        vm.paid = false;
        vm.diabledContinue = true;

        // Payment options status
        vm.option = '';
        vm.cc_merchantpage2 = false;
        vm.sadad = false;
        vm.iban = false;
        vm.sadad_bill = false;
        vm.v_iban = '';
        vm.iban_payment_option_message = '';
        vm.mada = false;
        vm.visa = false;
        vm.mastercard = false;
        vm.sadad_bill_info = '';

        // Set year select
        vm.currentYear = new Date().getFullYear();
        vm.years = [];
        for (var i = vm.currentYear; i < (vm.currentYear + 11); i++) {
            vm.years.push({value: i.toString().substr(i.toString().length - 2), text: i});
        }

        vm.errorMessage = '';

        Contract.get(vm.contractId)
            .then(function (res) {
                vm.status = true;
                vm.contract = res.data.contract;
                vm.contract.offer = res.data.contract.offer;

                if (vm.contract.new_status == 'PENDING') {
                    vm.status = false;
                } else if (vm.contract.new_status == 'VALID') {
                    vm.paid = true;
                }

                Visas.get(vm.contract.visa_id)
                    .then(function (res) {
                        vm.contract.visa = res.data.visa;
                    });

                /*if (vm.contract.pro.payment_channel.length > 0) {
                  angular.forEach(vm.contract.pro.payment_channel, function (PaymentChannel) {
                    vm.payment_methods.push(PaymentChannel.payment_channel);
                  });
                }*/

                vm.payments = res.data.contract.payments;
                vm.lookupBanks = res.data.banks;
                vm.officeAccounts = res.data.officeAccounts;
                vm.amount = vm.contract.offer.price + vm.contract.offer.delivery_price;
                vm.vat =  vm.amount * 0.05;
                vm.total_amount_including_vat = vm.amount + vm.vat;

                vm.disableForm = false;

                vm.loader = true;

                $scope.selectPaymentOption('iban');

                //if (vm.payment_methods.length > 0) {
                if (vm.cc_merchantpage2 == true || vm.sadad == true || vm.iban == true || vm.sadad_bill==true) {
                    vm.diabledContinue = false;
                } else {
                    vm.errorMessage = $translate.instant("CONTRACTS.REQUESTS.PHRASES.NO_ACTIVE_PAYMENT_OPTION");
                }

            }, function () {
            })
            .finally(function () {
                block.stop();
            });

        $scope.selectPaymentOption = function (option) {

            Contract.setPaymentChannel(vm.contractId, option)
                .then(function (res) {
                    vm.v_iban = res.data.contract.bill.v_iban;
                    vm.sadad_bill_info =$translate.instant('CONTRACTS.REQUESTS.LABELS.PAY_BY_SADAD', { bill:res.data.contract.bill.sadad_bill_number, code: $translate.instant('VISAS.ISSUE_REQUESTS.PHRASES.SADAD_BIN')});
                    vm.iban_payment_option_message = $translate.instant('CONTRACTS.REQUESTS.LABELS.IBAN_PAYMENT_OPTION', {
                        iban: res.data.contract.bill.v_iban,
                        price: vm.amount + vm.vat,
                    });

                });

            vm.cc_merchantpage2 = false;
            vm.sadad = false;
            vm.iban = false;
            vm.sadad_bill=false;
            if (option == 'cc') {
                vm.cc_merchantpage2 = true;
            } else if (option == 'sadad-olp') {
                vm.sadad = true;
            } else if(option == 'iban'){
                vm.iban = true;
            } else {
                vm.sadad_bill=true;
            }

            vm.option = option;
        }

        $scope.selectCardOption = function (card_option) {

            vm.mada = false;
            vm.visa = false;
            vm.mastercard = false;
            vm.sadad_bill=false;

            if (card_option == 'mada') {
                vm.mada = true;
            } else if (card_option == 'visa') {
                vm.visa = true;
            } else {
                vm.mastercard = true;
            }
        }

        vm.handleContinueClick = function () {
            if (vm.option == 'cc') {
                if (payfortFortMerchantPage2.validateCcForm(vm)) {
                    vm.getPaymentPage();
                }
            } else if (vm.option == 'sadad-olp') {
                vm.getPaymentPage();
            } else {
                vm.errorMessage = $translate.instant("CONTRACTS.REQUESTS.PHRASES.NO_PAYMENT_METHOD_SELECTED");
            }
        }

        vm.getPaymentPage = function () {

            if (!vm.diabledContinue) {

                block.start();
                Contract.getContractPaymentPage(vm.contractId, vm.option).then(function (r) {
                        var response = r.data;

                        if (response.form) {
                            $('.apphere').append(response.form);

                            if (response.paymentMethod == 'cc_merchantpage') {
                                vm.showMerchantPage(response.url);
                            } else if (response.paymentMethod == 'cc_merchantpage2') {
                                var expDate = $('#payfort_fort_mp2_expiry_year').val() + '' + $('#payfort_fort_mp2_expiry_month').val();
                                var mp2_params = {};
                                mp2_params.card_holder_name = $('#payfort_fort_mp2_card_holder_name').val();
                                mp2_params.card_number = $('#payfort_fort_mp2_card_number').val();
                                mp2_params.expiry_date = expDate;
                                mp2_params.card_security_code = $('#payfort_fort_mp2_cvv').val();
                                $.each(mp2_params, function (k, v) {
                                    $('<input>').attr({
                                        type: 'hidden',
                                        id: k,
                                        name: k,
                                        value: v
                                    }).appendTo('#payfort_payment_form');
                                });
                                $('#payfort_payment_form input[type=submit]').click();
                            } else {
                                $('#payfort_payment_form input[type=submit]').click();
                            }
                        }
                    }, function (response) {
                        // called asynchronously if an error occurs
                        // or server returns response with an error status.
                    }
                );
            }
        }

        // Payfort functions
        vm.showMerchantPage = function (merchantPageUrl) {
            if ($("#payfort_merchant_page").size()) {
                $("#payfort_merchant_page").remove();
            }
            $('<iframe name="payfort_merchant_page" id="payfort_merchant_page" height="430px" width="100%" frameborder="0" scrolling="no"></iframe>').appendTo('#pf_iframe_content');
            $("#payfort_merchant_page").attr("src", merchantPageUrl);
            $("#payfort_payment_form").attr("action", merchantPageUrl);
            $("#payfort_payment_form").attr("target", "payfort_merchant_page");
            $("#payfort_payment_form").attr("method", "POST");
            $('#payfort_payment_form input[type=submit]').click();
            //$("#payfort_payment_form").submit();
            $("#div-pf-iframe").show();
        }

        var getUrlParameter = function getUrlParameter(sParam) {
            var sPageURL = decodeURIComponent(window.location.search.substring(1)),
                sURLVariables = sPageURL.split('&'),
                sParameterName,
                i;

            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');

                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? true : sParameterName[1];
                }
            }
        };


        var payfortFort = (function () {
            return {
                validateCreditCard: function (element) {
                    var isValid = false;
                    var eleVal = $(element).val();
                    eleVal = this.trimString(element.val());
                    eleVal = eleVal.replace(/\s+/g, '');
                    $(element).val(eleVal);
                    $(element).validateCreditCard(function (result) {
                        /*$('.log').html('Card type: ' + (result.card_type == null ? '-' : result.card_type.name)
                         + '<br>Valid: ' + result.valid
                         + '<br>Length valid: ' + result.length_valid
                         + '<br>Luhn valid: ' + result.luhn_valid);*/
                        isValid = result.valid;
                    });
                    return isValid;
                },
                validateCardHolderName: function (element) {
                    $(element).val(this.trimString(element.val()));
                    var cardHolderName = $(element).val();
                    if (cardHolderName.length > 50) {
                        return false;
                    }
                    return true;
                },
                validateCvc: function (element) {
                    $(element).val(this.trimString(element.val()));
                    var cvc = $(element).val();
                    if (cvc.length > 4 || cvc.length == 0) {
                        return false;
                    }
                    if (!this.isPosInteger(cvc)) {
                        return false;
                    }
                    return true;
                },
                isDefined: function (variable) {
                    if (typeof (variable) === 'undefined' || typeof (variable) === null) {
                        return false;
                    }
                    return true;
                },
                trimString: function (str) {
                    return str.trim();
                },
                isPosInteger: function (data) {
                    var objRegExp = /(^\d*$)/;
                    return objRegExp.test(data);
                }
            };
        })();

        var payfortFortMerchantPage2 = (function () {
            return {
                validateCcForm: function (vm) {
                    this.hideError();
                    var isValid = payfortFort.validateCardHolderName($('#payfort_fort_mp2_card_holder_name'));
                    if (!isValid) {
                        this.showError($translate.instant("CONTRACTS.REQUESTS.PHRASES.INVALID_CARD_HOLDER_NAME"), vm);
                        return false;
                    }
                    isValid = payfortFort.validateCreditCard($('#payfort_fort_mp2_card_number'));
                    if (!isValid) {
                        this.showError($translate.instant("CONTRACTS.REQUESTS.PHRASES.INVALID_CREDIT_CARD_NUMBER"), vm);
                        return false;
                    }
                    isValid = payfortFort.validateCvc($('#payfort_fort_mp2_cvv'));
                    if (!isValid) {
                        this.showError($translate.instant("CONTRACTS.REQUESTS.PHRASES.INVALID_CVV"), vm);
                        return false;
                    }
                    return true;
                },
                showError: function (msg, vm) {
                    vm.errorMessage = msg;
                },
                hideError: function () {
                    return;
                }
            };
        })();

    };

    (function () {
        var $,
            __indexOf = [].indexOf || function (item) {
                for (var i = 0, l = this.length; i < l; i++) {
                    if (i in this && this[i] === item) return i;
                }
                return -1;
            };

        $ = jQuery;

        $.fn.validateCreditCard = function (callback, options) {
            var bind, card, card_type, card_types, get_card_type, is_valid_length, is_valid_luhn, normalize, validate,
                validate_number, _i, _len, _ref;
            card_types = [
                {
                    name: 'amex',
                    pattern: /^3[47]/,
                    valid_length: [15]
                }, {
                    name: 'diners_club_carte_blanche',
                    pattern: /^30[0-5]/,
                    valid_length: [14]
                }, {
                    name: 'diners_club_international',
                    pattern: /^36/,
                    valid_length: [14]
                }, {
                    name: 'jcb',
                    pattern: /^35(2[89]|[3-8][0-9])/,
                    valid_length: [16]
                }, {
                    name: 'laser',
                    pattern: /^(6304|670[69]|6771)/,
                    valid_length: [16, 17, 18, 19]
                }, {
                    name: 'visa_electron',
                    pattern: /^(4026|417500|4508|4844|491(3|7))/,
                    valid_length: [16]
                }, {
                    name: 'visa',
                    pattern: /^4/,
                    valid_length: [16]
                }, {
                    name: 'mastercard',
                    pattern: /^5[1-5]/,
                    valid_length: [16]
                }, {
                    name: 'maestro',
                    pattern: /^(5018|5020|5038|6304|6759|676[1-3])/,
                    valid_length: [12, 13, 14, 15, 16, 17, 18, 19]
                }, {
                    name: 'discover',
                    pattern: /^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)/,
                    valid_length: [16]
                }
            ];
            bind = false;
            if (callback) {
                if (typeof callback === 'object') {
                    options = callback;
                    bind = false;
                    callback = null;
                } else if (typeof callback === 'function') {
                    bind = true;
                }
            }
            if (options == null) {
                options = {};
            }
            if (options.accept == null) {
                options.accept = (function () {
                    var _i, _len, _results;
                    _results = [];
                    for (_i = 0, _len = card_types.length; _i < _len; _i++) {
                        card = card_types[_i];
                        _results.push(card.name);
                    }
                    return _results;
                })();
            }
            _ref = options.accept;
            for (_i = 0, _len = _ref.length; _i < _len; _i++) {
                card_type = _ref[_i];
                if (__indexOf.call((function () {
                        var _j, _len1, _results;
                        _results = [];
                        for (_j = 0, _len1 = card_types.length; _j < _len1; _j++) {
                            card = card_types[_j];
                            _results.push(card.name);
                        }
                        return _results;
                    })(), card_type) < 0) {
                    throw "Credit card type '" + card_type + "' is not supported";
                }
            }
            get_card_type = function (number) {
                var _j, _len1, _ref1;
                _ref1 = (function () {
                    var _k, _len1, _ref1, _results;
                    _results = [];
                    for (_k = 0, _len1 = card_types.length; _k < _len1; _k++) {
                        card = card_types[_k];
                        if (_ref1 = card.name, __indexOf.call(options.accept, _ref1) >= 0) {
                            _results.push(card);
                        }
                    }
                    return _results;
                })();
                for (_j = 0, _len1 = _ref1.length; _j < _len1; _j++) {
                    card_type = _ref1[_j];
                    if (number.match(card_type.pattern)) {
                        return card_type;
                    }
                }
                return null;
            };
            is_valid_luhn = function (number) {
                var digit, n, sum, _j, _len1, _ref1;
                sum = 0;
                _ref1 = number.split('').reverse();
                for (n = _j = 0, _len1 = _ref1.length; _j < _len1; n = ++_j) {
                    digit = _ref1[n];
                    digit = +digit;
                    if (n % 2) {
                        digit *= 2;
                        if (digit < 10) {
                            sum += digit;
                        } else {
                            sum += digit - 9;
                        }
                    } else {
                        sum += digit;
                    }
                }
                return sum % 10 === 0;
            };
            is_valid_length = function (number, card_type) {
                var _ref1;
                return _ref1 = number.length, __indexOf.call(card_type.valid_length, _ref1) >= 0;
            };
            validate_number = (function (_this) {
                return function (number) {
                    var length_valid, luhn_valid;
                    card_type = get_card_type(number);
                    luhn_valid = false;
                    length_valid = false;
                    if (card_type != null) {
                        luhn_valid = is_valid_luhn(number);
                        length_valid = is_valid_length(number, card_type);
                    }
                    return {
                        card_type: card_type,
                        valid: luhn_valid && length_valid,
                        luhn_valid: luhn_valid,
                        length_valid: length_valid
                    };
                };
            })(this);
            validate = (function (_this) {
                return function () {
                    var number;
                    number = normalize($(_this).val());
                    return validate_number(number);
                };
            })(this);
            normalize = function (number) {
                return number.replace(/[ -]/g, '');
            };
            if (!bind) {
                return validate();
            }
            this.on('input.jccv', (function (_this) {
                return function () {
                    $(_this).off('keyup.jccv');
                    return callback.call(_this, validate());
                };
            })(this));
            this.on('keyup.jccv', (function (_this) {
                return function () {
                    return callback.call(_this, validate());
                };
            })(this));
            callback.call(this, validate());
            return this;
        };

    }).call(this);

    angular.module('musaned.app.contracts.requests')
        .controller('PaymentCtrl', PaymentCtrl);
})();