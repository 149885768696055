(function () {
  'use strict';

  var PasswordReset = function ($http) {
    var PasswordReset = {};

    PasswordReset.request = function (data) {
      return $http.post('app/password/request', data);
    };

    PasswordReset.reset = function (data) {
      return $http.post('app/password/reset', data);
    };

    return PasswordReset;
  };

  angular.module('musaned.app.password')
    .service('PasswordReset', PasswordReset);
})();
