(function () {
  'use strict';

  var PasswordRequestForm = function ($translate) {
    var PasswordRequestForm = {};

    PasswordRequestForm.fields = [
      {
        key: 'id_number',
        type: 'input',
        templateOptions: {
          label: $translate.instant('USERS.LABELS.ID_NUMBER'),
          placeholder: $translate.instant('USERS.LABELS.ID_NUMBER')
        },
        expressionProperties: {
          'templateOptions.label': '"USERS.LABELS.ID_NUMBER" | translate',
          'templateOptions.placeholder': '"USERS.LABELS.ID_NUMBER" | translate'
        }
      },
      {
        key: 'email',
        type: 'input',
        templateOptions: {
          type: 'email',
          label: $translate.instant('USERS.LABELS.EMAIL'),
          placeholder: $translate.instant('USERS.LABELS.EMAIL')
        },
        expressionProperties: {
          'templateOptions.label': '"USERS.LABELS.EMAIL" | translate',
          'templateOptions.placeholder': '"USERS.LABELS.EMAIL" | translate'
        }
      },
      {
        key: 'mobile',
        type: 'input',
        templateOptions: {
          type: 'text',
          label: $translate.instant('USERS.LABELS.MOBILE'),
          placeholder: $translate.instant('USERS.LABELS.MOBILE')
        },
        expressionProperties: {
          'templateOptions.label': '"USERS.LABELS.MOBILE" | translate',
          'templateOptions.placeholder': '"USERS.LABELS.MOBILE" | translate'
        }
      }
    ];

    return PasswordRequestForm;
  };

  angular.module('musaned.app.password')
    .service('PasswordRequestForm', PasswordRequestForm);
})();
