(function () {
  'use strict';

  var PasswordResetCtrl = function ($state, $stateParams, $rootScope, $translate, blockUI, PasswordReset) {
    var vm = this;

    var block = blockUI.instances.get('password_reset');

    vm.data = {
      token: $stateParams.token,
      id_number: '',
      email: '',
      password: ''
    };

    vm.reset = function () {
      block.start();
      PasswordReset.reset(vm.data)
        .then(function () {
          $state.go('user.login')
        }, function (res) {
          if (res.data.error.message == 'USER.PASSWORD.TOKEN.EXPIRED') {
            vm.errorMessage = $translate.instant('USERS.MESSAGES.PASSWORD.TOKEN_EXPIRED');
          } else {
            vm.errorMessage = $translate.instant('COMMON.MESSAGES.GENERIC_ERROR');
          }
        })
        .finally(function () {
          block.stop();
        });
    };
  };

  angular.module('musaned.app.password')
    .controller('PasswordResetCtrl', PasswordResetCtrl);
})();
