(function () {
  'use strict';

  var App_Routes = function ($urlRouterProvider, stateHelperProvider) {
    stateHelperProvider
      .state({
        name: 'home',
        url: '/',
        resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
        views: {
          'content@': {
            controller: function ($scope, $location) {
              $scope.eContractLinks = $location.host() == 'visa.musaned.com.sa';
            },
            templateUrl: 'partials/app/home.html'
          }
        }
      })
      .state({
        name: 'apps',
        url: '/apps',
        views: {
          'content@': {
            templateUrl: 'partials/app/download.html'
          }
        }
      })
      .state({
        name: 'faq',
        url: '/faq',
        views: {
          'content@': {
            templateUrl: 'partials/app/faq.html'
          }
        }
      })
      .state({
        name: 'debug',
        url: '/debug/:eContract/:pro',
        views: {
          'content@': {
            controller: function ($state, $stateParams, locker) {
              var eContract = 'https://'+$stateParams.eContract;
              var pro = 'https://'+$stateParams.pro;

              if (eContract === locker.get('E-CONTRACTING-URL') && pro === locker.get('PRO-URL')) {
                $state.go('user.login');
              }

              locker.put('E-CONTRACTING-URL', eContract);
              locker.put('PRO-URL', pro);
              window.location.reload();
            }
          }
        }
      })
      .state({
        name: 'inquiry',
        url: '/inquiry',
        views: {
          'content@': {
            controller: 'InquiryCtrl',
            controllerAs: 'vm',
            templateUrl: 'partials/gio/inquiry.html'
          }
        }
      })
      .state({
        name: 'tracking',
        url: '/tracking/:token',
        views: {
          'content@': {
            controller: 'TrackingCtrl',
            controllerAs: 'vm',
            templateUrl: 'partials/gio/tracking.html'
          }
        }
      })
        .state({
        name: 'SriLanka',
        url: '/SriLanka',
        views: {
          'content@': {
            controller: 'ShowSirLankaCtrl',
            controllerAs: 'vm',
            templateUrl: 'partials/app/launching/SirLanka.html'
          }
        }
      })
      .state({
        abstract: true,
        name: 'user',
        url: '/user',
        views: {
          'content': {
            templateUrl: 'partials/app/main.html'
          }
        },
        children: [
          {
            name: 'login',
            url: '/login',
            views: {
              'content@': {
                controller: 'LoginCtrl',
                controllerAs: 'vm',
                templateUrl: 'partials/app/user/login.html'
              }
            }
          },
          {
            name: 'logout',
            url: '/logout',
            resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
            views: {
              'content@': {
                controller: 'LogoutCtrl',
                controllerAs: 'vm',
                templateUrl: 'partials/app/user/logout.html'
              }
            }
          },
          {
            name: 'appointment',
            url: '/appointment',
            resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
            views: {
              'content@': {
                controller: 'AppointmentCtrl',
                controllerAs: 'vm',
                templateUrl: 'partials/app/appointment.html'
              }
            }
          },
          {
            name: 'register',
            url: '/register',
            views: {
              'content@': {
                controller: 'RegistrationCtrl',
                controllerAs: 'vm',
                templateUrl: 'partials/app/user/register.html'
              }
            }
          },
          {
            name: 'password_request',
            url: '/password/request',
            views: {
              'content@': {
                controller: 'PasswordRequestCtrl',
                controllerAs: 'vm',
                templateUrl: 'partials/app/password/request.html'
              }
            }
          },
          {
            name: 'password_reset',
            url: '/password/reset/{token}',
            views: {
              'content@': {
                controller: 'PasswordResetCtrl',
                controllerAs: 'vm',
                templateUrl: 'partials/app/password/reset.html'
              }
            }
          },
          {
            name: 'dashboard',
            url: '/dashboard',
            resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
            views: {
              'stepcontent': {
                controller: 'DashboardCtrl',
                controllerAs: 'vm',
                templateUrl: 'partials/app/home.html'
              }
            }
          },
          {
            name: 'verify',
            url: '/verify',
            views: {
              'content@': {
                controller: 'VerifyCtrl',
                controllerAs: 'vm',
                templateUrl: 'partials/app/user/verify.html'
              }
            }
          },
          {
            name: 'details',
            url: '/details',
            resolve: {
                user: function (Auth) { return Auth.fetchUserInfo() },
                google: function ($ocLazyLoad) {
                    return $ocLazyLoad.load({name: 'google-maps', files: [{path: 'https://maps.google.com.sa/maps/api/js?language=ar&key=AIzaSyCYc_8CWJY2quaPCVYSevA5IhypC_jiRkY', type: 'js'}]});
                }
              },
            views: {
              'stepcontent': {
                controller: 'UserDetailsCtrl',
                controllerAs: 'vm',
                templateUrl: 'partials/app/user/details.html'
              }
            }
          }
        ]
      })
      .state({
        name: 'visas',
        abstract: true,
        url: '/visas',
        resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
        views: {
          'content': {
            templateUrl: 'partials/app/main.html'
          }
        },
        children: [
          {
            name: 'issue_requests',
            abstract: true,
            url: '/issue_requests',
            views: {
              'stepcontent': {
                template: '<div ui-view></div>'
              }
            },
            children: [
              {
                name: 'list',
                url: '/list',
                resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
                controller: 'VisaIssueRequestsListCtrl',
                controllerAs: 'vm',
                templateUrl: 'partials/app/visas/issue_requests/list.html'
              },
              {
                name: 'create',
                url: '/create',
                resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
                controller: 'VisasListCtrl',
                controllerAs: 'vm',
                templateUrl: 'partials/app/visas/issue_requests/issueRequest.html'
              },
              {
                name: 'new',
                url: '/new',
                resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
                controller: 'VisaIssueRequestCreationCtrl',
                controllerAs: 'vm',
                templateUrl: 'partials/app/visas/issue_requests/new.html'
              },
              {
                name: 'show',
                url: '/:requestId',
                resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
                controller: 'VisaIssueRequestShowCtrl',
                controllerAs: 'vm',
                templateUrl: 'partials/app/visas/issue_requests/show.html'
              } ,
              {
                name: 'bill',
                url: '/bill/:requestId',
                resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
                controller: 'VisaIssueRequestBillCtrl',
                controllerAs: 'vm',
                templateUrl: 'partials/app/visas/issue_requests/bill.html'
              } ,
              {
                name: 'restore_bill',
                url: '/restore/bill',
                resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
                controller: 'VisaIssueRequestRestoreBillCtrl',
                controllerAs: 'vm',
                templateUrl: 'partials/app/visas/issue_requests/restore_bill.html'
              } ,
            ]
          },
          {
            name: 'mosa_issue_requests',
            abstract: true,
            url: '/mosa_issue_requests',
            views: {
              'stepcontent': {
                template: '<div ui-view></div>'
              }
            } ,
            children: [
              {
                name: 'list',
                url: '/list',
                resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
                controller: 'VisaMosaIssueRequestsListCtrl',
                controllerAs: 'vm',
                templateUrl: 'partials/app/visas/mosa_issue_requests/list.html'
              },
              {
                name: 'create',
                url: '/create',
                resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
                controller: 'VisasMosaListCtrl',
                controllerAs: 'vm',
                templateUrl: 'partials/app/visas/mosa_issue_requests/issueRequest.html'
              },
              {
                name: 'new',
                url: '/new',
                resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
                controller: 'VisaMosaIssueRequestCreationCtrl',
                controllerAs: 'vm',
                templateUrl: 'partials/app/visas/mosa_issue_requests/new.html'
              },
              {
                name: 'show',
                url: '/:requestId',
                resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
                controller: 'VisaMosaIssueRequestShowCtrl',
                controllerAs: 'vm',
                templateUrl: 'partials/app/visas/mosa_issue_requests/show.html'
              }
            ]
          },
          {
            name: 'list',
            url: '/list',
            resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
            views: {
              'stepcontent': {
                controller: 'VisasListCtrl',
                controllerAs: 'vm',
                templateUrl: 'partials/app/visas/list.html'
              }
            }
          },
          {
            name: 'show',
            url: '/:visaId/show',
            resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
            views: {
              'stepcontent': {
            controller: 'VisaShowCtrl',
            controllerAs: 'vm',
            templateUrl: 'partials/app/visas/show.html'
              }
            }
          } ,
        ]
      })
      .state({
          name: 'replacedVisas',
          abstract: true,
          url: '/replacedVisas',
          resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
        views: {
          'content': {
            templateUrl: 'partials/app/main.html'
          }
        },
          children: [
            {
              name: 'replaceable',
              url: '/replaceable/',
              resolve: { user: function (Auth) { return Auth.fetchUserInfo() }},
              views: {
                'stepcontent': {
                  controller: 'ReplaceableCtrl',
                  controllerAs: 'vm',
                  templateUrl: 'partials/app/replacedVisas/replaceable.html'
                }
              }
            },
            {
              name: 'replacement',
              url: '/replacement/:workerId?',
              resolve: { user: function (Auth) { return Auth.fetchUserInfo() }},
              views: {
                'stepcontent': {
                  controller: 'ReplacedVisaCtrl',
                  controllerAs: 'vm',
                  templateUrl: 'partials/app/replacedVisas/replacement.html'
                }
              }
            },

          ]
        })
      .state({
        name: 'contracts',
        abstract: true,
        url: '/contracts',
        resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
        views: {
          'content': {
            templateUrl: 'partials/app/main.html'
          }
        },
        children: [
          {
            name: 'list',
            url: '/list',
            resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
            views: {
              'stepcontent': {
                controller: 'ContractsListCtrl',
                controllerAs: 'vm',
                templateUrl: 'partials/app/contracts/contracts/list.html'
              }
            }
          },
          {
            name: 'payment',
            url: '/:contractId/payment/:status',
            resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
            views: {
              'stepcontent': {
                controller: 'PaymentCtrl',
                controllerAs: 'vm',
                templateUrl: 'partials/app/contracts/contracts/payments.html'
              }
            }
          },
          {
            name: 'sadadPayment',
            url: '/:contractId/sadadPayment/:status',
            resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
            views: {
              'stepcontent': {
                controller: 'SadadPaymentCtrl',
                controllerAs: 'vm',
                templateUrl: 'partials/app/contracts/contracts/sadadPayment.html'
              }
            }
          },
          {
            name: 'requests',
            abstract: true,
            url: '/requests',
            resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
            views: {
              'stepcontent': {
                template: '<div ui-view></div>'
              }
            },
            children: [
              {
                name: 'list',
                url: '/',
                resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },

                    controller: 'ContractRequestsListCtrl',
                    controllerAs: 'vm',
                    templateUrl: 'partials/app/contracts/requests/list.html'

              },
              {
                name: 'new',
                url: '/new/:requestId?',
                resolve: {
                  user: function (Auth) { return Auth.fetchUserInfo() }
                },

                    controller: 'ContractRequestCreationCtrl',
                    controllerAs: 'vm',
                    templateUrl: 'partials/app/contracts/requests/new.html?123'

              },
              {
                name: 'proposal',
                url: '/show/:requestId/proposal',
                resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
                views: {
                  'content@': {
                    controller: 'ContractRequestShowCtrl',
                    controllerAs: 'vm',
                    templateUrl: 'partials/app/contracts/requests/proposal.html'
                  }
                }
              },
              {
                name: 'contract',
                url: '/show/:requestId/offer/:offerId',
                resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
                views: {
                  'content@': {
                    controller: 'ContractsShowCtrl',
                    controllerAs: 'vm',
                    templateUrl: 'partials/app/contracts/requests/contract.html'
                  }
                }
              },
              {
                name: 'details',
                url: '/show/:requestId/details',
                resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
                views: {
                  'content@': {
                    controller: 'ContractRequestShowCtrl',
                    controllerAs: 'vm',
                    templateUrl: 'partials/app/contracts/requests/details.html'
                  }
                }
              },
              {
                name: 'cancel',
                url: '/cancel/:contractId',
                resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
                views: {
                  'content@': {
                    controller: 'ContractRequestCancelCtrl',
                    controllerAs: 'vm',
                    templateUrl: 'partials/app/contracts/requests/cancel.html'
                  }
                }
              },
              {
                name: 'approveCancel',
                url: '/approveCancel/:contractId',
                resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
                views: {
                  'content@': {
                    controller: 'ContractRequestCancelApproveCtrl',
                    controllerAs: 'vm',
                    templateUrl: 'partials/app/contracts/requests/approveCancel.html'
                  }
                }
              },
              {
                name: 'paymentCompensation',
                url: '/paymentCompensation/:paymentId',
                resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
                views: {
                  'content@': {
                    controller: 'paymentCompensationCtrl',
                    controllerAs: 'vm',
                    templateUrl: 'partials/app/contracts/requests/paymentCompensation.html'
                  }
                }
              }
            ]
          }, {
            name: 'replacement',
            url: '/replacement/:requestId?',
            resolve: { user: function (Auth) { return Auth.fetchUserInfo() }},
            views: {
              'content@': {
                controller: 'ReplacementRequestCtrl',
                controllerAs: 'vm',
                templateUrl: 'partials/app/contracts/replacement/replacement.html'
              }
            }
          },
          {
            name: 'payment_confirmations',
            abstract: true,
            url: '/payment_confirmations',
            resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
            children: [
              {
                name: 'create',
                url: '/:contractId/create',
                resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
                views: {
                  'content@': {
                    controller: 'ContractPaymentConfirmationCreationCtrl',
                    controllerAs: 'vm',
                    templateUrl: 'partials/app/contracts/payment_confirmations/create.html'
                  }
                }
              }
            ]
          },
          {
            name: 'officeRequests',
            abstract: true,
            url: '/office/requests',
            resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
            views: {
              'stepcontent': {
                template: '<div ui-view></div>'
              }
            },
            children: [
              {
                name: 'list',
                url: '/:requestId/:type',
                params:{created:false},
                resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },

                    controller: 'officeRequestsListCtrl',
                    controllerAs: 'vm',
                    templateUrl: 'partials/app/contracts/officeRequests/list.html'

              }
            ]
          },

        ]
      })
      .state({
          name: 'tawtheeqContracts',
          abstract: true,
          url: '/tawtheeq_contracts',
          resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
          views: {
            'content': {
              templateUrl: 'partials/app/main.html'
            }
          },
          children: [
            {
              name: 'list',
              url: '/list',
              resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
              views: {
                'stepcontent': {
                  controller: 'TawtheeqContractShowCtrl',
                  controllerAs: 'vm',
                  templateUrl: 'partials/app/eTawtheeq/tawtheeqContracts/list.html'
                }
              }
            },
            {
              name: 'confirm',
              url: '/:laborerId',
              resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
              views: {
                'stepcontent': {
                  controller: 'TawtheeqContractConfirmCtrl',
                  controllerAs: 'vm',
                  templateUrl: 'partials/app/eTawtheeq/tawtheeqContracts/confirm.html'
                }
              }
            },
            {
              name: 'view',
              url: '/:laborerId/view',
              resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
              views: {
                'stepcontent': {
                  controller: 'TawtheeqContractViewCtrl',
                  controllerAs: 'vm',
                  templateUrl: 'partials/app/eTawtheeq/tawtheeqContracts/view.html'
                }
              }
            },


          ]
        })
        .state({
          name: 'gov',
          url: '/gov',
          views: {
            'content@': {
              templateUrl: 'partials/app/allowedGov.html'
            }
          }
        })
        .state({
          name: 'notices',
          abstract: true,
          url: '/notices',
          resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
          views: {
            'content': {
              templateUrl: 'partials/app/main.html'
            }
          },
          children: [
            {
              name: 'list',
              url: '/list',
              resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
              views: {
                'stepcontent': {
                  controller: 'NoticeRequestListCtrl',
                  controllerAs: 'vm',
                  templateUrl: 'partials/app/notices/list.html'
                }
              }
            },
            {
              name: 'create',
              url: '/create',
              resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
              views: {
                'stepcontent': {
                  controller: 'NoticeRequestCreationCtrl',
                  controllerAs: 'vm',
                  templateUrl: 'partials/app/notices/new.html?123'
                }
              }
            },
            {
              name: 'show',
              url: '/:noticeId/show',
              resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
              views: {
                'stepcontent': {
                  controller: 'NoticeRequestShowCtrl',
                  controllerAs: 'vm',
                  templateUrl: 'partials/app/notices/show.html'
                }
              }
            },
            {
              name: 'bill',
              url: '/bill/:noticeId',
              resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
              views: {
                'stepcontent': {
                  controller: 'NoticeRequestBillCtrl',
                  controllerAs: 'vm',
                  templateUrl: 'partials/app/notices/bill.html'
                }
              }
            } ,
            {
              name: 'restore_bill',
              url: '/restore/bill',
              resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
              views: {
                'stepcontent': {
                  controller: 'NoticeRequestRestoreBillCtrl',
                  controllerAs: 'vm',
                  templateUrl: 'partials/app/notices/restore_bill.html'
                }
              }
            } ,

          ]
        })
      .state({
          name: 'appointments',
          abstract: true,
          url: '/appointments',
          resolve: { user: function (Auth) { return Auth.fetchUserInfo() } },
          views: {
              'content': {
                  templateUrl: 'partials/app/main.html'
              }
          },
          children: [

              {
                  name: 'create',
                  url: '/create',
                  views: {
                      'stepcontent': {
                          controller: 'AppointmentCreationCtrl',
                          controllerAs: 'vm',
                          templateUrl: 'partials/app/appointments/create.html'
                      }
                  }
              },
              {
                  name: 'view',
                  url: '/view',
                  views: {
                      'stepcontent': {
                          controller: 'AppointmentCreationCtrl',
                          controllerAs: 'vm',
                          templateUrl: 'partials/app/appointments/book.html'
                      }
                  }
              },
              {
                  name: 'list',
                  url: '/list/:user_id',
                  views: {
                      'stepcontent': {
                          controller: 'AppointmentCreationCtrl',
                          controllerAs: 'vm',
                          templateUrl: 'partials/app/appointments/list.html'
                      }
                  }
              }

          ]
      })
       .state({
               name: 'portal',
               abstract: true,
               url: '/portal',
               views: {
                   'content': {
                       templateUrl: 'partials/app/main.html'
                   }
               },
               children: [
                   {
                       name: 'eForms',
                       url: '/eForms',
                       views: {
                           'content@': {
                               templateUrl: 'partials/app/portal/eForms.html'
                           }
                       }
                   },
                   {
                       name: 'rights',
                       url: '/rights',
                       views: {
                           'content@': {
                               templateUrl: 'partials/app/portal/rights.html'
                           }
                       }
                   },
                   {
                       name: 'complaints',
                       url: '/complaints',
                       views: {
                           'content@': {
                               templateUrl: 'partials/app/portal/complaints.html'
                           }
                       }
                   },
                   {
                       name: 'regulations',
                       url: '/regulations',
                       views: {
                           'content@': {
                               templateUrl: 'partials/app/portal/regulations.html'
                           }
                       }
                   },
                   {
                       name: 'news',
                       url: '/news',
                       views: {
                           'content@': {
                               templateUrl: 'partials/app/portal/news.html'
                           }
                       }
                   },
                   {
                       name: 'about',
                       url: '/about_musaned',
                       views: {
                           'content@': {
                               templateUrl: 'partials/app/portal/about_musaned.html'
                           }
                       }
                   }
                   ]
       })
    ;

    $urlRouterProvider.otherwise('/user/login');
  };

  angular.module('musaned.app')
    .config(App_Routes);
})();
