(function () {
  'use strict';

  var VisaIssueRequestCreationCtrl = function ($anchorScroll, $location, $scope, $timeout, $state, $modal, $rootScope, $translate, Upload, blockUI, VisaIssueRequestCreationForm, VisaIssueRequestCreation, Visas, $ocLazyLoad, WizardHandler, $filter) {
    var vm = this;

    $ocLazyLoad.load('/assets/js/pdf.combined.js');
    $ocLazyLoad.load('/assets/js/FileAPI.min.js');

    Visas.getSetting().then(function (respone) {
      vm.setting = respone.data;
    });

    var block = blockUI.instances.get('issue_form');

    vm.can_request_new_visa = true;
    vm.isFinanciallyCapable = false;

    vm.bill_msg = '';
    vm.billingConfig = false;
    vm.checkIssueRequest = function () {
      block.start();
      VisaIssueRequestCreation.checkPermission()
          .then(function (res) {
            vm.can_request_new_visa = true;
            $rootScope.occupations = res.data.occupationsList;
            vm.fields = VisaIssueRequestCreationForm.fields;
            vm.billingConfig = res.data.billingConfig;
            vm.isFinanciallyCapable = res.data.isFinanciallyCapable;

            vm.show_current_balance_and_refund_request = res.data.show_current_balance_and_refund_request;
            $rootScope.show_current_balance_and_refund_request = res.data.show_current_balance_and_refund_request;
            vm.addExtraFiles(res.data.extraFiles);
            vm.hasExtraFiles = (res.data.extraFiles.length > 0) ? true : false;
          }, function (res) {
            $location.hash('errorMessage');
            $anchorScroll();
            vm.can_request_new_visa = false;
            if (res.data.error.code == 120) {
              vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.EXCEEDED_MAX_ALLOWED_REQUEST');
            } else if (res.data.error.code == 121) {
              vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.GENDER_STATUS_ERROR');
            } else if (res.data.error.code == 111) {
              vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.USER_HAS_TRAFFIC_VIOLATION');
            } else if (res.data.error.code == 122) {
              vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.STOP_GOVERNMENT_SERVICES');
            } else if (res.data.error.code == 106) {
              vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.DIED');
            } else if (res.data.error.code == 105) {
              vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.ERROR__NOT_FOUND');
            } else if (res.data.error.code == 107) {
              vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.CANNOT_OPEN_SPONSER_FILE');
            } else if (res.data.error.code == 109) {
              vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.INVALID_BOD') + window.location.origin + '/#/user/register';
            } else if (res.data.error.code == 108) {
              vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.DLBA_VISA_ISSUE');
            } else if (res.data.error.code == 511) {
              vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.TIMEOUT');
            } else if (res.data.error.code >= 130 && res.data.error.code < 140) {
              vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.' + res.data.error.code);
            } else {
              vm.errorMessage = $translate.instant('USERS.MESSAGES.LOGIN.COULD_NOT_LOGIN');
            }
          })
          .finally(function () {
            block.stop();
          });
    };

    vm.pageChanged = function () {
      Visas.all(vm.currentPage)
          .then(function (res) {
            vm.paginator = res.data.paginator;
            vm.currentPage = vm.paginator.current_page;
          })
          .finally(function () {
            block.stop();
          });
    };

    vm.checkIssueRequest();
    vm.pageChanged();
    vm.checklist = VisaIssueRequestCreationForm.checklist;
    vm.model = {
      documents: {}
    };
    vm.files = [];

    $rootScope.$on('$translateChangeSuccess', function () {
      $filter('filter')(vm.uploads, {'id':1})[0].name = $translate.instant('VISAS.ISSUE_REQUESTS.PHRASES.PROOF_FINANCIAL_CAPACITY');
      $filter('filter')(vm.uploads, {'id':2})[0].name = $translate.instant('VISAS.ISSUE_REQUESTS.PHRASES.COPY_ID');
      $filter('filter')(vm.uploads, {'id':3})[0].name = $translate.instant('VISAS.ISSUE_REQUESTS.PHRASES.OTHER_SUPPORTING_DOCUMENT');
    });

    vm.uploads = [{
      'id': 1,
      'name': $translate.instant("VISAS.ISSUE_REQUESTS.PHRASES.PROOF_FINANCIAL_CAPACITY"),
      'files': [],
      'required': !vm.isFinanciallyCapable
    },
      {id: 2, 'name': $translate.instant("VISAS.ISSUE_REQUESTS.PHRASES.COPY_ID"), 'files': [], 'required': false},
      {
        id: 3,
        'name': $translate.instant("VISAS.ISSUE_REQUESTS.PHRASES.OTHER_SUPPORTING_DOCUMENT"),
        'files': [],
        'required': false
      }];
    vm.upload_disabled = false;

    vm.addExtraFiles = function (files) {
      var item = {};
      for (var i = 0; i < files.length; i++) {
        item = {
          'id': i + vm.uploads.length + 1,
          'name': $translate.instant('VISAS.ISSUE_REQUESTS.PHRASES.' + files[i]),
          'files': [],
          'required': true
        }
        vm.uploads.push(item);
      }
    }

    $scope.$watch('vm.files', function (newFiles) {
      $scope.formUpload = false;
      if (newFiles != null) {
        for (var i = 0; i < newFiles.length; i++) {
          $scope.errorMsg = null;
          (function (file) {
            vm.upload(file);
          })(newFiles[i]);
        }
      }
    });
    vm.uploadFile = function (files, type) {
      if (files && files.length) {
        for (var i = 0; i < files.length; i++) {
          vm.upload(files[i], type);
        }
      }
    };
    vm.upload = function (file, type) {
      vm.upload_disabled = true;
      vm.errorMsg = [];
      file.upload = Upload.upload({
        url: '/app/user/uploads/upload' + vm.getReqParams(),
        method: 'POST',
        file: file,
        fileFormDataName: 'file'
      });

      file.upload
          .then(function (res) {
            $timeout(function () {
              file.result = res.data.userUpload;
              if (res.data.userUpload !== undefined) {
                res.data.userUpload.type = type;
                vm.uploads[type - 1].files.push(res.data.userUpload);
              }
              if (res.data.errors !== undefined) {
                vm.errorMsg.push('(' + file.name + ') ' + res.data.errors.file);
              }
              vm.upload_disabled = false;
            });
          }, function (res) {
            if (res.status == 422) {
              vm.errorMsg.push('(' + file.name + ') ' + res.data.errors.file);
            } else {
              vm.errorMsg.push('(' + file.name + ') ' + $translate.instant(res.data.error.message));
            }
            vm.upload_disabled = false;
          });
      file.upload.progress(function (evt) {
        // Math.min is to fix IE which reports 200% sometimes
        file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
      });
    };

    vm.disableStep = function () {

      var financial_proof, extra_proof;
      financial_proof = extra_proof = false;
      for (var i = 0; i < vm.uploads.length; i++) {
        for (var j = 0; j < vm.uploads[i].files.length; j++) {

          if (vm.uploads[i].files[j].type == 1) {
            financial_proof = true;
          }
          if (vm.uploads[i].files[j].type >= 4) {
            extra_proof = true;

          }

        }
      }
      if (vm.hasExtraFiles) {
        return !(extra_proof && (vm.isFinanciallyCapable || financial_proof));
      }

      return !(vm.isFinanciallyCapable || financial_proof);
    };

    vm.getReqParams = function () {
      return vm.generateErrorOnServer ? '?errorCode=' + vm.serverErrorCode + '&errorMessage=' + vm.serverErrorMsg : '';
    };
    vm.removeFile = function (idx, type) {
      vm.uploads[type - 1].files.splice(idx, 1);
    };
    vm.finishWizard = function () {
      $state.go('visas.issue_requests.list');
    }
    // function assignment
    vm.createBill = function () {
      var block = blockUI.instances.get('issue_form');
      block.start();

      vm.errorMessage = '';

      vm.uploads.map(function (upload) {
        upload.files.map(function (uploadFile) {
          vm.model.documents[uploadFile.id] = uploadFile.type;
        });
      });

      return VisaIssueRequestCreation.create(vm.model)
          .then(function (res) {
            $rootScope.successMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.SUCCESS');
            for (var i = 0; i < vm.checklist.questions.length; i++) {
              vm.checklist.questions[i].selectedAnswer = null;
            }
            vm.bill_msg = $translate.instant('VISAS.ISSUE_REQUESTS.PHRASES.CONTINUO', {bill: res.data.bill_number});
            vm.bill_number = res.data.bill_number;

            if ($scope.lang == 'ar') {
              moment.locale('ar-sa');
            } else {
              moment.locale('en-us');
            }
            vm.bill_expiry_date = moment(res.data.bill_expiry_date).format('LLLL'); // الأربعاء ٥ أبريل ٢٠١٧ ١٣:٥٦
            vm.bill_issueed_date = moment(res.data.request.bill.created_at).format('LLLL'); // الأربعاء ٥ أبريل ٢٠١٧ ١٣:٥٦
            vm.is_vat_enabled = res.data.is_vat_enabled;
            vm.is_ups = res.data.is_ups;
            vm.checkout_url = res.data.checkout_url;

            vm.request = res.data.request;
            vm.user = res.data.user;
            vm.counts = res.data.counts;
            $rootScope.show_current_balance = res.data.show_current_balance;
            $rootScope.show_current_balance_and_refund_request = res.data.show_current_balance_and_refund_request;
            WizardHandler.wizard($translate.instant('VISAS.ISSUE_REQUESTS.PHRASES.E_VISA')).next();
          }, function (res) {
            $location.hash('errorMessage');
            $anchorScroll();
            if (res.data.error.code == 104) {
              vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.USER_NOT_ALLOWED');
            } else if (res.data.error.code == 102) {
              vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.MISSING_FILES');
            } else if (res.data.error.code == 103) {
              vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.PENDING_REQUEST');
            } else if (res.data.error.code == 107) {
              vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.WAITING_PAYMENT_REQUEST');
            } else if (res.data.error.code == 120) {
              vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.EXCEEDED_MAX_ALLOWED_REQUEST');
            } else if (res.data.error.code >= 130 && res.data.error.code < 140) {
              vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.' + res.data.error.code);
            } else {
              vm.errorMessage = $translate.instant('USERS.MESSAGES.LOGIN.COULD_NOT_LOGIN');
            }
          })
          .finally(function () {
            block.stop();
          });
    };

    vm.allQuestionsAnswered = function () {
      for (var i = 0; i < vm.checklist.questions.length; i++) {
        if (
            vm.checklist.questions[i].visible == true &&
            ( !vm.checklist.questions[i].selectedAnswer
            || (i == 0 && vm.checklist.questions[i].selectedAnswer == 2))
        ) {
          return true;
        }
      }
      return false;
    };

    vm.showQuestion = function (item, dependent) {
      if (item === 3 && vm.isFinanciallyCapable) {
        vm.checklist.questions[item].visible = false;
      }
      if (dependent) {
        if (vm.checklist.questions[dependent.index].selectedAnswer == dependent.value) {
          vm.checklist.questions[item].visible = true;
        } else {
          vm.checklist.questions[item].visible = false;
          vm.checklist.questions[item].selectedAnswer = null;
        }
      }
      return vm.checklist.questions[item].visible;
    };
    vm.hasFinancialCapacity = false;

    vm.checkFinancialCapacity = function () {
      vm.model.employmentStatus = vm.checklist.questions[2].selectedAnswer;

      if (vm.checklist.questions[1].selectedAnswer == 1 && vm.checklist.questions[3].selectedAnswer == 3) {
        block.start();
        VisaIssueRequestCreation.checkFinancialCapacity()
            .then(function (res) {
              if (res.data.gosiServiceValue) {

                vm.can_request_new_visa = true;
                vm.hasFinancialCapacity = true;
                WizardHandler.wizard($translate.instant('VISAS.ISSUE_REQUESTS.PHRASES.E_VISA')).next();
              } else {
                $location.hash('errorMessage');
                $anchorScroll();
                vm.can_request_new_visa = false;
                vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.ERROR__INVALID_GOSI');
              }

            }, function (res) {
              $location.hash('errorMessage');
              $anchorScroll();
              vm.can_request_new_visa = false;
              if (res.data.error.code == 120) {
                vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.EXCEEDED_MAX_ALLOWED_REQUEST');
              } else if (res.data.error.code == 121) {
                vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.GENDER_STATUS_ERROR');
              } else if (res.data.error.code == 111) {
                vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.USER_HAS_TRAFFIC_VIOLATION');
              } else if (res.data.error.code == 122) {
                vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.STOP_GOVERNMENT_SERVICES');
              } else if (res.data.error.code == 106) {
                vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.DIED');
              } else if (res.data.error.code == 105) {
                vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.ERROR__NOT_FOUND');
              } else if (res.data.error.code == 107) {
                vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.CANNOT_OPEN_SPONSER_FILE');
              } else if (res.data.error.code == 109) {
                vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.INVALID_BOD');
              } else if (res.data.error.code == 511) {
                vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.TIMEOUT');
              } else if (res.data.error.code == 107) {
                vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.ERROR__INVALID_GOSI');
              } else if (res.data.error.code >= 130 && res.data.error.code < 140) {
                vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.' + res.data.error.code);
              } else {
                vm.errorMessage = $translate.instant('USERS.MESSAGES.LOGIN.COULD_NOT_LOGIN');
              }
            })
            .finally(function () {
              block.stop();
            });

      } else {
        WizardHandler.wizard($translate.instant('VISAS.ISSUE_REQUESTS.PHRASES.E_VISA')).next();

      }
    };
  };

  angular.module('musaned.app.visas.issueRequests')
      .controller('VisaIssueRequestCreationCtrl', VisaIssueRequestCreationCtrl);
})();
