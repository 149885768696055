(function () {
  'use strict';

  var NoticeRequestBillCtrl = function ($stateParams, $modal, locker, blockUI, NoticeRequests, $ocLazyLoad) {
    var vm = this;

    vm.noticeId = $stateParams.noticeId;

      NoticeRequests.bill(vm.noticeId)
      .then(function (res) {
        vm.data = res.data;
      }, function (res) {
          if (res.data.error.code == 201) {
            vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.NOT_FOUND');
          } else {
            vm.errorMessage = $translate.instant(res.data.error.message);
          }
        });

  };

  angular.module('musaned.app.notices')
    .controller('NoticeRequestBillCtrl', NoticeRequestBillCtrl);
})();
