(function () {
  'use strict';

  var ReplaceableCtrl = function ($scope, $translate, blockUI, ReplacementVisaIssueRequestCreation) {
    var vm = this;
    vm.errorMessages = [];
    var block = blockUI.instances.get('eligible_workers');
    block.start();
    ReplacementVisaIssueRequestCreation.getEligibleWorkers()
      .then(function (res){
        vm.eligibleWorkers = res.data;
      })
      .finally(function () {
        block.stop();
    });
  };

  angular.module('musaned.app.user')
    .controller('ReplaceableCtrl', ReplaceableCtrl);
})();
