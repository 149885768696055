(function () {
  'use strict';

  var InvoicePrintCtrl = function ($scope, $modalInstance, $window, details) {
    var vm = this;

    $scope.contract = details;

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');

    };

    $scope.print = function () {
      $window.print();
    }
  };

  angular.module('musaned.app.contracts.contracts')
    .controller('InvoicePrintCtrl', InvoicePrintCtrl);
})();
