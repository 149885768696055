(function () {
  'use strict';

  var NoticeRequestListCtrl = function (NoticeRequests, blockUI, $scope, $rootScope) {
    var vm = this;

    var block = blockUI.instances.get('requests_list');

    block.start();

    if ($rootScope.successMessage) {
      vm.successMessage = $rootScope.successMessage;
      $rootScope.successMessage = '';
    }

      NoticeRequests.all()
      .then(function (res) {
        block.stop();
        vm.paginator = res.data.paginator;
        vm.currentPage = vm.paginator.current_page;
        vm.printURI = res.data.print_uri;
      });

    vm.pageChanged = function () {
      block.start();
        NoticeRequests.all(vm.currentPage)
        .then(function (res) {
          vm.paginator = res.data.paginator;
          vm.printURI = res.data.print_uri;
          block.stop();
        });
    };
  };

  angular.module('musaned.app.notices')
    .controller('NoticeRequestListCtrl', NoticeRequestListCtrl);
})();
