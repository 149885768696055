(function () {
  'use strict';

  var LoginCtrl = function ($rootScope, $state, $translate, blockUI, Auth, AuthEvents, $location) {
    var vm = this;

    var block = blockUI.instances.get('login');

    vm.data = {username: '', password: ''};

    vm.errorMessage = '';

    if ($location.search().status == "activated") {
      vm.alertMessage = $translate.instant('USERS.MESSAGES.LOGIN.ACTIVATED');
    }

    if ($location.search().rated) {
      vm.alertMessage = $translate.instant('USERS.MESSAGES.LOGIN.RATED');
    }

    if ($rootScope.login && $rootScope.login.required) {
      vm.errorMessage = $translate.instant('USERS.MESSAGES.LOGIN.REQUIRED');
    }

    vm.login = function () {
      block.start();

      vm.errorMessage = '';

      Auth.login(vm.data)
        .then(function () {
        }, function (res) {
          vm.data.password = '';

          if (res.data.error.code == 101) {
            vm.errorMessage = $translate.instant('USERS.MESSAGES.LOGIN.INVALID_CREDENTIALS');
          } else if (res.data.error.code == 104) {
            vm.errorMessage = $translate.instant('USERS.MESSAGES.LOGIN.ACTIVATE_YOUR_ACCOUNT');
          } else {
            vm.errorMessage = $translate.instant('USERS.MESSAGES.LOGIN.COULD_NOT_LOGIN');
          }
        })
        .finally(function () {
          block.stop();
        });
    };

  };

  angular.module('musaned.app.auth')
    .controller('LoginCtrl', LoginCtrl);
})();
