(function () {
  'use strict';
  window.location = "https://app.musaned.com.sa/#/signin";
  // mount on window for testing
  window.app = angular.module('musaned.app', [
    'ngSanitize',
    'ngCookies',
    'ngFileUpload',
    'ngAnimate',
    'ngIdle',
    'ui.router',
    'ui.router.stateHelper',
    'ui.select',
    'ui.bootstrap',
    'formly',
    'formlyBootstrap',
    'timer',
    'oc.lazyLoad',
    'angular-locker',
    'pascalprecht.translate',
    'mgo-angular-wizard',
    'blockUI',
    'angularMomentHijri',
    'angulartics',
    'angulartics.google.analytics',
    'musaned.common.locale',
    'musaned.common.validators',
    'musaned.common.mediaPreview',
    'musaned.common.forms',
    'musaned.common.print',
    'musaned.app.navbar',
    'musaned.app.user',
    'musaned.app.dashboard',
    'musaned.app.visas',
    'musaned.app.visas.mosa.issueRequests',
    'musaned.app.launching',
    'musaned.app.contracts',
    'musaned.app.contracts.contracts',
    'musaned.app.contracts.officeRequests',
    'musaned.app.replacedVisas',
    'musaned.app.contracts.replacementRequests',
    'musaned.app.sidebar',
    'musaned.app.eTawtheeq',
    'musaned.app.eTawtheeq.tawtheeqContracts',
    'musaned.app.notices',
    'musaned.app.appointments',
    'mm.iban'
  ]);

  angular.module('musaned.app')
    .service('App_URLs', function ($http, locker) {
      let eContractingBaseUri = locker.get('E-CONTRACTING-URL') || 'https://econtracting.musaned.com.sa';
      let proBaseUri = locker.get('PRO-URL') || 'https://pros.musaned.com.sa';

      return {
        eContractingBaseUri,
        proBaseUri
      };
    });

  angular.element(document).ready(function () {
    angular.bootstrap(document, ['musaned.app']);
  });
})();
