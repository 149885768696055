(function () {
  'use strict';

  var IdleCtrl = function ($rootScope, $scope, $state, $modal, $translate, Idle, Auth, AuthEvents) {
    $scope.countdown = Idle.getTimeout();

    $scope.$on('IdleStart', function() {
      sweetAlert({
        type: 'warning',
        title: $translate.instant('IDLE.WARNING.TITLE'),
        text: $translate.instant('IDLE.WARNING.MESSAGES.IDLE'),
        showConfirmButton: false
      });
    });

    $scope.$on('IdleEnd', function() {
      sweetAlert.close();
    });

    $scope.$on('IdleTimeout', function() {
      Auth.logout();
      $rootScope.$on(AuthEvents.LOGOUT_SUCCESS, function () {
        sweetAlert.close();
      });
    });
  };

  angular.module('musaned.app.auth')
    .controller('IdleCtrl', IdleCtrl);
})();
