(function () {
  'use strict';

  var App_Config = function ($httpProvider, $translateProvider, blockUIConfig, lockerProvider, KeepaliveProvider, IdleProvider) {
    $httpProvider.defaults.headers.common.Accept = 'application/json';
    $httpProvider.defaults.xsrfHeaderName = 'X-XSRF-TOKEN';
    $httpProvider.defaults.xsrfCookieName = 'XSRF-TOKEN';

    $httpProvider.interceptors.push(function ($injector, $rootScope, $q, locker, AuthEvents) {
      var requestTokenInterceptor = function (config) {
        var token = locker.get('AUTH-TOKEN');
        if (token) {
          config.headers['X-AUTH-TOKEN'] = token;
          config.headers['authorization'] = 'bearer ' + token;
        }
        return config;
      };
      var responseTokenInterceptor = function (response) {
        var token = response.headers()['x-auth-token'];
        if (token) locker.put('AUTH-TOKEN', token);
        return response;
      };
      var responseTokenErrorInterceptor = function (response) {
        if (response.status == 401) {
          $rootScope.$emit(AuthEvents.AUTHENTICATION_FAILED);
          locker.forget('AUTH-TOKEN');
          $injector.get('$state').go('user.login');
        }
        return $q.reject(response);
      };
      return {
        request: requestTokenInterceptor,
        response: responseTokenInterceptor,
        responseError: responseTokenErrorInterceptor
      };
    });

    $translateProvider.useStaticFilesLoader({
      prefix: '/lang/',
      suffix: '.json'
    });

    $translateProvider.preferredLanguage('ar');
    $translateProvider.fallbackLanguage(['en']);

    $translateProvider.useLocalStorage();
    $translateProvider.useSanitizeValueStrategy('escapeParameters');

    lockerProvider.defaults({
      namespace: 'musaned',
      separator: '.',
      eventsEnabled: true,
      extend: {}
    });

    blockUIConfig.autoInjectBodyBlock = false;
    blockUIConfig.delay = 50;

    IdleProvider.autoResume(true);
    IdleProvider.idle(10 * 60);
    IdleProvider.timeout(30);
    IdleProvider.keepalive(true);
    KeepaliveProvider.interval(60);
    KeepaliveProvider.http('/app/user/keepAlive');
    
  };

  angular.module('musaned.app')
    .config(App_Config);
})();
