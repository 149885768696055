(function () {
  'use strict';

  var ShowSirLankaCtrl = function ($scope) {
    var vm = this;

    vm.saudi_launched = false;
    vm.foreign_launched = false;

    vm.congratulations = false;

    vm.launch = function () {
      if(vm.saudi_launched && vm.foreign_launched){
        vm.congratulations = true;
      }
    };

    $scope.launch_foreign = function () {
      vm.foreign_launched = true;
      vm.launch();
    };

    $scope.launch_saudi = function () {
      vm.saudi_launched = true;
      vm.launch();
    };
  };

  angular.module('musaned.app.launching')
    .controller('ShowSirLankaCtrl', ShowSirLankaCtrl);
})();
