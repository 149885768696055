(function () {
  'use strict';
  var AuthEvents = function () {
    return {
      AUTHENTICATION_FAILED: 'musaned.auth.failed',
      LOGIN_SUCCESS: 'musaned.auth.login_success',
      USER_INFO_UPDATED: 'musaned.auth.user_info_updated',
      LOGOUT_SUCCESS: 'musaned.auth.logout_success'
    };
  };

  angular.module('musaned.app.auth')
    .service('AuthEvents', AuthEvents);
})();
