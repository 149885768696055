(function () {
    'use strict';

    var ContractRequests = function ($http, App_URLs) {
        var ContractRequests = {};

        ContractRequests.request = function (requestId) {
            return $http.get(App_URLs.eContractingBaseUri+'/app/contracts/requests/request/' + requestId);
        };

        ContractRequests.getSetting = function (setting) {
            return $http.get(App_URLs.eContractingBaseUri + '/api/system/settings/' + setting);
        };


        ContractRequests.get = function (requestId) {
            return $http.get(App_URLs.eContractingBaseUri + '/api/requests/' + requestId);
        };

        ContractRequests.accept = function (requestId, officeId) {
            return $http.put(App_URLs.eContractingBaseUri +'/api/requests/' + requestId + '/offers/' + officeId +  '/accept');
        };


        ContractRequests.offices = function () {
            return $http.get('app/contracts/requests/offices');
        };

        ContractRequests.Services = function (requestId) {
            return $http.get('app/contracts/requests/Services/' + requestId);
        };


        ContractRequests.reject = function (requestId, officeId) {
            return $http.put(App_URLs.eContractingBaseUri + '/api/requests/' + requestId + '/offers/' + officeId + '/reject');
        };

        ContractRequests.availableVisas = function () {
            return $http.get('app/visas/available');
        };

        ContractRequests.requestSearch = function (data) {
            return $http.get(App_URLs.eContractingBaseUri + '/api/requests', {
                params: data
            });
        };

        ContractRequests.contractingOptions = function () {
            return $http.get('app/contracts/options');
        };
        ContractRequests.lookups = function (visa) {
            return $http.get('app/contracts/lookups', {
                params: {visa: visa}
            });
        };

        ContractRequests.proSearch = function (data) {
            return $http.get(App_URLs.proBaseUri + '/api/offices', {
                params: data
            });
        };

        ContractRequests.proRatings = function (data) {
            return $http.get(App_URLs.eContractingBaseUri + '/api/offices/ratings', {
                params: data
            });
        };

        ContractRequests.cities = function (regionId) {
            return $http.get('app/contracts/cities', {
                params: {regionId: regionId}
            });
        };

        ContractRequests.searchCity = function (query_str) {
            return $http.get('api/cities', {
                params: {name: query_str}
            });
        };

        ContractRequests.city = function (cityId) {
            return $http.get('api/city', {
                params: {city_id: cityId}
            });
        };

        ContractRequests.occupations = function () {
            return $http.get('api/occupations');
        };

        ContractRequests.nationalities = function () {
            return $http.get('api/nationalities');
        };

        ContractRequests.create = function (request) {
            return $http.post(App_URLs.eContractingBaseUri + '/api/requests', request);
        };

        ContractRequests.preVisaContract = function (request) {
            return $http.post('app/contracts/requests/preVisaContracts', request);
        };

        ContractRequests.preVisaContracts = function () {
            return $http.get('app/contracts/requests/preVisaContracts');
        };

        ContractRequests.show = function (contractId) {
            return $http.get('app/contracts/' + contractId);
        };

        ContractRequests.cancel = function (contractId, data) {
            return $http.post('/app/contracts/' + contractId + '/cancel', data);
        };

        ContractRequests.acceptCancel = function (contractId) {
            return $http.post('/app/contracts/' + contractId + '/acceptCancel');
        };

        ContractRequests.rejectCancel = function (contractId, data) {
            return $http.post('/app/contracts/' + contractId + '/rejectCancel', data);
        };

        ContractRequests.acceptPaymentCompensation = function (paymentId) {
            return $http.post('/app/contracts/paymentCompensation/' + paymentId + '/accept');
        };

        ContractRequests.rejectPaymentCompensation = function (paymentId, data) {
            return $http.post('/app/contracts/paymentCompensation/' + paymentId + '/reject', data);
        };

        ContractRequests.showPaymentCompensation = function (paymentId) {
            return $http.get('/app/contracts/paymentCompensation/' + paymentId);
        };

        ContractRequests.replacement = function (contractId, data) {
            return $http.post('app/contracts/requests/replacement/' + contractId, data);
        };

        return ContractRequests;
    };

    angular.module('musaned.app.contracts.requests')
        .service('ContractRequests', ContractRequests);
})();