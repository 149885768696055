(function () {
    'use strict';

    var AppointmentCtrl = function ($rootScope, $scope, $state, $translate, Auth, $http, blockUI) {

        if (!Auth.user().email) {
            $state.go('home');
        }

        var block = blockUI.instances.get('appointment_panel');

        var vm = this;

        vm.errorMessage = '';
        vm.lang = $rootScope.lang;
        vm.widget = new TimekitBooking();
        vm.email = Auth.user().email;
        vm.name = Auth.user().name;
        vm.id_number = Auth.user().username;
        vm.dates = [];
        vm.gios = {};
        vm.office = null;
        vm.timekit_app = null;
        vm.bookings = null;

        $http.get('app/appointments/getGios').then(function (res) {
            angular.forEach(res.data, function (value) {
                vm.gios[value.id] = value;
            });
        });

        vm.setEvents = function () {

            block.start();

            $http.get('app/appointments?gio=' + vm.office).then(function (res) {

                vm.bookings = res.data.data;

                vm.widget.fullCalendar('removeEventSources');
                var events = [];
                var startMoment = moment({hour: 8, minute: 0}).add(1, 'day').locale('en');
                var futureDuration = moment({hour: 0, minute: 0}).locale('en').add(2, 'weeks');
                for (
                    var i = startMoment;
                    i <= futureDuration;
                    i = i.add(15, 'minutes')) {

                    var count = 0;
                    angular.forEach(vm.bookings, function (value) {
                        if (value.attributes.event.start == i.format()) {
                            count++;
                        }
                    });

                    if (count < vm.gios[vm.office].timekit_slot) {
                        events.push({
                            custom_event: true,
                            start: i.locale('en').format(),
                            end: moment(i.format()).add(15, 'minutes').locale('en').format()
                        });
                    }

                }

                vm.widget.fullCalendar('addEventSource', events);

                vm.widget.fullCalendar('gotoDate', moment().add(3, 'days'));

            }).finally(function () {
                block.stop();
            });

        }

        vm.setUserBookings = function () {

            block.start();

            $http.get('app/appointments?gio=' + vm.office + '&id_number=' + vm.id_number).then(function (res) {
                angular.forEach(res.data.data, function (value) {
                    if (value.attributes.customer.email == vm.email) {
                        var sdate = new Date(value.attributes.event.start);
                        vm.dates.push((sdate.getMonth() + 1) + "-" + sdate.getDate());
                    }
                });
            }).finally(function () {
                block.stop();
            });

        }

        vm.officeChanged = function () {

            var email = vm.gios[vm.office].timekit_email;

            var key = vm.gios[vm.office].timekit_key;

            vm.errorMessage = '';

            if (key) {

                vm.setUserBookings();

                $http.get('app/appointments/config?gio=' + vm.office).then(function (res) {
                    vm.timekit_app = res.data.app;
                    vm.renderCalendar(email, res.data.calendar, key);
                });

            } else {

                vm.errorMessage = $translate.instant('APPOINTMENTS.PHRASES.OFFICE_NOT_AVAILABLE');

            }

        }

        vm.renderCalendar = function (email, calendar, key) {

            if (vm.timekit_app) {
                setTimeout(function () {
                    vm.widget.init({
                        targetEl: '#bookingjs',
                        app: vm.timekit_app,
                        email: email,
                        apiToken: key,
                        calendar: calendar,
                        availabilityView: 'agendaWeek',
                        goToFirstEvent: false,
                        timekitCreateBooking: {
                            graph: 'instant',
                            customer: {
                                timezone: 'Asia/Riyadh'
                            }
                        },
                        fullCalendar: {
                            allDaySlot: false,
                            minTime: '08:00:00',
                            maxTime: '14:30:00',
                            hiddenDays: [5, 6],
                            locale: $rootScope.lang,
                            eventRender: function (event) {
                                if (typeof event.custom_event == 'undefined') {
                                    return false;
                                }
                            }
                        },
                        localization: {
                            strings: {
                                submitText: $translate.instant('APPOINTMENTS.PHRASES.BOOK_IT'),
                                successMessageTitle: $translate.instant('APPOINTMENTS.PHRASES.THANK_YOU'),
                                successMessageBody: $translate.instant('APPOINTMENTS.PHRASES.BOOKING_SUCCESS')
                            }
                        },
                        timekitFindTime: {future: '1 day', length: '1 hour'},
                        bookingFields: {
                            name: {
                                placeholder: 'ID number',
                                prefilled: vm.id_number,
                                locked: true
                            },
                            email: {
                                prefilled: vm.email,
                                locked: true
                            },
                            comment: {
                                enabled: false
                            },
                            voip: {
                                enabled: true,
                                placeholder: 'Customer Name',
                                prefilled: vm.name,
                                locked: true
                            }
                        },
                        callbacks: {
                            showBookingPage: function (event) {

                                var sdate = new Date(event.start._i);

                                if (vm.dates.indexOf((sdate.getMonth() + 1) + "-" + sdate.getDate()) != -1) {

                                    $('.booking-err').remove();

                                    setTimeout(function () {

                                        $('.bookingjs-form').remove();

                                        $('.bookingjs-bookpage-header').after(
                                            $('<div>').attr('class', 'alert alert-danger booking-err')
                                                .text($translate.instant('APPOINTMENTS.PHRASES.ONCE_A_DAY_BOOKING'))
                                        );

                                    }, 200);

                                }

                            },
                            createBookingSuccessful: function () {
                                vm.setUserBookings();
                            },
                            renderCompleted: function () {
                                vm.setEvents();
                            },
                            closeBookingPage: function () {
                                vm.setEvents();
                            }
                        }
                    });
                }, 500);
            }
        }

    };

    angular.module('musaned.app')
        .controller('AppointmentCtrl', AppointmentCtrl);
})();
