(function () {
  'use strict';

  var User = function ($rootScope, $http, Auth) {
    var User = {};

    User.info = function () {
      Auth.user();
    };

    User.updateDetails = function (data) {
        return $http.post('/app/user/details/info', data);
    };

    User.updateContactDetails = function (data) {
      return $http.post('/app/user/details/contacts', data);
    };

    User.changePassword = function (data) {
      return $http.post('/app/user/details/password', data);
    };

    User.reasons_cancel_visa = function () {
      return $http.get('/app/user/details/reasons_cancel_visa');
    };

    User.cancel_visa = function (data) {
      return $http.post('/app/user/details/cancel_visa', data);
    };

    return User;
  };

  angular.module('musaned.app.user')
    .service('User', User);
})();
