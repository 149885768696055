(function () {
  'use strict';

  var PasswordResetForm = function ($translate, Validators) {
    var PasswordResetForm = {};

    PasswordResetForm.fields = [
      //{
      //  key: 'token',
      //  type: 'input',
      //  templateOptions: {
      //    label: $translate.instant('USERS.LABELS.TOKEN'),
      //    placeholder: $translate.instant('USERS.LABELS.TOKEN')
      //  },
      //  expressionProperties: {
      //    'templateOptions.label': '"USERS.LABELS.TOKEN" | translate',
      //    'templateOptions.placeholder': '"USERS.LABELS.TOKEN" | translate'
      //  }
      //},
      {
        key: 'email',
        type: 'input',
        templateOptions: {
          type: 'text',
          label: $translate.instant('USERS.LABELS.EMAIL'),
          placeholder: $translate.instant('USERS.LABELS.EMAIL')
        },
        expressionProperties: {
          'templateOptions.label': '"USERS.LABELS.EMAIL" | translate',
          'templateOptions.placeholder': '"USERS.LABELS.EMAIL" | translate'
        }
      },
      {
        key: 'id_number',
        type: 'input',
        templateOptions: {
          label: $translate.instant('USERS.LABELS.ID_NUMBER'),
          placeholder: $translate.instant('USERS.LABELS.ID_NUMBER')
        },
        expressionProperties: {
          'templateOptions.label': '"USERS.LABELS.ID_NUMBER" | translate',
          'templateOptions.placeholder': '"USERS.LABELS.ID_NUMBER" | translate'
        }
      },
      {
        key: 'password',
        type: 'input',
        templateOptions: {
          type: 'password',
          required: true,
          label: $translate.instant('USERS.LABELS.PASSWORD'),
          placeholder: $translate.instant('USERS.PLACEHOLDERS.PASSWORD')
        },
        expressionProperties: {
          'templateOptions.label': '"USERS.LABELS.PASSWORD" | translate',
          'templateOptions.placeholder': '"USERS.PLACEHOLDERS.PASSWORD" | translate'
        },
        validators: {
          complexity: Validators.passwordComplexity('user')
        }
      },
      {
        key: 'password_confirmation',
        type: 'input',
        optionsTypes: ['matchField'],
        data: {
          fieldToMatch: 'password'
        },
        templateOptions: {
          type: 'password',
          required: true,
          label: $translate.instant('USERS.LABELS.PASSWORD_CONFIRMATION'),
          placeholder: $translate.instant('USERS.PLACEHOLDERS.PASSWORD_CONFIRMATION')
        },
        expressionProperties: {
          'templateOptions.label': '"USERS.LABELS.PASSWORD_CONFIRMATION" | translate',
          'templateOptions.placeholder': '"USERS.PLACEHOLDERS.PASSWORD_CONFIRMATION" | translate'
        }
      }
    ];

    return PasswordResetForm;
  };

  angular.module('musaned.app.password')
    .service('PasswordResetForm', PasswordResetForm);
})();
