(function () {
    'use strict';

    var TawtheeqContractConfirmCtrl = function ($window, $scope, $state, $translate, $rootScope, $timeout, $stateParams, blockUI, $modal, Upload, TawtheeqContracts, $ocLazyLoad) {
        var vm = this;
        vm.laborerId = $stateParams.laborerId;
        var block = blockUI.instances.get('contracts_tawtheeq');

        vm.data = {};
        vm.sendDatasendData = {};

        block.start();
        TawtheeqContracts.confirm(vm.laborerId)
            .then(function (res) {
                if (res.data.contract) {
                    vm.data = res.data.contract;

                    if (res.data.contract.worker_relative) {
                        vm.data.relative_name = res.data.contract.worker_relative.relative_name;
                        vm.data.relative_type = (res.data.contract.worker_relative.relative_type) + "";
                        vm.data.relative_phone = ("966" == res.data.contract.worker_relative.relative_phone.slice(0, 3)) ? res.data.contract.worker_relative.relative_phone.replace("966", "0") : res.data.contract.worker_relative.relative_phone;
                        vm.data.relative_address = res.data.contract.worker_relative.relative_address;

                    }
                    if (res.data.contract.employer_mobile) {
                        vm.data.employer_mobile = ("966" == res.data.contract.employer_mobile.slice(0, 3)) ? res.data.contract.employer_mobile.replace("966", "0") : res.data.contract.employer_mobile;

                    }
                    vm.data.worker_mobile = ("966" == res.data.contract.worker_mobile.slice(0, 3)) ? res.data.contract.worker_mobile.replace("966", "0") : res.data.contract.worker_mobile;
                    vm.data.salary = parseFloat(res.data.contract.salary);


                    vm.data.account_no = res.data.contract.account_no;
                }


                vm.data.worker = res.data.worker;
                vm.data.userNationality = res.data.nic_user_info.nationality;
                vm.data.userNationality_en = res.data.nic_user_info.nationality_en;
                vm.data.userName = res.data.nic_user_info.first_name + " " + res.data.nic_user_info.last_name;
                vm.data.userGender = res.data.nic_user_info.gender;
                vm.data.userId = res.data.user_info.id_number;
                vm.data.userMobile = ("966" == res.data.user_info.mobile.slice(0, 3)) ? res.data.user_info.mobile.replace("966", "0") : res.data.user_info.mobile;
                vm.data.userEmail = res.data.user_info.email;
                vm.data.marital_status = res.data.nic_user_info.marital_status;
                vm.data.marital_status_en = res.data.nic_user_info.marital_status_en;
                vm.data.wasel = res.data.wasel_info;
                vm.data.street_number = res.data.user_info.user_details.street_number;
                vm.data.route_ar = res.data.user_info.user_details.route;
                vm.data.locality_ar = res.data.user_info.user_details.locality;
                vm.data.sublocality_ar = res.data.user_info.user_details.sublocality;
                vm.data.postal_code = res.data.user_info.user_details.postal_code;
                vm.data.postal_code_suffix = res.data.user_info.user_details.postal_code_suffix;
                vm.data.userPhone = '';
                vm.data.user = res.data;
                vm.lookupBanks = res.data.banks;
                vm.lookupCities = res.data.cities;

            }, function (res) {
                var title = 'حدث خطأ اثناء استرجاع البيانات', text = '';
                if (res.data.error) {
                    if (res.data.error.code == 105) {
                        text = 'الرجاء التسجيل في خدمة العنوان الوطني المقدمة من البريد السعودي عبر هذا <a href="https://register.address.gov.sa/ar" target="_blank">الرابط</a>';
                    } else if (res.data.error.code == 106) {
                        text = 'حدث خطأ اثناء استرجاع معلومات العنوان الوطني, الرجاء المحاولة مرة أخرى.';
                    }
                }
                //      vm.errorMessage= text;

                sweetAlert({
                    type: 'error',
                    title: title,
                    text: text,
                    html: true
                })
            })
            .finally(function () {
                block.stop();
            });

        $scope.toggle_details = function () {
            $scope.show_details = !$scope.show_details;
        };

        $scope.getBankDetails = function (id) {
            return _.filter(vm.lookupBanks, function (bank) {
                return bank.id == id
            })[0];
        }

        $scope.submit = function () {
            var block = blockUI.instances.get('contracts_tawtheeq');
            block.start();
            vm.sendData = angular.copy(vm.data);
            delete vm.sendData.user;
            delete vm.sendData.worker;

            if (vm.data.bank_id) {
                var bank = $scope.getBankDetails(vm.data.bank_id);

                vm.data.bank_label = bank.label;
                vm.data.bank_label_en = bank.label_en;
            }
            if (vm.data.city) {
                vm.data.city_label_ar = vm.data.city;
                vm.data.city_label_en = vm.data.city;
            }
            return TawtheeqContracts.confirmData(vm.laborerId, vm.sendData)
                .then(function (res) {
                    if (res.status == 200) {
                        sweetAlert({
                            title: $translate.instant("TAWTHEEQ_CONTRACTS.PHRASES.CONFIRM"),
                            text: $translate.instant("TAWTHEEQ_CONTRACTS.PHRASES.CONFIRM_MSG"),
                            type: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#DD6B55",
                            confirmButtonText: $translate.instant("COMMON.ACTIONS.YES"),
                            cancelButtonText: $translate.instant("COMMON.ACTIONS.GO_BACK"),
                            closeOnConfirm: false,
                            showLoaderOnConfirm: true
                        }, function (confirmed) {
                            if (confirmed) {
                                //    vm.errorMessage = '';
                                sweetAlert.close();
                                var modalInstance = $modal.open({
                                    animation: false,
                                    templateUrl: 'partials/app/eTawtheeq/tawtheeqContracts/view.html',
                                    controller: 'TawtheeqContractViewCtrl',
                                    size: 'lg',
                                    windowClass: 'print-visa-modal',
                                    resolve: {
                                        laborerId: function () {
                                            return vm.laborerId;
                                        },
                                        details: function () {
                                            return vm.data;
                                        }
                                    }
                                });
                            }
                        });
                    }
                }, function (res) {
                    var title = 'حدث خطأ اثناء التحقق من البيانات!', text = '';
                    if (res.data.errors) {
                        if (res.data.errors.worker_name) {
                            text += res.data.errors.worker_name;
                            text += '\n';
                        }
                        if (res.data.errors.worker_mobile) {
                            text += res.data.errors.worker_mobile;
                            text += '\n';
                        }
                        if (res.data.errors.salary) {
                            text += res.data.errors.salary;
                            text += '\n';
                        }
                        if (res.data.errors.employer_mobile) {
                            text += res.data.errors.employer_mobile;
                            text += '\n';
                        }
                        if (res.data.errors.location) {
                            text += res.data.errors.location;
                            text += '\n';
                        }
                        if (res.data.errors.relative_address) {
                            text += res.data.errors.relative_address;
                            text += '\n';
                        }
                        if (res.data.errors.relative_phone) {
                            text += res.data.errors.relative_phone;
                            text += '\n';
                        }
                        if (res.data.errors.relative_type) {
                            text += res.data.errors.relative_type;
                            text += '\n';
                        }
                        if (res.data.errors.relative_name) {
                            text += res.data.errors.relative_name;
                            text += '\n';
                        }
                        if (res.data.errors.account_no) {
                            text += res.data.errors.account_no;
                            text += '\n';
                        }
                    } else if (res.data.errors.code == 422) {
                        text = 'خطأ في المدخلات, تأكد من صحة البيانات المدخلة';
                    }
                    //      vm.errorMessage= text;

                    sweetAlert({
                        type: 'error',
                        title: title,
                        text: text
                    })
                })
                .finally(function () {
                    block.stop();
                });


        };

        $scope.validate = function () {

            var validate = (
                vm.data.worker_name_en &&
                vm.data.worker_name &&
                vm.data.worker_mobile &&
                vm.data.salary &&
                vm.data.salary >=100 &&
                vm.data.salary <=99999 &&
                vm.data.account_no &&
                vm.data.bank_id
            );
            return validate;
        };
    };

    angular.module('musaned.app.eTawtheeq.tawtheeqContracts')
        .controller('TawtheeqContractConfirmCtrl', TawtheeqContractConfirmCtrl);
})();
