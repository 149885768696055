(function () {
  'use strict';

  var NoticeRequestCreationForm = function ($rootScope, $translate, blockUI) {
    var CreationForm = {};

    CreationForm.checklist = {
      "questions": [
        {
          "id": 1,
          "text": ('CREATIONFORM.Q9.Q9'),
          "answers": [{"id": 1, "text": ('COMMON.ACTIONS.YES')}, {"id": 2, "text": ('COMMON.ACTIONS.NO')}],
          "selectedAnswer": null,
          "visible": true,
          "type":"radio"

        },
        {
          "id": 2,
          "text": ('CREATIONFORM.Q10.Q10'),
          "answers": [{"id": 1, "text": ('CREATIONFORM.Q10.A1')}, {"id": 2, "text": ('CREATIONFORM.Q10.A2')},{"id": 3, "text": ('CREATIONFORM.Q10.A3')}, {"id": 4, "text": ('CREATIONFORM.Q10.A4')},{"id": 6, "text": ('CREATIONFORM.Q10.A6')},{"id": 7, "text": ('CREATIONFORM.Q10.A7')},{"id": 5, "text": ('CREATIONFORM.Q10.A5')},{"id": 8, "text": ('CREATIONFORM.Q10.A8')}],
          "selectedAnswer": null,
          "visible": true,
          "type":"select"

        },
        {
          "id": 3,
          "text": ('CREATIONFORM.Q3.Q3'),
          "answers": [{"id": 3, "text": ('CREATIONFORM.Q3.A1')}, {"id": 4, "text": ('CREATIONFORM.Q3.A2')}],
          "selectedAnswer": null,
          "visible": true,
          "type":"radio"
        },



      ]
    };

    CreationForm.fields = {
      step2: [
        {
          key: 'financial_capability_type',
          type: 'radio',
          templateOptions: {
            label: $translate.instant('CREATIONFORM.Q8.Q8'),
            options: [
              {value: 1, name:$translate.instant('CREATIONFORM.Q8.A1')},
              {value: 2, name:$translate.instant('CREATIONFORM.Q8.A2')}
            ],
            required: true
          }
        },
        {
          key: 'financial_capability_value',
          type: 'input',
          ngModelAttrs: {
            arabicNumbersVal: {
              attribute: 'tmk-arabic-numbers'
            }
          },
          templateOptions: {
            type: 'tel',
            required: true,
            minValue: 500,
            maxValue: 999999999999999,
            defaultValue: '',
            arabicNumbersVal: 'Arabic Digits'
          },
          hideExpression: '!model.financial_capability_type',
          controller: function ($scope) {
            $scope.$watch('model.financial_capability_type', function (newValue, oldValue) {
              $scope.model.financial_capability_value = '';
              if (newValue == 1) {
                $scope.to.label = $translate.instant('CREATIONFORM.Q8.A3');
                // $scope.to.minValue = 5000;
              } else {
                $scope.to.label = $translate.instant('CREATIONFORM.Q8.A4');
                // $scope.to.minValue = 35000;
              }
            });
          },
          validators: {
            minValue: {
              expression: function ($viewValue, $modelValue, scope) {
                var value = parseInt($modelValue || $viewValue);
                return (value && value >= scope.to.minValue)
              },
              message: function ($viewValue, $modelValue, scope) {
                var value = $modelValue || $viewValue;
                if (value && value < scope.to.minValue) {
                  return scope.to.label+' '+$translate.instant('COMMON.MUST_BE_AT_LEAST')+' '+scope.to.minValue + $translate.instant('COMMON.SR')+'.';
                } else {
                  return '';
                }
              }
            },
            maxValue: {
              expression: function ($viewValue, $modelValue, scope) {
                var value = parseInt($modelValue || $viewValue);
                return (value && value <= scope.to.maxValue)
              },
              message: function ($viewValue, $modelValue, scope) {
                var value = $modelValue || $viewValue;
                if (value && value > scope.to.maxValue) {
                  return scope.to.label +' '+$translate.instant('COMMON.MUST_BE_AT_LEAST')+' '+scope.to.maxValue + $translate.instant('COMMON.SR')+'.';
                } else {
                  return '';
                }
              }
            }
          }
        }
      ]
    };

    return CreationForm;
  };

  angular.module('musaned.app.notices')
    .service('NoticeRequestCreationForm', NoticeRequestCreationForm)
})();
