(function () {
    'use strict';

    var InquiryCtrl = function ($scope, $rootScope, $http, blockUI) {

        var vm = this;
        var block = blockUI.instances.get('inquiry_form');

        $scope.inquiry = {};
        vm.errorMessage = '';
        vm.result = false;
        vm.reason = "";

        vm.range = function (min, max, step) {
            step = step || 1;
            var input = [];
            for (var i = min; i <= max; i += step) {
                input.push(i);
            }
            return input;
        };

        vm.submitInquiry = function () {
            block.start();
            $http.get('/inquiry/results', {params: $scope.inquiry})
                .then(function (res) {
                    vm.errorMessage = '';
                    vm.result = res.data;
                }, function (res) {
                    vm.result = false;
                    vm.errorMessage = res.data;
                })
                .finally(function(){
                    block.stop();
                });

        }

    };

    angular.module('musaned.app')
        .controller('InquiryCtrl', InquiryCtrl)
})();
