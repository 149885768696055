(function () {
    'use strict';

    var VisaMosaIssueRequestCreationCtrl = function ($anchorScroll, $location, $scope, $timeout, $state, $modal, $rootScope, $translate, Upload, blockUI, VisaMosaIssueRequestCreationForm, VisaMosaIssueRequestCreation, Visas, $ocLazyLoad) {
        var vm = this;

        $ocLazyLoad.load('/assets/js/pdf.combined.js');
        $ocLazyLoad.load('/assets/js/FileAPI.min.js');

        Visas.getSetting().then(function (respone) {
            vm.setting = respone.data;
        });

        var block = blockUI.instances.get('issue_form');
        block.start();
        vm.can_request_new_visa = true;

        vm.checkIssueRequest = function () {
            block.start();
            VisaMosaIssueRequestCreation.checkPermission()
                .then(function (res) {
                    vm.can_request_new_visa = true;
                    $rootScope.occupations = res.data.occupationsList;
                    vm.fields = VisaMosaIssueRequestCreationForm.fields;
                }, function (res) {
                    $location.hash('errorMessage');
                    $anchorScroll();
                    vm.can_request_new_visa = false;
                    if (res.data.error.code == 120) {
                        vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.EXCEEDED_MAX_ALLOWED_REQUEST');
                    } else if (res.data.error.code == 121) {
                        vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.GENDER_STATUS_ERROR');
                    } else if (res.data.error.code == 111) {
                        vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.USER_HAS_TRAFFIC_VIOLATION');
                    } else if (res.data.error.code == 122) {
                        vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.STOP_GOVERNMENT_SERVICES');
                    } else if (res.data.error.code == 106) {
                        vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.DIED');
                    } else if (res.data.error.code == 105) {
                        vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.MOSA_ERROR__NOT_FOUND');
                    } else if (res.data.error.code == 107) {
                        vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.CANNOT_OPEN_SPONSER_FILE');
                    } else if (res.data.error.code == 511) {
                        vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.TIMEOUT');
                    } else if (res.data.error.code == 110) {
                        vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.ORDER_SUPPORT');
                    } else if (res.data.error.code == 111) {
                        vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.ERROR__HAS_PROBLEM');
                    } else if (res.data.error.code >= 130 && res.data.error.code < 140) {
                        vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.' + res.data.error.code);
                    } else if (res.data.error.code == 123 ) {
                        vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.MOSA_ERROR__NOT_APPLICABLE');
                    } else {
                        vm.errorMessage = $translate.instant(res.data.error.message);
                    }
                })
                .finally(function () {
                    block.stop();
                });
        };
        block.stop();
        vm.pageChanged = function () {

            Visas.all(vm.currentPage)
                .then(function (res) {
                    vm.paginator = res.data.paginator;
                    vm.currentPage = vm.paginator.current_page;
                })
                .finally(function () {
                    block.stop();
                });
        };

        vm.checkIssueRequest();
        vm.pageChanged();
        vm.checklist = VisaMosaIssueRequestCreationForm.checklist;
        vm.model = {
            documents: {}
        };
        vm.files = [];

        vm.uploads = [{
            'id': 1,
            'name': $translate.instant("VISAS.ISSUE_REQUESTS.PHRASES.PROOF_FINANCIAL_CAPACITY"),
            'files': [],
            'required': true
        },
            {id: 2, 'name': $translate.instant("VISAS.ISSUE_REQUESTS.PHRASES.COPY_ID"), 'files': [], 'required': false},
            {
                id: 3,
                'name': $translate.instant("VISAS.ISSUE_REQUESTS.PHRASES.OTHER_SUPPORTING_DOCUMENT"),
                'files': [],
                'required': false
            }];
        vm.upload_disabled = false;

        $scope.$watch('vm.files', function (newFiles) {
            $scope.formUpload = false;
            if (newFiles != null) {
                for (var i = 0; i < newFiles.length; i++) {
                    $scope.errorMsg = null;
                    (function (file) {
                        vm.upload(file);
                    })(newFiles[i]);
                }
            }
        });
        vm.uploadFile = function (files, type) {
            if (files && files.length) {
                for (var i = 0; i < files.length; i++) {
                    vm.upload(files[i], type);
                }
            }
        };
        vm.upload = function (file, type) {
            vm.upload_disabled = true;
            vm.errorMsg = [];
            file.upload = Upload.upload({
                url: '/app/user/uploads/upload' + vm.getReqParams(),
                method: 'POST',
                file: file,
                fileFormDataName: 'file'
            });

            file.upload
                .then(function (res) {
                    $timeout(function () {
                        file.result = res.data.userUpload;
                        res.data.userUpload.type = type;
                        vm.uploads[type - 1].files.push(res.data.userUpload);
                        vm.upload_disabled = false;
                    });
                }, function (res) {
                    if (res.status == 422) {
                        vm.errorMsg.push('(' + file.name + ') ' + res.data.errors.file);
                    } else {
                        vm.errorMsg.push('(' + file.name + ') ' + $translate.instant(res.data.error.message));
                    }
                    vm.upload_disabled = false;
                });
            file.upload.progress(function (evt) {
                // Math.min is to fix IE which reports 200% sometimes
                file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
            });
        };

        vm.disableStep = function () {

            var at_least_one_proof = true;
            for (var i = 0; i < vm.uploads.length; i++) {
                for (var j = 0; j < vm.uploads[i].files.length; j++) {
                    if (vm.uploads[i].files[j].type == 1) {
                        at_least_one_proof = false;
                    }
                }
            }

            return at_least_one_proof;
        };

        vm.getReqParams = function () {
            return vm.generateErrorOnServer ? '?errorCode=' + vm.serverErrorCode + '&errorMessage=' + vm.serverErrorMsg : '';
        };
        vm.removeFile = function (idx, type) {
            vm.uploads[type - 1].files.splice(idx, 1);
        };

        // function assignment
        vm.finishWizard = function () {
            var block = blockUI.instances.get('issue_form');
            block.start();

            vm.errorMessage = '';

            vm.uploads.map(function (upload) {
                upload.files.map(function (uploadFile) {
                    vm.model.documents[uploadFile.id] = uploadFile.type;
                });
            });

            return VisaMosaIssueRequestCreation.create(vm.model)
                .then(function (res) {
                    $rootScope.successMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.SUCCESS');
                    for (var i = 0; i < vm.checklist.questions.length; i++) {
                        vm.checklist.questions[i].selectedAnswer = null;
                    }
                    $state.go('visas.issue_requests.list');
                }, function (res) {
                    $location.hash('errorMessage');
                    $anchorScroll();
                    if (res.data.error.code == 104) {
                        vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.USER_NOT_ALLOWED');
                    } else if (res.data.error.code == 102) {
                        vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.MISSING_FILES');
                    } else if (res.data.error.code == 103) {
                        vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.PENDING_REQUEST');
                    } else if (res.data.error.code == 120) {
                        vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.EXCEEDED_MAX_ALLOWED_REQUEST');
                    } else if (res.data.error.code >= 130 && res.data.error.code < 140) {
                        vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.' + res.data.error.code);
                    } else {
                        vm.errorMessage = $translate.instant(res.data.error.message);
                    }
                })
                .finally(function () {
                    block.stop();
                });
        };

        vm.allQuestionsAnswered = function () {
            for (var i = 0; i < vm.checklist.questions.length; i++) {
                if (
                    vm.checklist.questions[i].visible == true &&
                    ( !vm.checklist.questions[i].selectedAnswer || vm.checklist.questions[i].selectedAnswer == 2)
                ) {
                    return true;
                }
            }
            return false;
        };

        vm.showQuestion = function (item, dependent) {
            if (!dependent)
                return true;

            if (vm.checklist.questions[dependent.index].selectedAnswer == dependent.value) {
                vm.checklist.questions[item].visible = true;
            } else {
                vm.checklist.questions[item].visible = false;
                vm.checklist.questions[item].selectedAnswer = null;
            }
            return vm.checklist.questions[item].visible;
        };

    };

    angular.module('musaned.app.visas.mosa.issueRequests')
        .controller('VisaMosaIssueRequestCreationCtrl', VisaMosaIssueRequestCreationCtrl);
})();
