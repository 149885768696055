(function () {
  'use strict';

  var Dashboard = function ($http) {
    var Dashboard = {};

    Dashboard.getData = function () {
      return $http.get('app/user/dashboard');
    };

    Dashboard.getServiceDeskData = function () {
      return $http.get('app/user/dashboard/service_requests');
    };

    return Dashboard;
  };

  angular.module('musaned.app.dashboard')
    .service('Dashboard', Dashboard);
})();
