(function () {
    'use strict';

    var ReplacementRequestCtrl = function ($anchorScroll, $translate, $scope, $location, $state, $rootScope, $timeout, officeRequest, VisaIssueRequests, $stateParams, blockUI, WizardHandler, Upload, Visas, ContractRequests, $ocLazyLoad, VisaIssueRequestCreationForm, ReplacementRequestCreation) {
        var vm = this;

        $ocLazyLoad.load('/assets/js/pdf.combined.js');
        $ocLazyLoad.load('/assets/js/FileAPI.min.js');

        vm.fields = VisaIssueRequestCreationForm.fields;
        vm.requestId = $stateParams.requestId;

        ContractRequests.offices().then(function (res) {
            vm.offices = res.data.offices;
        });
        vm.officeId = null;
        $scope.officeRequest = officeRequest.get(vm.requestId)
            .then(function (res) {
                vm.contractRequest = res.data.contractRequests;
                for (var i = 0; i < vm.contractRequest.office_contract_requests.length; i++) {

                    if (vm.contractRequest.office_contract_requests[i].contract != null) {
                        vm.officeId = vm.contractRequest.office_contract_requests[i].office_id;
                        $scope.step3.offices = vm.officeId;
                        $scope.getServices();
                        $scope.step3.contractId = vm.contractRequest.office_contract_requests[i].contract.id;
                        // $scope.step3.Services = vm.contractRequest.office_contract_requests[i].contract.visa.nationality.id;

                    }
                }
                vm.visa = res.data.contractRequests.visa;

            });

       /* Visas.get(vm.requestId)
            .then(function (res) {
                vm.visa = res.data.visa;
                vm.requestId = res.data.visa.issueRequest.id;

            });*/


        vm.data = {};
        vm.model = {
            documents: {}
        };

        vm.daysList = [];
        vm.monthsList = [];
        vm.yearsList = [];
        var i = 1;
        for (i = 1; i <= 30; i++) vm.daysList.push({value: i, label: i + ""});
        for (i = 1; i <= 12; i++) vm.monthsList.push({value: i, label: i + ""});
        for (i = 1300; i <= 1450; i++) vm.yearsList.push({value: i, label: i + ""});

        vm.files = [];
        vm.uploads = [];
        vm.upload_disabled = false;
        $scope.$watch('vm.files', function (newFiles) {
            $scope.formUpload = false;
            if (newFiles != null) {
                for (var i = 0; i < newFiles.length; i++) {
                    $scope.errorMsg = null;
                    (function (file) {
                        upload(file);
                    })(newFiles[i]);
                }
            }
        });

        var upload = function (file) {
            vm.upload_disabled = true;
            vm.errorMsg = [];
            file.upload = Upload.upload({
                url: '/app/user/uploads/upload' + vm.getReqParams(),
                method: 'POST',
                file: file,
                fileFormDataName: 'file'
            });

            file.upload
                .then(function (res) {
                    $timeout(function () {
                        file.result = res.data.userUpload;
                        vm.uploads.push(res.data.userUpload);
                        vm.upload_disabled = false;
                    });
                }, function (res) {
                    if (res.status == 422) {
                        vm.errorMsg.push('(' + file.name + ') ' + res.data.errors.file);
                    } else {
                        vm.errorMsg.push('(' + file.name + ') ' + $translate.instant(res.data.error.message));
                    }
                    vm.upload_disabled = false;
                });
            file.upload.progress(function (evt) {
                // Math.min is to fix IE which reports 200% sometimes
                file.progress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
            });
        };


        vm.getReqParams = function () {
            return vm.generateErrorOnServer ? '?errorCode=' + vm.serverErrorCode + '&errorMessage=' + vm.serverErrorMsg : '';
        };
        vm.removeFile = function (idx) {
            vm.uploads.splice(idx, 1);
        };

        $scope.step0 = {};
        $scope.step1 = {};
        $scope.step2 = {};
        $scope.step3 = {};


        $scope.nextBtn = false;

        $scope.watchRegions = function () {
            block.start();

            ContractRequests.cities($scope.proRegion.id)
                .then(function (res) {
                    $scope.cities = res.data.cities;
                })
                .finally(function () {
                    block.stop();
                });
        };

        $scope.proSearch = function () {
            block.start();
            var data = {};

            if (vm.proName) {
                data.name = vm.proName;
            }

            if (vm.proRegion) {
                data.region = vm.proRegion.id;
            }

            if (vm.proCity) {
                data.city = vm.proCity.id;
            }

            ContractRequests.proSearch(data)
                .then(function (res) {
                    $scope.pros = res.data.offices;
                })
                .finally(function () {
                    block.stop();
                });
        };


        $scope.finishWizard = function () {
            var block = blockUI.instances.get('replacement_form');
            block.start();

            $scope.errorMessage = '';

            vm.uploads.map(function (upload) {
                vm.model.documents[upload.id] = upload.type;
            });
            vm.model.visa_id = vm.visa.id;
            vm.model.reason_id = $scope.step0.reason;
            vm.model.reason_details = $scope.step0;

            if ($scope.step0.reason == 1) {
                vm.model.reason_details.entryDate = $scope.step0.leaveIssue.entryDate.year + '-' + $scope.step0.leaveIssue.entryDate.month + '-' + $scope.step0.leaveIssue.entryDate.day;
                vm.model.reason_details.leaveDate = $scope.step0.leaveIssue.leaveDate.year + '-' + $scope.step0.leaveIssue.leaveDate.month + '-' + $scope.step0.leaveIssue.leaveDate.day;

            }
            if ($scope.step0.reason == 2) {
                vm.model.reason_details.entryDate = $scope.step0.wentOut.entryDate.year + '-' + $scope.step0.wentOut.entryDate.month + '-' + $scope.step0.wentOut.entryDate.day;
                vm.model.reason_details.leaveDate = $scope.step0.wentOut.leaveDate.year + '-' + $scope.step0.wentOut.leaveDate.month + '-' + $scope.step0.wentOut.leaveDate.day;

            }
            if ($scope.step0.reason == 3) {
                vm.model.reason_details.runAwayDate = $scope.step0.runAway.entryDate.year + '-' + $scope.step0.runAway.entryDate.month + '-' + $scope.step0.runAway.day;
            }

            var data = {};
            angular.extend(data, vm.data, $scope.step1, $scope.step2, $scope.step3, vm.model);

            return ReplacementRequestCreation.create(vm.requestId, data)
                .then(function (res) {
                    $rootScope.successMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.SUCCESS');
                    $state.go('contracts.requests.list');
                }, function (res) {
                    $location.hash('errorMessage');
                    $anchorScroll();
                    if (res.data.error.code == 104) {
                        vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.USER_NOT_ALLOWED');
                    } else if (res.data.error.code == 102) {
                        vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.MISSING_FILES');
                    } else if (res.data.error.code == 103) {
                        vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.PENDING_REQUEST');
                    } else {
                        vm.errorMessage = $translate.instant(res.data.error.message);
                    }
                })
                .finally(function () {
                    block.stop();
                });
        };

        vm.disableStep = function () {
            if (vm.uploads.length == 0 || vm.uploads.length > 10) {
                return true;
            }

            var at_least_one_proof = true;
            var reason_proof = true;

            var reason_proof_1 = false;
            var reason_proof_2 = false;
            for (var i = 0; i < vm.uploads.length; i++) {
                if (typeof vm.uploads[i].type == "undefined") {
                    return true;
                }

                if (vm.uploads[i].type == 1) {
                    at_least_one_proof = false;
                }
                switch ($scope.step0.reason) {
                    case '1':
                        if(vm.uploads[i].type == 4 )reason_proof_1 = true;
                        if(vm.uploads[i].type == 5 )reason_proof_2 = true;

                        break;
                    case '2':
                        if(vm.uploads[i].type == 6 )reason_proof_1 = true;
                        if(vm.uploads[i].type == 7 )reason_proof_2 = true;
                        break;
                    case '3':
                        if($scope.step0.runAway ==1 && vm.uploads[i].type == 8 ){
                            reason_proof_1 = true;
                            reason_proof_2 = true;
                        }
                        if($scope.step0.runAway ==2 && vm.uploads[i].type == 9 ){
                            reason_proof_1 = true;
                            reason_proof_2 = true;
                        }
                        break;
                    default:

                }

            }
            reason_proof = !(reason_proof_1 && reason_proof_2 && at_least_one_proof == false)  ;
            return reason_proof;
        };
        $scope.validateStep0 = function () {

            if (!$scope.step0 || !$scope.step0.reason) {
                return false;
            }

            vm.errorMsg = null;
            var validate = false;
            switch ($scope.step0.reason) {
                case "1":
                    $scope.step0.leave_issue != '2';
                    validate = true;
                    break;
                case "2":
                    $scope.step0.wentOutReason != '2';
                    validate = true;
                    break;
            }

            return validate;
        };
        $scope.workerInfo = false;
        $scope.setOccupation = function () {
            vm.model['occupation'] = vm.visa.nationality.id;
            $scope.workerInfo = true;

        };
        $scope.setWorkerInfo = function () {
            $scope.workerInfo = true;

        }

        $scope.validateStep3 = function () {

            if (!$scope.step3) {
                return false;
            }

            if (vm.data.request_type == 1) {
                var validate = (

                    $scope.step3.worker_id != null &&
                    $scope.step3.worker_number != null

                );
            }

            if (vm.data.request_type == 2) {
                var validate = (
                    $scope.step3.method2.confirm != ''
                );
            }

            return validate;
        };
        $scope.getServices = function () {

            ContractRequests.Services($scope.step3.offices)
                .then(function (res) {
                    vm.Services = res.data.Services;
                });
            return vm.Services;


        };

        $scope.changeOffice = function () {
            $scope.allow = false;
            $scope.changeNationality = null;
            $scope.step3.Services = null;
            $scope.agreement = null;
            vm.data.request_type = null;
            $scope.nextBtn = false;


        };

        $scope.viewRequestType = function () {

            $scope.nextBtn = true;

        };

    };


    angular.module('musaned.app.contracts.replacementRequests')
        .controller('ReplacementRequestCtrl', ReplacementRequestCtrl);
})();
