(function () {
  'use strict';

  var RegistrationCtrl = function ($rootScope, $state, $interval, $timeout, $translate, $anchorScroll, blockUI, Registration) {
    var vm = this;

    vm.loadingBlock = blockUI.instances.get('registration');

    vm.daysList = [];
    vm.monthsList = [];
    vm.yearsList = [];
    var i = 1;
    for (i = 1; i <= 30; i++) vm.daysList.push({value: i, label: i+""});
    for (i = 1; i <= 12; i++) vm.monthsList.push({value: i, label: i+""});
    for (i = 1300; i <= 1450; i++) vm.yearsList.push({value: i, label: i+""});

    vm.steps = {
      personalInfo: { id: 1, title: 'USERS.PHRASES.REGISTRATION.PERSONAL_INFO' },
      verification: { id: 2, title: 'USERS.PHRASES.REGISTRATION.VERIFICATION' },
      accountDetails: { id: 3, title: 'USERS.PHRASES.REGISTRATION.ACCOUNT_DETAILS' }
    };

    vm.data = {
      check_date: { day: 1, month: 1, year: 1400 },
      personalInfo: {
        id_type: 1,
        id_number: '',
        first_name: '',
        last_name: '',
        mobile: '',
        check_date: '',
        city: '',
        region: '',
        district: ''
      },
      verification: {
        payload: '',
        code: ''
      },
      accountDetails: {
        payload: '',
        password: '',
        password_confirmation: '',
        email: '',
        city: '',
        region: '',
        district: '',
        smsEnable:''
      }
    };

    vm.currentStep = vm.steps.personalInfo.id;

    vm.errorMessages = [];


    var countdown;

    vm.checkInfo = function () {
      vm.loadingBlock.start();

      vm.data.personalInfo.check_date = vm.data.check_date.year + '-' + vm.data.check_date.month + '-' + vm.data.check_date.day;

      Registration.checkInfo(vm.data.personalInfo)
        .then(function (res) {
          vm.errorMessage = '';
          vm.data.verification.payload = res.data.payload;
          vm.steps.verification.expires = Math.floor((new Date(res.data.expires) - new Date()) / 1000);
          vm.steps.verification.countdown = 15 * 60;
          vm.currentStep = vm.steps.verification.id;

          countdown = $interval(function () {
            vm.steps.verification.countdown = vm.steps.verification.countdown - 1;
          }, 1000, vm.steps.verification.countdown);

          $timeout(function () {
            vm.currentStep = vm.steps.personalInfo.id;
          }, vm.steps.verification.countdown * 1000);

        }, function (res) {
            $interval.cancel(countdown);
            if (res.data.hasOwnProperty("error")) {
                if (res.data.error.code == 101) {
                    vm.errorMessage = $translate.instant('USERS.MESSAGES.REGISTRATION.INVALID_DATA');
                } else if (res.data.error.code == 102) {
                    vm.errorMessage = $translate.instant('USERS.MESSAGES.REGISTRATION.NOT_REGISTERED_IN_ABSHER');
                } else {
                    vm.errorMessage = $translate.instant('USERS.MESSAGES.REGISTRATION.FAILURE');
                }
            } else if (res.data.hasOwnProperty("errors")) {
                if (res.data.errors.hasOwnProperty("mobile")) {
                    vm.errorMessage = $translate.instant('USERS.MESSAGES.REGISTRATION.MOBILE_NOT_UNIQUE');
                }
            }
            $anchorScroll('personalInfo');
        })
        .finally(function () {
          vm.loadingBlock.stop();
        });
    };

    vm.verifyCode = function () {
      $interval.cancel(countdown);
      vm.loadingBlock.start();
      vm.errorMessages = [];
      Registration.verifyCode(vm.data.verification)
        .then(function (res) {
          vm.data.accountDetails.payload = res.data.payload;
          vm.currentStep = vm.steps.accountDetails.id;
        }, function (res) {
          if (res.data.error.code == 201) {
            vm.errorMessage = $translate.instant('USERS.MESSAGES.REGISTRATION.INVALID_CODE');
          } else {
            vm.errorMessage = $translate.instant('USERS.MESSAGES.REGISTRATION.FAILURE');
          }
        })
        .finally(function () {
          vm.loadingBlock.stop();
        });
    };

    vm.register = function () {
      vm.loadingBlock.start();
      vm.errorMessages = [];

      Registration.register(vm.data.accountDetails)
        .then(function (data) {
          var text;
          if(data.data.alert == true) {
              text = $translate.instant('USERS.MESSAGES.REGISTRATION.ACTIVATE');
          } else{
              text = $translate.instant('USERS.MESSAGES.REGISTRATION.SUCCESS');
          }
          sweetAlert({
              type: 'success',
              title: "",
              text: text,
              showConfirmButton: true
          });
          $rootScope.alertMessage = $translate.instant('USERS.MESSAGES.REGISTRATION.SUCCESS');
          $state.go('user.login');
        }, function (res) {
          if (res.status == 422) {
            angular.forEach(res.data.errors, function (elem, messages) {
              angular.forEach(elem, function (message) {
                vm.errorMessages.push(message);
              });
            });
          } else {
            vm.errorMessages = [$translate.instant('USERS.MESSAGES.REGISTRATION.FAILURE')];
          }
        })
        .finally(function () {
          vm.loadingBlock.stop();
        });
    };
  };

  angular.module('musaned.app.registration')
    .controller('RegistrationCtrl', RegistrationCtrl);
})();
