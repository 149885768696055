(function () {
    'use strict';

    var AppointmentRequests = function ($http) {
        var AppointmentRequests = {};

        AppointmentRequests.create = function (model) {
            return $http.post('/app/appointment/', model);
        };

        AppointmentRequests.get = function () {
            return $http.get('/app/appointments');
        };


        AppointmentRequests.getAvailability = function (office_id,date) {
            return $http.get('/app/appointments/availability/' + office_id+'/'+date);
        };

        AppointmentRequests.confirm = function (date, time,gio_id) {
            return $http.post('/app/appointments/confirm', {date:date, gio_id:gio_id,time:time});
        };

        AppointmentRequests.book = function (date, time,gio_id) {
            return $http.post('/app/appointments/store', {date:date, gio_id:gio_id,time:time});
        };

        AppointmentRequests.getAppointments = function () {
            return $http.get('/app/appointments/list/'+1);
        };

        return AppointmentRequests;
    };

    angular.module('musaned.app.appointments')
        .service('AppointmentRequests', AppointmentRequests)
})();
