(function () {
  'use strict';

  var PasswordRequestCtrl = function ($translate, blockUI, PasswordReset) {
    var vm = this;

    var block = blockUI.instances.get('password_request');
    vm.availableOptions = [
     // {id: '1', name: $translate.instant('USERS.LABELS.EMAIL')},
      {id: '2', name: $translate.instant('USERS.LABELS.MOBILE')}
    ];

    vm.data = {};

    vm.request = function () {
      block.start();

      PasswordReset.request(vm.data)
        .then(function () {
          var choiceIndex = vm.data.method - 1;
          sweetAlert({
            type: 'success',
            title: 'تم الإرسال بنجاح',
            text:  'في حال كانت البيانات المدخلة صحيحة فسيتم إرسال آلية إستعادة كلمة المرور إلى ' + vm.availableOptions[choiceIndex].name + ' المسجل لدينا.',
            confirmButtonText: 'موافق'
          });
        }, function (res) {
          sweetAlert({
            type: 'error',
            title: 'لا يمكن استعادة كلمة المرور الآن.',
            timer: 2500,
            showConfirmButton: false
          });
        })
        .finally(function () {
          block.stop();
        });
    };
  };

  angular.module('musaned.app.password')
    .controller('PasswordRequestCtrl', PasswordRequestCtrl);
})();
