(function () {
  'use strict';

  var VisaShowCtrl = function ($stateParams, $modal, locker, blockUI, Visas, $ocLazyLoad) {
    var vm = this;

    $ocLazyLoad.load('assets/js/pdf.combined.js');

    vm.visaId = $stateParams.visaId;

    Visas.get(vm.visaId)
      .then(function (res) {
        vm.visa = res.data.visa;
      });

  };

  angular.module('musaned.app.visas')
    .controller('VisaShowCtrl', VisaShowCtrl);
})();
