(function () {
  'use strict';

  var VisaIssueRequestBillCtrl = function ($stateParams, $modal, locker, blockUI, VisaIssueRequests, $ocLazyLoad) {
    var vm = this;

    vm.requestId = $stateParams.requestId;

    VisaIssueRequests.bill(vm.requestId)
      .then(function (res) {
        vm.data = res.data;
      }, function (res) {
          if (res.data.error.code == 201) {
            vm.errorMessage = $translate.instant('VISAS.ISSUE_REQUESTS.MESSAGES.NOT_FOUND');
          } else {
            vm.errorMessage = $translate.instant(res.data.error.message);
          }
        });

  };

  angular.module('musaned.app.visas.issueRequests')
    .controller('VisaIssueRequestBillCtrl', VisaIssueRequestBillCtrl);
})();
