(function () {
    'use strict';

    var ContractRequestShowCtrl = function ($stateParams, $rootScope, $state, $modal, blockUI, ContractRequests) {
        var vm = this;

        vm.requestId = $stateParams.requestId;
        var block = blockUI.instances.get('contract');
        block.start();
        ContractRequests.get(vm.requestId)
            .then(function (res) {
                vm.request = res.data.officeContractRequest;
                vm.request.contract.text = $sce.trustAsHtml(vm.request.contract.text);
            }).finally(function () {
                block.stop();
            });

        vm.accept = function () {
            var block = blockUI.instances.get('contract');
            block.start();
            ContractRequests.accept(vm.request.contract.id, vm.request.office.id)
                .then(function (res) {
                    $state.go('contracts.payment', {
                        contractId: vm.request.contract.id
                    });
                })
                .finally(function () {
                    block.stop();
                });
        };

        vm.reject = function () {
            var block = blockUI.instances.get('contract');
            block.start();
            ContractRequests.reject(vm.request.contract.id, vm.request.office.id)
                .then(function (res) {
                    $rootScope.successMessage = 'تم رفض العرض المقدم من ' + vm.request.office.name;
                    $state.go('contracts.requests.list');
                })
                .finally(function () {
                    block.stop();
                });
        };
    };

    angular.module('musaned.app.contracts.requests')
        .controller('ContractRequestShowCtrl', ContractRequestShowCtrl);
})();
