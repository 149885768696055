(function () {
  'use strict';

  var TawtheeqContracts = function ($http) {
    var vm = this;


    vm.get = function () {
      return $http.get('app/tawtheeq');
    };

    vm.confirm = function (laborerId) {
      return $http.get('app/tawtheeq/' + laborerId + '/confirm');
    };

    vm.store = function (laborerId,data) {
      return $http.post('app/tawtheeq/' + laborerId + '/confirm',data);
    };

    vm.show = function (laborerId) {
      return $http.get('app/tawtheeq/' + laborerId + '/show');
    };

    vm.confirmData = function (laborerId,data) {
      return $http.post('/app/tawtheeq/' + laborerId + '/confirm_data',data);
    };

  };

  angular.module('musaned.app.eTawtheeq.tawtheeqContracts')
    .service('TawtheeqContracts', TawtheeqContracts);
})();
