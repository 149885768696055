(function () {
  'use strict';

  var TawtheeqContractShowCtrl = function ($stateParams, $rootScope, $state, $modal, blockUI, TawtheeqContracts, Settings) {
    var vm = this;
    vm.workers = null;
    vm.setting = {
      is_dlba_disabled: 0
    };
    vm.is_profile_updated = false;

    Settings.get('is_dlba_disabled').then(function (respone) {
      vm.setting.is_dlba_disabled = respone.data;
    });

    Settings.get('dlba_disable_message').then(function (respone) {
      vm.setting.dlba_disable_message = respone.data;
    });


    TawtheeqContracts.get()
      .then(function (res) {
        vm.workers = res.data.contracts;
        vm.userName = res.data.nic_user_info.first_name + " " + res.data.nic_user_info.last_name;
        vm.userGender = res.data.nic_user_info.gender;
        vm.userId = res.data.user_info.id_number;
        vm.is_profile_updated = res.data.is_profile_updated;
        vm.data = res.data;

      });

    vm.view = function (data) {
      var modalInstance = $modal.open({
        animation: false,
        templateUrl: 'partials/app/eTawtheeq/tawtheeqContracts/view.html',
        controller: 'TawtheeqContractViewCtrl',
        size: 'lg',
        windowClass: 'print-visa-modal',
        resolve: {
          laborerId: function () {
            return data;
          },
          details: function () {
            return null;
          }
        }
      });
    };
  };

  angular.module('musaned.app.eTawtheeq.tawtheeqContracts')
    .controller('TawtheeqContractShowCtrl', TawtheeqContractShowCtrl);
})();
