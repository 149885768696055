(function () {
    'use strict';

    var TrackingCtrl = function ($stateParams, $scope, $rootScope, $http, blockUI,$translate) {

        var vm = this;
        var block = blockUI.instances.get('tracking_form');

        $scope.tracking = {};
        vm.errorMessage = '';
        vm.result = false;
        vm.reason = "";

      vm.token = $stateParams.token;

      vm.submitTracking = function () {
        block.start();
        $http.post('/tracking/results', {id_number: $scope.tracking.id_number, token: vm.token})
          .then(function (res) {
            vm.errorMessage = '';
            vm.result = res.data;
          }, function (res) {
            vm.result = false;
            vm.errorMessage = res.data;
            if (res.data.error) {
              if (res.data.error.code == 404) {
                vm.errorMessage.message = $translate.instant('REPLACEMENT_VISA.MESSAGES.REQUEST_NOT_FOUND');
              } else if (res.data.error.code == 400) {
                vm.errorMessage.message = $translate.instant('REPLACEMENT_VISA.MESSAGES.ID_NUMBER_NOT_CORRECT');
              }
            }

          })
          .finally(function () {
            block.stop();
          });
      }

    };
    angular.module('musaned.app')
        .controller('TrackingCtrl', TrackingCtrl)
})();
