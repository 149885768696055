(function () {
    'use strict';

    var SadadPaymentCtrl = function ($scope, $state, $translate, $http, $rootScope, $location, $timeout, $stateParams, blockUI, WizardHandler, Upload, Visas, ContractRequests, $ocLazyLoad, Contract) {
        var vm = this;

        vm.contractId = $stateParams.contractId;
        vm.paymentStatus = $stateParams.status;

        var block = blockUI.instances.get('payment_form');
        block.start();

        // Set year select
        vm.currentYear = new Date().getFullYear();
        vm.years = [];
        for (var i = vm.currentYear; i < (vm.currentYear + 11); i++) {
            vm.years.push({value: i.toString().substr(i.toString().length - 2), text: i});
        }

        vm.errorMessage = '';
        vm.payment_methods = [];

        function init() {
            Contract.get(vm.contractId)
                .then(function (res) {
                    vm.status = true;
                    vm.contract = res.data.contract;
                    if(vm.contract.bill.sadad_bill_number != null){
                    vm.bill_number = vm.contract.bill.sadad_bill_number;
                    }
                    else {
                        vm.contract.bill.v_iban;
                    }

                    if (vm.contract.new_status == 'PENDING') {
                        vm.status = false;
                    } else if (vm.contract.new_status == 'VALID') {
                        vm.paid = true;
                    }

                    Visas.get(vm.contract.visa_id)
                        .then(function (res) {
                            vm.contract.visa = res.data.visa;
                        });

                    vm.payments = res.data.contract.payments;
                    vm.lookupBanks = res.data.banks;
                    vm.officeAccounts = res.data.officeAccounts;
                    vm.amount = vm.contract.bill.amount;
                    vm.contract.bill.expiry_date = moment(vm.contract.bill.expiry_date).format('Y/M/D');

                }, function (error) {
                    if (error.status === 404) {
                        sweetAlert({
                            title: $translate.instant('CONTRACTS.BILLS.ERROR_TITLE'),
                            text: $translate.instant('CONTRACTS.BILLS.ERROR_NOT_FOUND'),
                            type: 'error',
                            showCancelButton: false,
                            closeOnConfirm: true,
                            closeOnCancel: false,
                            showLoaderOnConfirm: true
                        }, function (confirmd) {
                            if (confirmd) {
                                $state.go('contracts.requests.list');
                            }
                        });
                    }
                })
                .finally(function () {
                    block.stop();
                });
        }

        init();

        vm.printByID = function printByID(domID, title) {

            var mywindow = window.open('', 'PRINT', 'height=400,width=600');

            mywindow.document.write('<html><head><title>' + title + '</title>');
            mywindow.document.write('</head><body >');
            mywindow.document.write(
                '<style>' +
                '        body {' +
                '            direction: rtl;' +
                '        }' +
                '    </style>'
            );
            mywindow.document.write('<h2>' + title + '</h2>');
            mywindow.document.write(document.getElementById(domID).outerHTML);
            mywindow.document.write('</body></html>');

            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/

            mywindow.print();
            mywindow.close();

            return true;
        }

        vm.rate = function (contract_id, rate) {
            Contract.rateMusaned(contract_id, rate)
                .then(function (res) {
                    if (res.status == 200 && res.data == 'success') {
                        sweetAlert({
                                type: 'success',
                                title: 'تقييم مساند',
                                text: 'شكرا لك، تم التقييم بنجاح',
                                timer: 10000
                            },
                            function () {
                                $('#rateModal').modal('hide');
                                $('body').removeClass('modal-open');
                                $('.modal-backdrop').remove();
                                init();
                            });
                    }

                });
        }
    };


    angular.module('musaned.app.contracts.requests')
        .controller('SadadPaymentCtrl', SadadPaymentCtrl);
})();