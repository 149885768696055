(function () {
  'use strict';

  var ContractsListCtrl = function (Contract, blockUI, $translate, $rootScope, Visas, $modal,officeRequest) {
    var vm = this;

    vm.errorMessage = $rootScope.errorMessage;
    $rootScope.errorMessage = '';

    vm.successMessage = $rootScope.successMessage;
    $rootScope.successMessage = '';
    vm.currentPage=null;
    var block = blockUI.instances.get('proposals_list');
    block.start();

    $('body').tooltip({
      selector: '.reviewTooltip'
    });

    vm.pageChanged = function () {
      var block = blockUI.instances.get('proposals_list');
      block.start();
        Contract.all(vm.currentPage)
          .then(function (res) {
            Visas.all(0).then(function (visa_response) {

              vm.proposals = res.data.data;

              for (var i = 0; i < vm.proposals.length; i++) {
                var visa = _.find(visa_response.data.paginator.data, function (visa) {
                  return vm.proposals[i].visa_id == visa.id;
                });
                vm.proposals[i].visa = visa
              }
          });

            vm.proposals = res.data.data;
            vm.currentPage = res.data.current_page;
            vm.per_page = res.data.per_page;
            vm.total = res.data.total;
          })
          .finally(function () {
            block.stop();
          });
    };

    vm.pageChanged();

      Contract.all(vm.currentPage)
      .then(function (res) {
        vm.proposals = res.data.data;
        vm.currentPage = res.data.current_page;
        vm.per_page = res.data.per_page;
        vm.total = res.data.total;
       // vm.locateAgreedOfficeRequest();
      })
      .finally(function () {
        block.stop();
      });

     vm.locateAgreedOfficeRequest = function() {
      for(var i in vm.proposals){
        vm.proposals[i].waitingForOfficesResponse = 0;
        vm.proposals[i].waitingForUserResponse = 0;
        vm.proposals[i].allRequestsAreRejected = vm.proposals[i].office_contract_requests.length;

        for(var key in vm.proposals[i].office_contract_requests){
          var contract = vm.proposals[i].office_contract_requests[key].contract;

          vm.proposals[i].waitingForOfficesResponse += contract == null && vm.proposals[i].office_contract_requests[key].status == 1? 1 : 0;
          vm.proposals[i].waitingForUserResponse += contract != null && contract.status == 1;
          vm.proposals[i].allRequestsAreRejected -= (
            (vm.proposals[i].status == 2) || // contract request is rejected
            (vm.proposals[i].office_contract_requests[key].status == 2) || // office contract request is rejected
            (contract != null && contract.status == 2) // contract rejected
          )  ? 1 : 0;

        }
          vm.proposals[i].allRequestsAreRejected = vm.proposals[i].allRequestsAreRejected == 0;

      }
    };

      vm.printInvoice = function (contract) {
        var date = new Date(Date.parse(contract.bill.expiry_date));
        var options = {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric'
        };
        contract.bill.due_date_ar = date.toLocaleDateString('ar-AR', options);
        contract.bill.due_date_en = date.toLocaleDateString('en-AR', options);
        contract.bill.created_at = new Date(Date.parse(contract.bill.created_at)).toLocaleDateString();

        var modalInstance = $modal.open({
          animation: false,
          templateUrl: 'partials/app/contracts/contracts/print.html',
          controller: 'InvoicePrintCtrl',
          size: 'lg',
          windowClass: 'print-invoice-modal',
          resolve: {
            details: function () {
              return contract;
            }
          }
        });
      };


         vm.cancelContract = function (contract) {
           vm.errorMessage = '';
           vm.successMessage = '';
           sweetAlert({
             title: $translate.instant("VISAS.CANCELLATION_REQUESTS.PHRASES.REQUEST_VISA_CANCELLATION"),
             text: $translate.instant("VISAS.CANCELLATION_REQUESTS.PHRASES.REQUEST_VISA_CANCELLATION_TEXT"),
             type: "warning",
             showCancelButton: true,
             confirmButtonColor: "#DD6B55",
             confirmButtonText: $translate.instant("COMMON.ACTIONS.YES"),
             cancelButtonText: $translate.instant("COMMON.ACTIONS.NO"),
             closeOnConfirm: false,
             showLoaderOnConfirm: true
           }, function (confirmed) {
             if (confirmed) {
               ContractRequests.cancel(contract)
                   .then(function () {
                     sweetAlert({
                       title: $translate.instant("VISAS.CANCELLATION_REQUESTS.PHRASES.CANCELLATION_REQUEST_SENT"),
                       type: "success"
                     });
                   }, function (res) {
                     sweetAlert({
                       type: 'error',
                       title: 'issue in cancelling'
                     })
                   });

             }
           });


         };
  };

  angular.module('musaned.app.contracts.contracts')
    .controller('ContractsListCtrl', ContractsListCtrl);
})();
