(function () {
    'use strict';

    var officeRequestsListCtrl = function (officeRequest, ContractRequests, blockUI, $stateParams, $translate, $rootScope, Auth, Visas, Contract, $state, $scope, App_URLs) {
        var vm = this;

        vm.data = {};
        vm.user = Auth.user();
        vm.errorMessage = $rootScope.errorMessage;
        $rootScope.errorMessage = '';
        vm.successMessage = $rootScope.successMessage;
        $rootScope.successMessage = '';
        vm.requestId = $stateParams.requestId;
        vm.type = $stateParams.type;
        vm.creationComplete = $stateParams.created;

        $scope.eContractingBaseUri = App_URLs.eContractingBaseUri;
        $scope.proBaseUri = App_URLs.proBaseUri;

        var block = blockUI.instances.get('Requests_list');
        block.start();

        vm.WorkerConfirm = function (contract_id) {
            var r = confirm("هل انت متآكد ؟");
            if (r == true) {
                officeRequest.Worker_Confirm(contract_id)
                    .then(function successCallback() {
                        confirm('تم تآكيد آستلام العميل');
                        location.reload();
                    }, function errorCallback() {
                        confirm('هناك مشكله في النظام ، يرجى تحديث الصفحه');

                    });
            }
        };

        vm.CancelRequest = function (request_id) {
            sweetAlert({
                title: $translate.instant("CONTRACTS.REQUESTS.PHRASES.CANCEL_REQUEST_TITLE"),
                text: $translate.instant("CONTRACTS.REQUESTS.PHRASES.CANCEL_REQUEST"),
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: $translate.instant("COMMON.ACTIONS.YES"),
                cancelButtonText: $translate.instant("COMMON.ACTIONS.NO"),
                closeOnConfirm: false,
                showLoaderOnConfirm: true
            }, function (confirmed) {
                if (confirmed) {
                    officeRequest.CancelRequest(request_id)
                        .then(function (res) {
                            sweetAlert({
                                title: $translate.instant("CONTRACTS.REQUESTS.PHRASES.CANCEL_REQUEST_SUCCEED"),
                                type: "success"
                            });
                            init();
                        }, function (res) {
                            sweetAlert({
                                type: 'error',
                                title: 'حصل خطأ'
                            })
                        });
                }
            });
        };

        vm.CancelContract = function (contract, reason) {
            var data = {
                reason: reason
            };
            officeRequest.CancelContract(contract.id, data)
                .then(function (res) {
                    $rootScope.successMessage = $translate.instant('CONTRACTS.REQUESTS.CANCELLATION_REQUESTS.CANCEL_CONTRACT_SUCCESS');
                    init();
                }, function (res) {
                    $rootScope.errorMessage = $translate.instant('CONTRACTS.REQUESTS.CANCELLATION_REQUESTS.CANCEL_CONTRACT_FAILED');
                    init();
                });

        };

        function init() {

            vm.ageRangeList = {
                1: 'CONTRACTS.REQUESTS.LABELS.AGE_30',
                2: 'CONTRACTS.REQUESTS.LABELS.AGE_40',
                3: 'CONTRACTS.REQUESTS.LABELS.AGE_50',
                4: 'CONTRACTS.REQUESTS.LABELS.AGE_60',
                5: 'CONTRACTS.REQUESTS.LABELS.AGE_65'
            };

            vm.workExperienceList = {
                1: 'CONTRACTS.REQUESTS.LABELS.WORKED_IN_KSA',
                2: 'CONTRACTS.REQUESTS.LABELS.WORKED_IN_GOLF',
                3: 'CONTRACTS.REQUESTS.LABELS.WORKED_BEFORE',
                4: 'CONTRACTS.REQUESTS.LABELS.NEVER_WORKED',
                5: 'CONTRACTS.REQUESTS.LABELS.NONE'
            };

            vm.religionList = {
                1: 'CONTRACTS.REQUESTS.LABELS.ISLAM',
                3: 'CONTRACTS.REQUESTS.LABELS.NONE',
                4: 'CONTRACTS.REQUESTS.LABELS.OTHER'
            };

            vm.familySizeList = {
                1: '1 - 5',
                2: '6 - 10',
                3: '11 - 15',
                4: 'CONTRACTS.REQUESTS.LABELS.FAMILY_SIZE_MORE_15'
            };
            vm.negotiationSystem = false;
            ContractRequests.getSetting('negotiation_system')
                .then(function (res) {
                    if (res.data.value > 0) {
                        vm.negotiationSystem = true; }
                })
                .finally(function () {
                    block.stop();
                });

            if (vm.type && ( vm.type = 'preVisa')) {
                officeRequest.getPreVisaRequest(vm.requestId)
                    .then(function (res) {
                        var RequestData = angular.fromJson(res.data.preVisa.contract_request);
                        vm.data['request_type'] = RequestData.request_type;
                        vm.data['visa']=res.data.request;
                        if (RequestData.request_type == 'PREFERENCES') {
                            vm.data['age'] = RequestData.age_range;
                            vm.data['work_experience'] = RequestData.work_experience;
                            vm.data['religion'] = RequestData.religion;
                        } else {
                            vm.data['worker_passport'] = RequestData.worker_passport;
                            vm.data['worker_mobile'] = RequestData.worker_mobile;
                        }
                        vm.data['address'] = $rootScope.userInfo.personal_details.address;
                        vm.data['family_size'] = RequestData.family_size;
                        vm.data['domestic_laborers'] = RequestData.domestic_laborers;
                        vm.data['work_type'] = RequestData.work_type;
                        vm.data['offers'] = [];
                        angular.forEach(RequestData.office, function (val) {
                            officeRequest.getProServices(val)
                                .then(function (res) {
                                    vm.data['offers'].push({
                                        'pro': res.data.offices[0],
                                        'price': res.data.offices[0].services[0].price,
                                        'duration': res.data.offices[0].services[0].recruitment_period,
                                    });
                                });
                        });
                    });
            } else {
                officeRequest.get(vm.requestId)
                    .then(function (res) {
                        vm.is_sadad=res.data.isSadad;
                        Visas.get(res.data.contractRequests.visa_id)
                            .then(function (visa_res) {
                                vm.data['visa'] = visa_res.data.visa;
                                officeRequest.getCity(res.data.contractRequests.city_id)
                                    .then(function (city_res) {
                                        vm.data['arrival_city'] = city_res.data.city.label_ar;
                                    });
                            });

                        vm.data['request_type'] = res.data.contractRequests.request_type;
                        vm.data['new_status'] = res.data.contractRequests.new_status;
                        vm.data['hasWaitingForUserAcceptOffer'] = res.data.contractRequests.offers.some(function (offer) {
                            return offer.pro_status == 'ACCEPT'&& offer.user_status == null;

                        });
                        var date = new Date(Date.parse(res.data.contractRequests.offers[0].valid_until));

                        var options = {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric'
                        };

                        var contract_date_options = {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                        };
                        vm.data['validity_date_ar'] = date.toLocaleDateString('ar-AR', options);
                        vm.data['validity_date_en'] = date.toLocaleDateString('en-US', options);

                        vm.data.allow_cancel = true;
                        for (var i = 0; i < res.data.contractRequests.offers.length; i++) {
                            var contract_date = new Date(Date.parse(res.data.contractRequests.offers[i].updated_at));

                            res.data.contractRequests.offers[i]['contract_date'] = contract_date.toLocaleDateString('ar-Ar', contract_date_options);
                            res.data.contractRequests.offers[i]['contract_date_hijri'] = contract_date.toLocaleDateString('ar-SA', contract_date_options);


                            if (res.data.contractRequests.offers[i].contract != null && res.data.contractRequests.offers[i].contract.new_status == 'VALID' && res.data.contractRequests.offers[i].contract.bill != null) {
                                var paid_date = res.data.contractRequests.offers[i].contract.bill.paid_date;

                                var startDay = new Date(paid_date);
                                var endDay = new Date();
                                var millisecondsPerDay = 1000 * 60 * 60 * 24;

                                var millisBetween = endDay.getTime() - startDay.getTime();
                                var days = millisBetween / millisecondsPerDay;
                                days = Math.floor(days);
                                if (days < 5) {
                                    vm.data['refund_allowed'] = true;
                                }
                            }

                            if (res.data.contractRequests.offers[0].pro_status != 'PENDING' && res.data.contractRequests.offers[0].user_status != 'CANCELLED'){

                                vm.data.allow_cancel = false;
                            }
                        }


                        if (vm.data['request_type'] == 'PREFERENCES') {

                            vm.data['age'] = res.data.contractRequests.age_range_id;
                            vm.data['work_experience'] = res.data.contractRequests.work_experience;
                            vm.data['religion'] = res.data.contractRequests.religion_id;
                        } else {
                            vm.data['worker_passport'] = res.data.contractRequests.worker_passport;
                            vm.data['worker_mobile'] = res.data.contractRequests.worker_mobile;
                        }
                        vm.data['domestic_laborers'] = res.data.contractRequests.domestic_laborers;
                        vm.data['work_type'] = $rootScope.userInfo.personal_details.job;
                        vm.data['family_size'] = res.data.contractRequests.family_size;
                        vm.data['offers'] = res.data.contractRequests.offers;
                        vm.data['address'] = $rootScope.userInfo.personal_details.address;
                        vm.data['contract_request'] = res.data.contractRequests;
                    })
                    .finally(function () {
                        block.stop();
                    });
            }
        }

        vm.rejectConfirm = function (request_id, office_id) {
            sweetAlert({
                title: $translate.instant("CONTRACTS.REQUESTS.CANCELLATION_REQUESTS.REJECTING_CONTRACT_CONFIRM_TITLE"),
                text: $translate.instant("CONTRACTS.REQUESTS.CANCELLATION_REQUESTS.REJECTING_CONTRACT_CONFIRM_TEXE"),
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: $translate.instant("COMMON.ACTIONS.YES"),
                cancelButtonText: $translate.instant("COMMON.ACTIONS.NO"),
                closeOnConfirm: false,
                closeOnCancel: true,
                showLoaderOnConfirm: true
            }, function (confirmd) {
                if (confirmd) {
                    ContractRequests.reject(request_id, office_id)
                        .then(function (res) {
                            swal($translate.instant("CONTRACTS.REQUESTS.CANCELLATION_REQUESTS.REJECTING_CONTRACT_CONFIRM_TITLE"), $translate.instant("CONTRACTS.REQUESTS.CANCELLATION_REQUESTS.REJECTING_CONTRACT_CONFIRM_SUCCESS"), "success");
                            init();
                        });
                }
            });
        };

        vm.ratePro = function (contract_id, rate) {
            Contract.ratePro(contract_id, rate)
                .then(function (res) {
                    if (res.status == 200 && res.data == 'success') {
                        sweetAlert({
                                type: 'success',
                                title: 'تقييم مكتب الاستقدام',
                                text: 'شكرا لك، تم تقييم المكتب بنجاح',
                                timer: 10000
                            },
                            function () {
                                init();
                            });
                    }

                });
        };

        vm.DiscountRequest = function (offer_id, discount_type) {
            sweetAlert({
                title: $translate.instant("CONTRACTS.REQUESTS.LABELS.DISCOUNT.TITLE"),
                text: $translate.instant("CONTRACTS.REQUESTS.LABELS.DISCOUNT.CONFIRM_DESC"),
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: $translate.instant("COMMON.ACTIONS.YES"),
                cancelButtonText: $translate.instant("COMMON.ACTIONS.NO"),
                closeOnConfirm: false,
                closeOnCancel: true,
                showLoaderOnConfirm: true
            }, function (confirmd) {
                if (confirmd) {
                    Contract.EndUserDiscountRequest(offer_id, discount_type)
                        .then(function (res) {
                            $('#discountModal' + offer_id).modal('hide');
                            sweetAlert({
                                type: 'success',
                                title: $translate.instant("CONTRACTS.REQUESTS.LABELS.DISCOUNT.TITLE"),
                                text: $translate.instant("CONTRACTS.REQUESTS.LABELS.DISCOUNT.SUCCESS"),
                                timer: 10000
                            });
                            init();
                        }, function () {
                            sweetAlert({
                                type: 'error',
                                title: $translate.instant("CONTRACTS.REQUESTS.LABELS.DISCOUNT.TITLE"),
                                text: $translate.instant("CONTRACTS.REQUESTS.LABELS.DISCOUNT.ERROR"),
                                timer: 10000
                            });
                            init();
                        });
                }
            });

        };

        vm.HideDiscountModal = function (offer) {
            $('#discountModal' + offer.id).modal('hide');
        };

        init();
    };


    angular.module('musaned.app.contracts.officeRequests')
        .controller('officeRequestsListCtrl', officeRequestsListCtrl);
})();